import { SingleValueProps } from 'react-select';
import { components } from 'react-select';
import {
  ImageMedium,
  SingleValueContainer,
  SkeletonWrapper,
} from './ImageMediumSingleValue.styled';
import { useState } from 'react';
import { Skeleton } from '@mui/material';
import { adjustImageSourceToUseS3ImageTransformator } from 'utils/adjustImageSourceToUseS3ImageTransformator';

export const ImageMediumSingleValue = (props: SingleValueProps<any>) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  return (
    <components.SingleValue {...props}>
      <SingleValueContainer>
        {props.data.label}
        &nbsp; &nbsp;
        {imageLoaded ? null : (
          <SkeletonWrapper>
            <Skeleton
              variant="rectangular"
              height={'24rem'}
              width={'24rem'}
              animation="wave"
              style={{ borderRadius: '5rem', backgroundColor: 'transparent' }}
            />
          </SkeletonWrapper>
        )}
        <ImageMedium
          imageLoaded={imageLoaded}
          src={adjustImageSourceToUseS3ImageTransformator(
            props.data.medium_image,
            174
          )}
          onLoad={() => setImageLoaded(true)}
          alt={'img'}
        />
      </SingleValueContainer>
    </components.SingleValue>
  );
};
