import * as Yup from 'yup';

export const addEditAddressSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  country: Yup.string().required('Name is required'),
  city: Yup.string().required('City is required'),
  code: Yup.string().required('Postal code is required'),
  street: Yup.string().required('Street is required'),
  contact: Yup.string()
    .optional()
    .max(50, 'Field accept maximum 50 characters')
    .nullable(),
  email: Yup.string().optional().email().nullable(),
  phone: Yup.string()
    .optional()
    .nullable()
    .max(50, 'Field accept maximum 50 characters'),
});
