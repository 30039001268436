import { useTranslation } from 'react-i18next';
import {
  AbsoluteTotalLabel,
  Cell,
  FooterContainer,
  TotalQuantityLabel,
} from './BomTableFooter.styled';
import { ITableConfig, ITableRowConfig } from 'components/Table/types';
import { IFormattedBOMItem } from '../helpers';

interface IBomTableFooterProps {
  tableData: IFormattedBOMItem[];
  tableConfig: ITableConfig;
}

const getTotalQuantity = (bomItems: IFormattedBOMItem[]) => {
  let totalQuantity = 0;
  if (bomItems) {
    for (const bomItemKey of Object.keys(bomItems)) {
      const bomItem = bomItems[bomItemKey];
      totalQuantity = totalQuantity + bomItem.quantity_per;
    }
  }
  return totalQuantity;
};

export const BomTableFooter = ({
  tableData,
  tableConfig,
}: IBomTableFooterProps) => {
  const { t } = useTranslation();
  const bomItems: IFormattedBOMItem[] = tableData || [];

  const firstBomItem = bomItems?.[0];
  if (!firstBomItem) {
    return <></>;
  }

  const totalQuantity = getTotalQuantity(bomItems);

  return (
    <FooterContainer>
      <AbsoluteTotalLabel>{t('Total')}</AbsoluteTotalLabel>
      <div style={{ width: '30rem' }} />
      <div style={{ width: '30rem' }} />
      {Object.keys(firstBomItem).map((cellKey: string, cellIndex: number) => {
        const rowConfig: ITableRowConfig = tableConfig.rowConfigs[cellIndex];
        switch (cellKey) {
          case 'quantity_per':
            return (
              <Cell
                key={`bom-table-footer-cell-${cellIndex}`}
                flexValue={rowConfig.flexValue}
              >
                <TotalQuantityLabel>{totalQuantity}</TotalQuantityLabel>
              </Cell>
            );
          default:
            return (
              <Cell
                key={`bom-table-footer-cell-${cellIndex}`}
                flexValue={rowConfig.flexValue}
              />
            );
        }
      })}
      <div style={{ width: '30rem' }} />
    </FooterContainer>
  );
};
