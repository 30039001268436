import { COLORS } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  $isOpen: boolean;
}

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
`;

export const DropDown = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  justify-content: flex-start;
  position: absolute;
  background-color: ${COLORS.WHITE};
  bottom: 0;
  right: 0;
  display: none;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 10rem;
  padding: 5rem 10rem;
  transform: translateY(100%);
  z-index: 2;

  ${(props: StyledProps) =>
    props.$isOpen &&
    css`
      display: flex;
    `}
`;

export const Text = styled.span`
  display: inline-block;
  font-size: 17rem;
  color: ${COLORS.BLACK};
  transition: 0.3s ease;
`;

export const Row = styled.div`
  display: flex;
  gap: 10rem;
  align-items: center;
  padding: 5rem;
  padding-bottom: 10rem;
  border-bottom: 1px solid ${COLORS.BORDER};

  :hover {
    ${Text} {
      color: ${COLORS.GREEN};
    }
  }

  &:last-of-type {
    padding-bottom: 5rem;
    border-bottom: none;
  }
`;
