import {
  CategoryNameLabel,
  Container,
  CategoryNameAndChevronWrapper,
  ModelsWrapper,
  PaginationWrapper,
  NoContentLabel,
  StyledIcon,
} from './CategoryList.styled';
import { useState } from 'react';
import { IModel } from 'types/Model.types';
import { ICategory } from 'types/Category.types';
import ModelRow from './ModelRow/ModelRow';
import Pagination from 'components/Pagination/Pagination';
import { CATEGORY_MODELS_PER_PAGE } from './constants';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { useGetModels } from 'pages/AllModelsPage/AllModelsListView/hooks';
import { useTranslation } from 'react-i18next';
import ModelRowSkeleton from './ModelRow/ModelRowSkeleton/ModelRowSkeleton';
import { COLORS } from 'assets/styled';
import { CaretDown } from '@phosphor-icons/react';

interface ICategoryListProps {
  supplierId: number;
  category: ICategory;
  withoutLine: boolean;
}

const CategoryList = ({
  supplierId,
  category,
  withoutLine,
}: ICategoryListProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const { t } = useTranslation();

  const { data: supplierModelsData, isLoading: isLoadingSupplierModels } =
    useGetModels(
      page,
      CATEGORY_MODELS_PER_PAGE,
      '',
      supplierId,
      category.id,
      isExpanded
    );

  const models: IModel[] = supplierModelsData?.models || [];

  const showPagination =
    supplierModelsData?.pagination?.total &&
    supplierModelsData?.pagination?.total >= CATEGORY_MODELS_PER_PAGE;

  return (
    <Container withoutLine={withoutLine}>
      <CategoryNameAndChevronWrapper
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <CategoryNameLabel>{category.name}</CategoryNameLabel>
        <StyledIcon
          svg={CaretDown}
          color={COLORS.BLACK}
          isExpanded={isExpanded}
        />
      </CategoryNameAndChevronWrapper>

      <ModelsWrapper
        isExpanded={isExpanded}
        hasPagination={showPagination}
        numberOfModelsInCategory={
          models.length
            ? models.length
            : isLoadingSupplierModels
            ? CATEGORY_MODELS_PER_PAGE
            : 1
        } // Used for transition speed
      >
        {models?.length ? (
          models.map((model: IModel, index: number) => (
            <ModelRow
              model={model}
              key={`modelrow-${index}`}
              withoutLine={models.length - 1 === index}
              onToggleFavoriteSuccess={() => {
                queryClient.invalidateQueries([
                  ReactQueryKeys.GET_MODELS_INFINITE,
                ]);
                queryClient.invalidateQueries([ReactQueryKeys.GET_MODELS]);
              }}
              withoutCategory
            />
          ))
        ) : isLoadingSupplierModels ? (
          Array.from({ length: CATEGORY_MODELS_PER_PAGE }, (_, index) => (
            <ModelRowSkeleton
              key={index}
              index={index}
              opacityCoeficient={0.1}
              withoutLine={CATEGORY_MODELS_PER_PAGE - 1 === index}
              withoutCategory
            />
          ))
        ) : (
          <NoContentLabel>
            {t('No models found for this category')}
          </NoContentLabel>
        )}
        {showPagination ? (
          <PaginationWrapper>
            <Pagination
              page={page}
              perPage={CATEGORY_MODELS_PER_PAGE}
              total={supplierModelsData?.pagination?.total}
              setPage={setPage}
            />
          </PaginationWrapper>
        ) : null}
      </ModelsWrapper>
    </Container>
  );
};

export default CategoryList;
