import { COLORS } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20rem;
`;

export const Title = styled.label`
  font-weight: 400;
  font-size: 26rem;
  color: ${COLORS.BLACK};
  margin-bottom: 20rem;
`;

export const Description = styled.span`
  font-size: 18rem;
  color: ${COLORS.BLACK};
  display: block;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${COLORS.BORDER};
  padding: 20rem 0;
  width: 100%;
  max-width: 100%;
`;

export const RowTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;
`;

export const RowTitle = styled.span`
  font-size: 18rem;
  color: ${COLORS.BLACK};
  display: block;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-top: 25rem;
`;

export const ButtonWrapper = styled.div`
  position: relative;
`;
