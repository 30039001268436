import { MODEL_CARD_CONTAINER_WIDTH } from 'components/ModelCard/ModelCard.styled';
import { useEffect, useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { getSuppliers } from 'services/SupplierService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { MODEL_CARDS_GAP } from './FavoritesSection/FavoritesSectionGrid/FavoritesSectionGrid.styled';
import { useDispatch, useSelector } from 'react-redux';
import { ViewType } from 'components/ViewTypePicker/ViewTypePicker';
import { FiltersPageEnum } from 'store/Filters/constants';
import { IRootReducerState } from 'store/store';
import { savePageFilters } from 'store/Filters/actions/filters';
import i18n, { FALLBACK_LOCALE } from 'providers/i18n/i18n';
import {
  getFavoritesModels,
  getRecentlyViewedModels,
} from 'services/ModelService';

export const useGetSuppliersInfinite = (perPage: number, searchBy: string) =>
  useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_SUPPLIERS_INFINITE, perPage, searchBy],
    queryFn: ({ pageParam = 1 }) => getSuppliers(pageParam, perPage, searchBy),
    getNextPageParam: (lastPage: any) => {
      if (!lastPage?.pagination) {
        return undefined;
      }
      if (
        lastPage.pagination.per_page * lastPage.pagination.page >=
        lastPage.pagination.total
      ) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.pagination.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
  });

// Based on scaling defined in global.styled.ts
const adjustDimensionBasedOnRemScaling = (dimension: number) => {
  // Default scale factor
  let scaleFactor = 1;
  // Adjust the scale factor based on the current viewport width
  if (window.innerWidth < 1300) {
    scaleFactor = 0.8; // Scale down for screens below 1300px
  } else if (window.innerWidth >= 1300 && window.innerWidth < 1900) {
    scaleFactor = 0.9; // Adjusted scale for screens between 1300px and 1900px
  } else if (window.innerWidth >= 1900) {
    scaleFactor = 1; // Full scale for screens above 1900px
  }
  // Return the adjusted dimension
  return dimension * scaleFactor;
};

// used in FavoritesSection and RecentlyViewedSection
export const useGetMaxDisplayedModelCardAmountBasedOnWindowWidth = () => {
  const [maxDisplayedModelCards, setMaxDisplayedModelCards] =
    useState<number>(4);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const paddingLeft = adjustDimensionBasedOnRemScaling(50);

    const newValue = Math.floor(
      (windowWidth - paddingLeft) /
        adjustDimensionBasedOnRemScaling(
          MODEL_CARD_CONTAINER_WIDTH + MODEL_CARDS_GAP
        )
    );

    setMaxDisplayedModelCards(Math.max(newValue, 1));
  }, [windowWidth]);

  return maxDisplayedModelCards;
};

export const useSelectedViewType = () => {
  const dispatch = useDispatch();
  const selectedViewType = useSelector(
    (state: IRootReducerState) =>
      state.filtersInfo?.modelsPage?.selectedViewType
  );

  return {
    selectedViewType,
    setSelectedViewType: (viewType: ViewType) =>
      dispatch(
        savePageFilters({
          page: FiltersPageEnum.MODELS,
          data: { selectedViewType: viewType },
        })
      ),
  };
};

export const useGetRecentlyViewedModels = (
  page: number,
  perPage: number,
  searchBy: string,
  supplierId: number | null,
  categoryId: number | null,
  archived?: boolean,
  isEnabled = true
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_RECENTLY_VIEWED_MODELS,
      page,
      perPage,
      searchBy,
      supplierId,
      categoryId,
      archived,
      i18n.resolvedLanguage || FALLBACK_LOCALE,
    ],
    queryFn: () => {
      return getRecentlyViewedModels(
        page,
        perPage,
        searchBy,
        supplierId,
        categoryId,
        archived
      );
    },
    enabled: isEnabled,
  });

export const useGetFavoriteModels = (
  page: number,
  perPage: number,
  searchBy: string,
  supplierId: number | null,
  categoryId: number | null,
  archived?: boolean,
  isEnabled = true
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_FAVORITE_MODELS,
      page,
      perPage,
      searchBy,
      supplierId,
      categoryId,
      archived,
      i18n.resolvedLanguage || FALLBACK_LOCALE,
    ],
    queryFn: () => {
      return getFavoritesModels(
        page,
        perPage,
        searchBy,
        supplierId,
        categoryId,
        archived
      );
    },
    enabled: isEnabled,
  });
