import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';
import { css } from 'styled-components';

interface StyledProps {
  selected: boolean;
}

export const AddressName = styled.span`
  display: block;
  font-size: 24rem;
  font-weight: 300;
  color: ${COLORS.BLACK};
  transition: 0.3s ease;

  ${respondTo.bigPhone`
    font-size: 20rem;
  `}
`;

export const IconWrapper = styled.div`
  color: ${COLORS.BLACK};
  transition: 0.3s ease;
  padding-top: 5rem;
`;

export const Container = styled.div<StyledProps>`
  border-radius: 20rem;
  border: 1px solid ${COLORS.BLACK};
  display: flex;
  flex-direction: column;
  padding: 20rem;
  max-width: 31.33%;
  width: 100%;
  margin-bottom: 30rem;
  transition: 0.3s ease;
  cursor: pointer;

  ${(props: StyledProps) =>
    props.selected &&
    css`
      background-color: ${COLORS.BACKGROUND};
      border-color: ${COLORS.GREEN};

      ${AddressName} {
        color: ${COLORS.GREEN};
      }

      ${IconWrapper} {
        color: ${COLORS.GREEN};
      }
    `}

  &:hover {
    border-color: ${COLORS.GREEN};

    ${AddressName} {
      color: ${COLORS.GREEN};
    }

    ${IconWrapper} {
      color: ${COLORS.GREEN};
    }
  }

  ${respondTo.horizontalTablet`
    max-width: 48.5%;
  `}

  ${respondTo.bigTablet`
    max-width: 100%;
  `}

  ${respondTo.smallTablet`
    padding: 15rem;
  `}
`;

export const ActionDropdownWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 5rem;
`;

export const TopSection = styled.div`
  display: flex;
  gap: 10rem;
  align-items: flex-start;
  margin-bottom: 20rem;
  padding-right: 25rem;
  position: relative;
`;

export const BottomSection = styled.div`
  display: flex;
  justify-content: center;
  gap: 20rem;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  gap: 10rem;
`;

export const DataWrapper = styled.div`
  max-width: 47%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5rem;
`;

export const DataTitle = styled.span`
  display: block;
  font-size: 16rem;
  font-weight: 600;
  color: ${COLORS.BLACK};
`;

export const DataValue = styled.span`
  display: block;
  font-size: 16rem;
  font-weight: 300;
  color: ${COLORS.BLACK};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
