import {
  AbsoluteItem,
  AbsoluteWrapper,
  Container,
  Content,
  Logo,
  LogoWrapper,
  SelectCompanyLabel,
} from './CompanySelectPage.styled';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useGetCompaniesInfinite, useSelectEntities } from './hooks';
import { ICompany } from 'types/Company.types';
import LogyxLogoSVG from 'assets/icons/logyxLogomark.svg';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import { setCompany, setIsLoggedIn } from 'store/User/actions/user';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'navigation/routes';
import ToggleDarkMode from 'components/ToggleDarkMode/ToggleDarkMode';
import { useDebounce } from 'utils/hooks/useDebounce';
import { usePageTitle } from 'utils/hooks/usePageTitle';
import { setAppMode } from 'store/Common/actions/common';
import { AppMode } from 'store/Common/reducers/common';
import ScrollableList from 'components/ScrollableList/ScrollableList';
import { COMPANIES_PER_PAGE } from './constants';
import { getDataFromPages } from 'utils/getDataFromPages';
import { LOGOUT } from 'store/constants';
import { toast } from 'react-toastify';
import { handleSelectEntities } from './helpers';
import { IRootReducerState } from 'store/store';

const CompanySelectPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchByCompany, setSearchByCompany] = useState<string>('');
  const debouncedSearchByCompany = useDebounce(searchByCompany);

  const {
    data: companiesData,
    isLoading: isLoadingGetCompanies,
    isSuccess: isSuccessGetCompanies,
    hasNextPage,
    fetchNextPage,
    isFetching: isFetchingGetCompanies,
  } = useGetCompaniesInfinite(COMPANIES_PER_PAGE, debouncedSearchByCompany);

  const companies: ICompany[] = getDataFromPages(
    companiesData?.pages,
    'companies'
  );

  const { supplier } = useSelector(
    (state: IRootReducerState) => state.userInfo
  );

  const { mutate: selectEntities, isLoading: isLoadingSelectEntities } =
    useSelectEntities();

  useEffect(() => {
    // If only 1 company is available, select it
    if (isSuccessGetCompanies && debouncedSearchByCompany === '') {
      const companyCount = companies?.length;
      dispatch(setAppMode(AppMode.COMPANY));
      switch (companyCount) {
        case 1:
          dispatch(setCompany(companies[0]));
          handleSelectEntities(
            dispatch,
            selectEntities,
            companies[0].id,
            supplier ? supplier.id : undefined
          );
          break;
        case 0: {
          if (supplier) {
            dispatch(setAppMode(AppMode.SUPPLIER));
            dispatch(setIsLoggedIn(true));
          } else {
            navigate(RoutesConfig.Login.fullPath);
            dispatch({ type: LOGOUT });
            toast.error(t('User is not connected to any company or supplier'));
          }
        }
      }
    }
  }, [isSuccessGetCompanies]);

  usePageTitle('Select company');

  return (
    <Container>
      <Content>
        <LogoWrapper>
          <Logo src={LogyxLogoSVG} alt="" />
          <AbsoluteWrapper>
            <AbsoluteItem>
              <ToggleDarkMode />
            </AbsoluteItem>
            <AbsoluteItem>
              <LanguageSwitcher />
            </AbsoluteItem>
          </AbsoluteWrapper>
        </LogoWrapper>
        <SelectCompanyLabel>{t('Select company')}</SelectCompanyLabel>

        <ScrollableList
          items={companies || []}
          onClick={(company: ICompany) => {
            if (isLoadingSelectEntities) {
              return;
            }
            dispatch(setAppMode(AppMode.COMPANY));
            dispatch(setCompany(company));

            handleSelectEntities(
              dispatch,
              selectEntities,
              company.id,
              supplier.id
            );
          }}
          onSearch={(str: string) => setSearchByCompany(str)}
          noContentLabel={t('No companies found')}
          searchLabel={t('Search companies')}
          isLoading={isLoadingGetCompanies}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetching={isFetchingGetCompanies}
        />
      </Content>
    </Container>
  );
};

export default CompanySelectPage;
