import {
  AdditionalWrapper,
  Container,
  PriceContainer,
  PriceLabel,
  PriceValue,
} from '../../FormItemWrapper/FormItemWrapper.styled';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IExtraPriceQuestionFormWrapperProps {
  children: React.ReactNode;
  questionPrice: number | undefined;
}

export const ExtraPriceQuestionFormWrapper = ({
  children,
  questionPrice,
}: IExtraPriceQuestionFormWrapperProps) => {
  const { t } = useTranslation();

  const isAddedPriceNegative =
    typeof questionPrice == 'number' && questionPrice < 0;

  return (
    <Container>
      {children}
      <AdditionalWrapper>
        {questionPrice !== undefined && (
          <PriceContainer>
            <PriceLabel>
              {isAddedPriceNegative ? t('Discount') : t('Added price')}:
            </PriceLabel>
            <PriceValue>€{questionPrice?.toFixed(2)}</PriceValue>
          </PriceContainer>
        )}
      </AdditionalWrapper>
    </Container>
  );
};
