import { useState } from 'react';
import { IModel } from 'types/Model.types';
import {
  ModelRowsContainer,
  NoContentLabel,
  NoContentLabelWrapper,
} from './FavoritesSectionList.styled';
import ModelRow from 'pages/ModelsPage/ModelsPageList/SupplierCategoriesList/CategoryList/ModelRow/ModelRow';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import Pagination from 'components/Pagination/Pagination';
import { FAVORITE_MODELS_PER_PAGE_LIST } from './constants';
import { useTranslation } from 'react-i18next';
import ModelRowSkeleton from 'pages/ModelsPage/ModelsPageList/SupplierCategoriesList/CategoryList/ModelRow/ModelRowSkeleton/ModelRowSkeleton';
import { useGetFavoriteModels } from 'pages/ModelsPage/hooks';

const FavoritesSectionList = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const { data: modelsData, isLoading: isLoadingModels } = useGetFavoriteModels(
    page,
    FAVORITE_MODELS_PER_PAGE_LIST,
    '',
    null,
    null
  );

  const models: IModel[] = modelsData?.models || [];

  const showPagination =
    modelsData?.pagination?.total &&
    modelsData?.pagination?.total >= FAVORITE_MODELS_PER_PAGE_LIST;

  return (
    <>
      {models?.length ? (
        <ModelRowsContainer>
          {models?.map((model: IModel, index: number) => (
            <ModelRow
              model={model}
              key={`favorite-model-row-${index}`}
              withoutLine={models.length - 1 === index}
              onToggleFavoriteSuccess={() => {
                queryClient.invalidateQueries([
                  ReactQueryKeys.GET_FAVORITE_MODELS,
                ]);
                queryClient.invalidateQueries([
                  ReactQueryKeys.GET_RECENTLY_VIEWED_MODELS,
                ]);
                queryClient.invalidateQueries([
                  ReactQueryKeys.GET_MODELS_INFINITE,
                ]);
              }}
            />
          ))}
          {showPagination ? (
            <Pagination
              page={page}
              perPage={FAVORITE_MODELS_PER_PAGE_LIST}
              total={modelsData?.pagination?.total || '0'}
              setPage={setPage}
            />
          ) : null}
        </ModelRowsContainer>
      ) : isLoadingModels ? (
        <ModelRowsContainer isSkeleton>
          {Array.from({ length: FAVORITE_MODELS_PER_PAGE_LIST }, (_, index) => (
            <ModelRowSkeleton
              key={index}
              index={index}
              withoutLine={FAVORITE_MODELS_PER_PAGE_LIST - 1 === index}
            />
          ))}
        </ModelRowsContainer>
      ) : (
        <NoContentLabelWrapper>
          <NoContentLabel>{t('No favourite models found')}</NoContentLabel>
        </NoContentLabelWrapper>
      )}
    </>
  );
};

export default FavoritesSectionList;
