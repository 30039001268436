import { IOption } from 'components/Select/type';
import { Dispatch, SetStateAction } from 'react';
import { IExtraPriceQuestionOption } from 'types/Configuration.types';

type SetOptionsType = Dispatch<SetStateAction<IExtraPriceQuestionOption[]>>;

export const onEdit = (
  optionIndex: number,
  updatedOption: IExtraPriceQuestionOption,
  setOptions: SetOptionsType
) => {
  setOptions((prevOptions) => {
    const newOptions = [...prevOptions];
    newOptions[optionIndex] = updatedOption;
    return newOptions;
  });
};

export const onAdd = (
  newOption: IExtraPriceQuestionOption,
  setOptions: SetOptionsType
) => {
  // if newOption.is_default is true, other options need to be set as non default:
  setOptions((prevOptions) =>
    [...prevOptions, newOption].map((option, index) => {
      const isNewOption = index === prevOptions.length;
      return {
        ...option,
        is_default: isNewOption
          ? newOption.is_default
          : newOption.is_default
          ? false
          : option.is_default,
      };
    })
  );
};

export const onDelete = (optionIndex: number, setOptions: SetOptionsType) => {
  setOptions((prevOptions) =>
    prevOptions.filter((_, index) => index !== optionIndex)
  );
};

export const onDefaultChange = (
  optionIndex: number,
  setOptions: SetOptionsType
) => {
  setOptions((prevOptions) =>
    prevOptions.map((option, index) => {
      const isThisOption = index == optionIndex;
      return {
        ...option,
        is_default: isThisOption ? !option.is_default : false,
      };
    })
  );
};

export const generateOptionFromExtraPriceQuestionOption = (
  extraPriceQuestionOption: IExtraPriceQuestionOption,
  index: number
): IOption => {
  const option: IOption = {
    label: extraPriceQuestionOption.label,
    value: extraPriceQuestionOption.value.toString(),
    is_default: extraPriceQuestionOption.is_default,
    index,
    original_option: extraPriceQuestionOption,
  };
  return option;
};
