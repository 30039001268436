import { Modal } from 'components/Modals/Modal/Modal';
import { Field, Formik } from 'formik';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IVisibilityConfigurationDTO,
  useGetDealerPanelModelQuestions,
  useGetDocumentVisibilityOptions,
  useGetModelDocumentVisibility,
  useSubmitVisibilityConfiguration,
} from './hooks';
import Button from 'components/Button/Button';
import {
  ButtonsWrapper,
  Column,
  ErrorOcurredLabel,
  FormContainer,
  FormTitle,
  FormTitleWrapper,
  NoContentLabel,
  QuestionLabel,
  QuestionWrapper,
  Scrollable,
} from './ManageModelQuestionDocumentVisibilityModal.styled';
import CustomSelect from 'components/Select/FormikSelect';
import { IQuestion } from 'types/Question.types';
import Loader from 'components/Loader/Loader';
import {
  EXTRA_PRICE_QUESTION_INITIAL_KEY,
  getInitialValuesFromQuestions,
  parseQuestionsObjectResponseIntoArray,
} from './helpers';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { getComponentsBasedOnDropdownType } from 'pages/ConfigurePage/ConfigureForm/FormItem/helpers';
import { DropdownType } from 'pages/ConfigurePage/ConfigureForm/FormItem/constants';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { IExtraPriceQuestionData } from 'types/Configuration.types';

interface IManageModelQuestionDocumentVisibilityModalProps {
  manageVisibilityModelId: number | null;
  setManageVisibilityModelId: Dispatch<SetStateAction<number | null>>;
}

const ManageModelQuestionDocumentVisibilityModal = ({
  manageVisibilityModelId,
  setManageVisibilityModelId,
}: IManageModelQuestionDocumentVisibilityModalProps) => {
  const [areAllFieldsTouched, setAreAllFieldsTouched] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const formikRef = useRef<any>(null);
  const base64Metadata: string | undefined = useSelector(
    (state: IRootReducerState) => state.submitConfigurationInfo.base64Metadata
  );

  const {
    data: documentVisibilityData,
    mutate: getModelDocumentVisibility,
    isLoading: isLoadingGetModelDocumentVisibility,
    reset: resetGetModelDocumentVisibility,
    isSuccess: isSuccessGetModelDocumentVisibility,
  } = useGetModelDocumentVisibility();

  const {
    data: questionsData,
    mutate: getDealerPanelModelQuestions,
    isLoading: isLoadingGetDealerPanelModelQuestions,
    reset: resetGetDealerPanelModelQuestions,
    isSuccess: isSuccessGetDealerPanelModelQuestions,
  } = useGetDealerPanelModelQuestions();

  const questions: IQuestion[] = questionsData?.questions
    ? parseQuestionsObjectResponseIntoArray(questionsData.questions)
    : [];

  const extraPriceQuestionData: IExtraPriceQuestionData | undefined =
    questionsData?.extra_price_question_data;

  useEffect(() => {
    if (manageVisibilityModelId) {
      getDealerPanelModelQuestions(manageVisibilityModelId);
      getModelDocumentVisibility(manageVisibilityModelId);
    } else {
      resetGetDealerPanelModelQuestions();
      resetGetModelDocumentVisibility();
    }
  }, [manageVisibilityModelId]);

  const {
    mutate: submitVisibilityConfiguration,
    isLoading: isLoadingSubmitVisibilityConfiguration,
  } = useSubmitVisibilityConfiguration();

  const handleSubmitVisibilityConfiguration = () => {
    const values = formikRef.current.values;
    const payload: IVisibilityConfigurationDTO = {
      values,
      modelId: manageVisibilityModelId!,
      base64Metadata,
    };
    submitVisibilityConfiguration(payload, {
      onSuccess: () => setManageVisibilityModelId(null),
    });
  };

  const documentVisibilityOptions = useGetDocumentVisibilityOptions();

  const modalHeight = questions.length * 50 + (questions.length - 1) * 30 + 200;

  const { isPhone } = useBreakpointFlag();

  return (
    <Modal
      isOpen={!!manageVisibilityModelId}
      onClose={() => {
        setManageVisibilityModelId(null);
      }}
      modalStyle={{
        position: 'fixed',
        margin: 'auto',
        width: '750rem',
        height: `${modalHeight}rem`,
        minHeight: '200rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {isLoadingGetDealerPanelModelQuestions ||
      isLoadingGetModelDocumentVisibility ? (
        <Loader />
      ) : !questions.length ? (
        <NoContentLabel>
          {t('No questions found for this model')}
        </NoContentLabel>
      ) : isSuccessGetModelDocumentVisibility &&
        isSuccessGetDealerPanelModelQuestions ? (
        <>
          <Formik
            innerRef={formikRef}
            enableReinitialize
            onSubmit={() => {
              //
            }}
            initialValues={getInitialValuesFromQuestions(
              questions,
              extraPriceQuestionData,
              documentVisibilityData,
              documentVisibilityOptions
            )}
          >
            {(formikProps: any) => {
              const { values, errors, setFieldValue } = formikProps;

              return (
                <>
                  <FormTitleWrapper>
                    <FormTitle>
                      {t(
                        'Manage document visibility options for model questions'
                      )}
                    </FormTitle>
                  </FormTitleWrapper>

                  <Scrollable overflowVisible={questions.length < 4}>
                    <FormContainer
                      height={`${
                        questions.length * 50 + (questions.length - 1) * 30
                      }rem`}
                    >
                      {questions.map((question: IQuestion, index: number) => (
                        <Column key={index}>
                          <QuestionLabel>{question.name}</QuestionLabel>
                          <QuestionWrapper>
                            <Field
                              isMulti
                              width="100%"
                              name={question.initialKey}
                              options={documentVisibilityOptions}
                              components={getComponentsBasedOnDropdownType(
                                DropdownType.DEFAULT
                              )}
                              component={CustomSelect}
                              placeholder={question.name}
                              onSelect={(value: string) =>
                                setFieldValue(question.initialKey, value)
                              }
                              errorMessage={
                                areAllFieldsTouched &&
                                errors[question.initialKey]
                              }
                              menuPlacement={index < 2 ? 'bottom' : 'top'}
                            />
                          </QuestionWrapper>
                        </Column>
                      ))}
                      {extraPriceQuestionData && (
                        <Column key={`${EXTRA_PRICE_QUESTION_INITIAL_KEY}-key`}>
                          <QuestionLabel>
                            {extraPriceQuestionData.name}
                          </QuestionLabel>
                          <QuestionWrapper>
                            <Field
                              isMulti
                              width="100%"
                              name={EXTRA_PRICE_QUESTION_INITIAL_KEY}
                              options={documentVisibilityOptions}
                              components={getComponentsBasedOnDropdownType(
                                DropdownType.DEFAULT
                              )}
                              component={CustomSelect}
                              placeholder={extraPriceQuestionData.name}
                              onSelect={(value: string) =>
                                setFieldValue(
                                  EXTRA_PRICE_QUESTION_INITIAL_KEY,
                                  value
                                )
                              }
                              errorMessage={
                                areAllFieldsTouched &&
                                errors[EXTRA_PRICE_QUESTION_INITIAL_KEY]
                              }
                              menuPlacement={
                                questions.length < 2 ? 'bottom' : 'top'
                              }
                            />
                          </QuestionWrapper>
                        </Column>
                      )}
                    </FormContainer>
                  </Scrollable>
                </>
              );
            }}
          </Formik>
          <ButtonsWrapper>
            <Button
              disabled={isLoadingSubmitVisibilityConfiguration}
              label={t('Cancel')}
              secondary
              width={`${isPhone ? '100rem' : '200rem'}`}
              onClick={() => setManageVisibilityModelId(null)}
            />
            <Button
              disabled={isLoadingSubmitVisibilityConfiguration}
              label={t('Save')}
              primary
              width={`${isPhone ? '100rem' : '200rem'}`}
              onClick={() => {
                if (formikRef.current?.isValid) {
                  handleSubmitVisibilityConfiguration();
                } else {
                  if (!areAllFieldsTouched) {
                    setAreAllFieldsTouched(true);
                  }
                }
              }}
            />
          </ButtonsWrapper>
        </>
      ) : (
        <ErrorOcurredLabel>{t('An error occurred')}</ErrorOcurredLabel>
      )}
    </Modal>
  );
};

export default ManageModelQuestionDocumentVisibilityModal;
