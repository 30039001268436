import { ITableConfig, ITableRowConfig } from 'components/Table/types';

const rowConfigs: ITableRowConfig[] = [
  { header: 'Article number', flexValue: 1, isSortable: true, isLocked: true },
  { header: 'Description', flexValue: 2, isSortable: true },
  { header: 'Cutting length', flexValue: 1, isSortable: true },
  { header: 'Quantity', flexValue: 1, isSortable: true },
  { header: 'Unit', flexValue: 1, isSortable: true },
  { header: 'Not sell before', flexValue: 1, isSortable: true },
  { header: 'Not sell after', flexValue: 1, isSortable: true },
];

export const tableConfig: ITableConfig = {
  rowConfigs: rowConfigs,
};

// Key map for manage columns in table
export const columnKeyMap: Record<string, string> = {
  'Article number': 'id',
  Description: 'description',
  'Cutting length': 'length',
  Quantity: 'quantity_per',
  Unit: 'unit',
  'Not sell before': 'not_sell_before',
  'Not sell after': 'not_sell_after',
};
