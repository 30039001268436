import { House } from '@phosphor-icons/react';
import Icon from 'components/Icon/Icon';
import {
  AddressName,
  BottomSection,
  Container,
  DataTitle,
  DataValue,
  DataWrapper,
  ActionDropdownWrapper,
  IconWrapper,
  Row,
  TopSection,
} from './ShippingAddressCard.styled';
import { useTranslation } from 'react-i18next';
import { IShippingAddress } from '../types';
import { getCountryNameByCode } from './helpers';
import MobileActionDropdown from 'components/MobileActionDropdown/MobileActionDropdown';

interface IShippingAddressCardProps {
  data: IShippingAddress;
  selected: boolean;
  onClick: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

const ShippingAddressCard = ({
  data,
  selected,
  onClick,
  onEdit,
  onDelete,
}: IShippingAddressCardProps) => {
  const { t } = useTranslation();

  return (
    <Container onClick={onClick} selected={selected}>
      <TopSection>
        <IconWrapper>
          <Icon svg={House} size={25} weight="regular" />
        </IconWrapper>
        <AddressName>{data.name}</AddressName>
        <ActionDropdownWrapper>
          <MobileActionDropdown
            onEditClick={(e) => {
              e.stopPropagation();
              onEdit?.();
            }}
            onDeleteClick={(e) => {
              e.stopPropagation();
              onDelete?.();
            }}
          />
        </ActionDropdownWrapper>
      </TopSection>
      <BottomSection>
        <Row>
          <DataWrapper>
            <DataTitle>{t('Country')}</DataTitle>
            <DataValue>{getCountryNameByCode(data.address.country)}</DataValue>
          </DataWrapper>
        </Row>
        <Row>
          <DataWrapper>
            <DataTitle>{t('City')}</DataTitle>
            <DataValue>{data.address.city}</DataValue>
          </DataWrapper>
          <DataWrapper>
            <DataTitle>{t('Postal code')}</DataTitle>
            <DataValue>{data.address.zip_code}</DataValue>
          </DataWrapper>
        </Row>
        <Row>
          <DataWrapper>
            <DataTitle>{t('Street')}</DataTitle>
            <DataValue>{data.address.street}</DataValue>
          </DataWrapper>
          <DataWrapper>
            <DataTitle>{t('Number')}</DataTitle>
            <DataValue>{data.address.house_number}</DataValue>
          </DataWrapper>
        </Row>
        <Row>
          <DataWrapper>
            <DataTitle>{t('Contact person')}</DataTitle>
            <DataValue>{data.contact_person}</DataValue>
          </DataWrapper>
        </Row>
        <Row>
          <DataWrapper>
            <DataTitle>{t('Email')}</DataTitle>
            <DataValue>{data.email}</DataValue>
          </DataWrapper>
          <DataWrapper>
            <DataTitle>{t('Phone number')}</DataTitle>
            <DataValue>{data.phone_number}</DataValue>
          </DataWrapper>
        </Row>
      </BottomSection>
    </Container>
  );
};

export default ShippingAddressCard;
