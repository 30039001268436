import { COLORS, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  $activeDiscountOption: boolean;
}

export const Container = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${paddingMd};

  ${respondTo.bigPhone`
    padding: 15rem;
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20rem;
`;

export const Title = styled.div`
  font-size: 24rem;
  font-weight: 300;
  line-height: 36rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${COLORS.BLACK};
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20rem;
  margin-top: 10rem;
`;

export const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20rem;
  justify-content: center;

  input {
    text-align: center;
  }
`;

export const DiscountsTypeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20rem;
  border: 1px solid ${COLORS.BORDER};
  border-radius: 20rem;
`;

export const DiscountTypeOption = styled.span`
  display: block;
  padding: 5rem;
  width: 150rem;
  text-align: center;
  border-radius: 20rem;
  transition: 0.3s ease;
  cursor: pointer;
  color: ${COLORS.BLACK};

  ${(props: StyledProps) =>
    props.$activeDiscountOption &&
    css`
      background-color: ${COLORS.GREEN};
      color: ${COLORS.WHITE};
    `};
`;
