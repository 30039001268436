import ModelCard from 'components/ModelCard/ModelCard';
import {
  ModelCardsWrapper,
  NoContentLabel,
  NoContentLabelWrapper,
} from './FavoritesSectionGrid.styled';
import { IModel } from 'types/Model.types';
import {
  useGetFavoriteModels,
  useGetMaxDisplayedModelCardAmountBasedOnWindowWidth,
} from 'pages/ModelsPage/hooks';
import { FAVORITE_MODELS_PER_PAGE } from './constants';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { useTranslation } from 'react-i18next';
import ModelCardSkeleton from 'components/ModelCard/ModelCardSkeleton/ModelCardSkeleton';

const FavoritesSectionGrid = () => {
  const { t } = useTranslation();
  const { data: modelsData, isLoading } = useGetFavoriteModels(
    1,
    FAVORITE_MODELS_PER_PAGE,
    '',
    null,
    null
  );

  const models: IModel[] = modelsData?.models || [];

  const maxDisplayedModelCardAmount =
    useGetMaxDisplayedModelCardAmountBasedOnWindowWidth();

  // Slice the models array to not exceed the max displayed model card amount
  const displayedModels = models.slice(0, maxDisplayedModelCardAmount);

  return (
    <ModelCardsWrapper>
      {displayedModels.length ? (
        displayedModels.map((model: IModel, index: number) => (
          <ModelCard
            model={model}
            key={`favorite-grid-${index}`}
            onToggleFavoriteSuccess={() => {
              queryClient.invalidateQueries([
                ReactQueryKeys.GET_FAVORITE_MODELS,
              ]);
              queryClient.invalidateQueries([
                ReactQueryKeys.GET_RECENTLY_VIEWED_MODELS,
              ]);
              queryClient.invalidateQueries([
                ReactQueryKeys.GET_MODELS_INFINITE,
              ]);
            }}
          />
        ))
      ) : isLoading && !displayedModels.length ? (
        Array.from({ length: maxDisplayedModelCardAmount }, (_, index) => (
          <ModelCardSkeleton
            key={index}
            index={index}
            opacityCoeficient={0.15}
          />
        ))
      ) : (
        <NoContentLabelWrapper>
          <NoContentLabel>{t('No favourite models found')}</NoContentLabel>
        </NoContentLabelWrapper>
      )}
    </ModelCardsWrapper>
  );
};

export default FavoritesSectionGrid;
