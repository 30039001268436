import {
  BackLabel,
  BackWrapper,
  Card,
  ConfirmWrapper,
  Container,
  SectionTitle,
  DeliveryDateWrapper,
  OrderReference,
  ShippingAddress,
  ShippingWrapper,
  Title,
  TopSection,
  ShippingTitleWrapper,
} from './CheckoutPage.styled';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon/Icon';
import { CaretLeft } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { useNavigate } from 'react-router-dom';
import ShippingAddressCard from './ShippingAddressCard/ShippingAddressCard';
import Button from 'components/Button/Button';
import DatePicker from 'components/DatePicker/DatePicker';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { useState } from 'react';
import Input from 'components/Input/Input';
import {
  IPostCartOrderDTO,
  useDeleteShippingAddress,
  useGetCompanyShippingAddresses,
  useGetDeliveryDays,
  usePostCartOrder,
} from './hooks';
import moment from 'moment';
import Loader from 'components/Loader/Loader';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { IShippingAddress } from './types';
import { InfoModal } from 'components/Modals/InfoModal/InfoModal';
import { RoutesConfig } from 'navigation/routes';
import AddEditAddressModal from 'pages/CheckoutPage/AddEditAddressModal/AddEditAddressModal';
import { ConfirmModal } from 'components/Modals/ConfirmModal/ConfirmModal';

const CheckoutPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isSmallTablet } = useBreakpointFlag();
  const [reference, setReference] = useState<string>('');
  const [addressForEditing, setAddressForEditing] =
    useState<IShippingAddress | null>(null);
  const [addressIdForDeleting, setAddressIdForDeleting] = useState<
    number | null
  >(null);
  const [date, setDate] = useState<moment.Moment | null>(null);
  const [addressId, setAddressId] = useState<number | null>(null);
  const [isInfoModal, setIsInfoModal] = useState<boolean>(false);
  const [isAddEditAddressModal, setIsAddEditAddressModal] =
    useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const { company, supplier } = useSelector(
    (state: IRootReducerState) => state.userInfo
  );

  const maxConnLeadTime = useSelector(
    (state: IRootReducerState) => state.shoppingCartInfo.maxConnLeadTime
  );

  const { data: shippingAddressesData, isLoading: shippingAddressesIsLoading } =
    useGetCompanyShippingAddresses(company.id);

  const { data: deliveryDays } = useGetDeliveryDays(company.id, supplier.id);

  const { mutate: postCartOrder, isLoading: postCartOrderIsLoading } =
    usePostCartOrder(() => setIsInfoModal(true));

  const { mutate: deleteShippingAddress } = useDeleteShippingAddress();

  const handleDeleteAddress = () => {
    if (addressIdForDeleting)
      deleteShippingAddress(addressIdForDeleting, {
        onSuccess: () => {
          setIsConfirmModalOpen(false);
        },
      });
  };

  return (
    <Container>
      <TopSection>
        <BackWrapper onClick={() => navigate(-1)}>
          <Icon svg={CaretLeft} color={COLORS.GREEN} onClick={() => null} />
          <BackLabel>{t('Back')}</BackLabel>
        </BackWrapper>
        <Title>{t('Checkout')}</Title>
      </TopSection>
      {(postCartOrderIsLoading || shippingAddressesIsLoading) && <Loader />}

      <Card>
        <ShippingWrapper>
          <ShippingTitleWrapper>
            <SectionTitle>{t('Shipping address')}</SectionTitle>
            <Button
              secondary
              label={t('Add another address')}
              onClick={() => {
                setIsAddEditAddressModal(true);
              }}
            />
          </ShippingTitleWrapper>
          <ShippingAddress>
            {shippingAddressesData?.map((address: IShippingAddress) => (
              <ShippingAddressCard
                key={address.id}
                data={address}
                selected={addressId === address.id}
                onClick={() => {
                  if (address.id === addressId) {
                    setAddressId(null);
                  } else {
                    setAddressId(address.id);
                  }
                }}
                onEdit={() => {
                  setAddressForEditing(address);
                  setIsAddEditAddressModal(true);
                }}
                onDelete={() => {
                  setIsConfirmModalOpen(true);
                  setAddressIdForDeleting(address.id);
                }}
              />
            ))}
          </ShippingAddress>
        </ShippingWrapper>

        <DeliveryDateWrapper>
          <SectionTitle>{t('Requested delivery date')}</SectionTitle>
          <DatePicker
            date={date}
            setDate={(newValue: any) => setDate(newValue)}
            width={isSmallTablet ? '100%' : ''}
            deliveryDays={deliveryDays}
            maxConnLeadTime={maxConnLeadTime}
          />
        </DeliveryDateWrapper>

        <OrderReference>
          <SectionTitle>{t('Order reference')}</SectionTitle>
          <Input
            height={'42rem'}
            placeholder={t('Reference')}
            value={reference}
            width={isSmallTablet ? '100%' : '300rem'}
            wrapperStyles={{ width: isSmallTablet ? '100%' : '300rem' }}
            onChange={(e) => setReference(e.target.value)}
          />
        </OrderReference>

        <ConfirmWrapper>
          <Button
            pwId="confirm-shopping-cart"
            disabled={!reference}
            primary
            label={t('Confirm')}
            width={'200rem'}
            onClick={() => {
              const postCardOrderDTO: IPostCartOrderDTO = {
                order_reference: reference,
                delivery_date: date ? date.format('DD-MM-YYYY') : null,
                addressId: addressId ?? null,
              };
              postCartOrder(postCardOrderDTO);
            }}
          />
        </ConfirmWrapper>
      </Card>

      <InfoModal
        level="THIRD"
        isOpen={isInfoModal}
        setIsOpen={setIsInfoModal}
        buttonClick={() => {
          navigate(RoutesConfig.Models.fullPath);
          setIsInfoModal(false);
        }}
        onCloseAction={() => navigate(RoutesConfig.Models.fullPath)}
        buttonText={t('Ok')}
        title={t('Order portal')}
        description={t('Your order has been placed successfully')}
      />

      {isAddEditAddressModal && (
        <AddEditAddressModal
          isOpen={isAddEditAddressModal}
          setIsOpen={(val) => {
            setIsAddEditAddressModal(val);
            if (!val) setAddressForEditing(null);
          }}
          addressForEditing={addressForEditing}
        />
      )}

      <ConfirmModal
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        title={t('Delete address')}
        description={t('Are you sure you want to delete this address')}
        onConfirm={handleDeleteAddress}
        onCancel={() => setIsConfirmModalOpen(false)}
      />
    </Container>
  );
};

export default CheckoutPage;
