import {
  AdditionalMargin,
  Column,
  ContainerGrayBottomBorder,
  FormItemContainer,
  QuestionName,
  QuestionNameAsteriskInfoRow,
} from '../QuestionRow/QuestionRow.styled';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import {
  IExtraPriceQuestionData,
  IExtraPriceQuestionOption,
} from 'types/Configuration.types';
import { generateOptionFromExtraPriceQuestionOption } from 'pages/DealerPanel/DealerPanelModelsPage/DealerPanelCategory/ModelConfiguration/ExtraPriceQuestionModal/ExtraPriceQuestionSelect/helpers';
import { getComponentsBasedOnDropdownType } from '../FormItem/helpers';
import { DropdownType } from '../FormItem/constants';
import { useTranslation } from 'react-i18next';
import { Select } from 'components/Select/Select';
import { ExtraPriceQuestionFormWrapper } from './ExtraPriceQuestionFormWrapper/ExtraPriceQuestionFormWrapper';
import { IOption } from 'components/Select/type';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedExtraPriceQuestionOption } from 'store/SubmitConfiguration/actions/submitConfiguration';
import { IRootReducerState } from 'store/store';
import { useGetExtraPriceQuestionOptionValueInclVAT } from 'components/SubHeader/hooks';

interface IExtraPriceQuestionRowProps {
  extraPriceQuestionData: IExtraPriceQuestionData;
}

export const ExtraPriceQuestionRow = ({
  extraPriceQuestionData,
}: IExtraPriceQuestionRowProps) => {
  const { selectedExtraPriceQuestionOption } = useSelector(
    (state: IRootReducerState) => state.submitConfigurationInfo
  );
  const { isSmallTablet } = useBreakpointFlag();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const extraPriceOptionValueInclVAT =
    useGetExtraPriceQuestionOptionValueInclVAT(
      selectedExtraPriceQuestionOption?.value
    );

  return (
    <ContainerGrayBottomBorder showBorder={false}>
      <FormItemContainer hasValue={!!selectedExtraPriceQuestionOption}>
        <Column>
          <QuestionNameAsteriskInfoRow>
            <QuestionName>{extraPriceQuestionData.name}</QuestionName>
          </QuestionNameAsteriskInfoRow>
          <AdditionalMargin />
        </Column>
        <ExtraPriceQuestionFormWrapper
          questionPrice={extraPriceOptionValueInclVAT || undefined}
        >
          <Select
            key={JSON.stringify(selectedExtraPriceQuestionOption)}
            height={'40rem'}
            width={isSmallTablet ? '100%' : '250rem'}
            name={'extraPriceQuestion'}
            defaultValue={selectedExtraPriceQuestionOption}
            options={extraPriceQuestionData.options.map(
              (
                extraPriceQuestionOption: IExtraPriceQuestionOption,
                index: number
              ) =>
                generateOptionFromExtraPriceQuestionOption(
                  extraPriceQuestionOption,
                  index
                )
            )}
            translate={false}
            placeholder={t('Choose')}
            isMulti={false}
            components={getComponentsBasedOnDropdownType(DropdownType.DEFAULT)}
            onChange={(option: IOption) =>
              dispatch(
                setSelectedExtraPriceQuestionOption(option.original_option)
              )
            }
          />
        </ExtraPriceQuestionFormWrapper>
      </FormItemContainer>
    </ContainerGrayBottomBorder>
  );
};
