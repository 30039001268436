import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { Modal } from '../../../components/Modals/Modal/Modal';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import {
  ButtonsWrapper,
  Container,
  FormikContainer,
  Row,
  RowSplit,
  SelectWrapper,
  StyledSelect,
  Title,
} from './AddEditAddressModal.styled';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import { Formik } from 'formik';
import { IShippingAddress, IShippingAddressDTO } from '../types';
import CustomSelect from 'components/Select/FormikSelect';
import { useGetCountryOptions } from 'utils/hooks/useGetCountryOptions';
import { IOption } from 'components/Select/type';
import { IRootReducerState } from 'store/store';
import { useSelector } from 'react-redux';
import { useCreateShippingAddress, useEditShippingAddress } from '../hooks';
import { splitHouseNumber } from './helpers';
import { Input } from 'components/Input/InputFormik';
import { addEditAddressSchema } from './validation';

export interface IAddEditAddressModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  addressForEditing: IShippingAddress | null;
}

const AddEditAddressModal = ({
  isOpen,
  setIsOpen,
  addressForEditing,
}: IAddEditAddressModalProps) => {
  const { t } = useTranslation();
  const formikRef = useRef<any>();
  const { isPhone } = useBreakpointFlag();
  const [areAllFieldsTouched, setAreAllFieldsTouched] =
    useState<boolean>(false);

  const countryOptions: IOption[] = useGetCountryOptions();

  const companyId = useSelector(
    (state: IRootReducerState) => state.userInfo.company.id
  );

  const { mutate: createShippingAddress, isLoading: isLoadingCreate } =
    useCreateShippingAddress();

  const { mutate: editShippingAddress, isLoading: isLoadingEdit } =
    useEditShippingAddress();

  const handleSubmit = async () => {
    const { values, isValid } = formikRef.current;

    if (!isValid) {
      return;
    }

    const house_number =
      values.number + (values.extension ? ` ${values.extension}` : '');

    const dto: IShippingAddressDTO = {
      name: values.name,
      contact_person: values.contact,
      email: values.email,
      phone_number: values.phone,
      address: {
        street: values.street,
        city: values.city,
        zip_code: values.code,
        house_number,
        country: values.country,
      },
      ...(!addressForEditing && {
        company_id: companyId,
      }),
    };

    if (addressForEditing) {
      editShippingAddress(
        {
          id: addressForEditing.id,
          data: dto,
        },
        {
          onSuccess: () => {
            setIsOpen(false);
          },
        }
      );
    } else {
      createShippingAddress(dto, {
        onSuccess: () => {
          setIsOpen(false);
        },
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      level="SECOND"
      onClose={() => setIsOpen(false)}
      modalStyle={{
        margin: 'auto',
        width: isPhone ? '90%' : '500rem',
        minWidth: isPhone ? '90%' : 'auto',
        position: 'fixed',
      }}
    >
      <Container>
        <Title>
          {addressForEditing ? t('Edit address') : t('Create address')}
        </Title>
        <Formik
          innerRef={formikRef}
          enableReinitialize
          initialValues={{
            name: addressForEditing?.name ?? '',
            country: addressForEditing?.address.country ?? 'NL',
            city: addressForEditing?.address.city ?? '',
            code: addressForEditing?.address.zip_code ?? '',
            street: addressForEditing?.address.street ?? '',
            ...splitHouseNumber(addressForEditing?.address.house_number),
            contact: addressForEditing?.contact_person ?? null,
            email: addressForEditing?.email ?? null,
            phone: addressForEditing?.phone_number ?? null,
          }}
          validationSchema={addEditAddressSchema}
          validateOnChange={true}
          validateOnBlur={true}
          validateOnMount={true}
          onSubmit={() => {
            // onSubmit
          }}
        >
          {({ handleBlur, setFieldValue, errors, touched, isValid }) => {
            return (
              <FormikContainer>
                <Row>
                  <Input
                    errorMessage={
                      areAllFieldsTouched || touched['name']
                        ? errors['name']
                        : ''
                    }
                    height={'41rem'}
                    name="name"
                    placeholder={t('Name')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('name', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '100%',
                    }}
                  />
                </Row>
                <Row>
                  <SelectWrapper>
                    <StyledSelect
                      errorMessage={
                        areAllFieldsTouched || touched['country']
                          ? errors['country']
                          : ''
                      }
                      height={'41rem'}
                      name="country"
                      options={countryOptions}
                      component={CustomSelect}
                      isMulti={false}
                      onSelect={(value: any) => {
                        setFieldValue('country', value);
                      }}
                    />
                  </SelectWrapper>
                </Row>
                <Row>
                  <Input
                    errorMessage={
                      areAllFieldsTouched || touched['city']
                        ? errors['city']
                        : ''
                    }
                    height={'41rem'}
                    name="city"
                    placeholder={t('City')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('city', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '100%',
                    }}
                  />
                </Row>
                <Row>
                  <Input
                    errorMessage={
                      areAllFieldsTouched || touched['code']
                        ? errors['code']
                        : ''
                    }
                    height={'41rem'}
                    name="code"
                    placeholder={t('Postal code')}
                    onBlur={(e: any) => {
                      const value = e.target.value.trim();
                      setFieldValue('code', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '100%',
                    }}
                  />
                </Row>
                <Row>
                  <Input
                    errorMessage={
                      areAllFieldsTouched || touched['street']
                        ? errors['street']
                        : ''
                    }
                    height={'41rem'}
                    name="street"
                    placeholder={t('Street')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('street', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '100%',
                    }}
                  />
                </Row>
                <RowSplit>
                  <Input
                    errorMessage={
                      areAllFieldsTouched || touched['number']
                        ? errors['number']
                        : ''
                    }
                    height={'41rem'}
                    name="number"
                    placeholder={t('Number')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('number', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: isPhone ? '100%' : '50%',
                    }}
                  />
                  <Input
                    errorMessage={
                      areAllFieldsTouched || touched['extension']
                        ? errors['extension']
                        : ''
                    }
                    height={'41rem'}
                    name="extension"
                    placeholder={t('Extension')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('extension', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: isPhone ? '100%' : '50%',
                    }}
                  />
                </RowSplit>
                <Row>
                  <Input
                    errorMessage={
                      areAllFieldsTouched || touched['contact']
                        ? errors['contact']
                        : ''
                    }
                    height={'41rem'}
                    name="contact"
                    placeholder={t('Contact person')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('contact', !value.length ? null : value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '100%',
                    }}
                  />
                </Row>
                <Row>
                  <Input
                    errorMessage={
                      areAllFieldsTouched || touched['email']
                        ? errors['email']
                        : ''
                    }
                    height={'41rem'}
                    name="email"
                    placeholder={t('Email')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('email', !value.length ? null : value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '100%',
                    }}
                  />
                </Row>
                <Row>
                  <Input
                    errorMessage={
                      areAllFieldsTouched || touched['phone']
                        ? errors['phone']
                        : ''
                    }
                    height={'41rem'}
                    name="phone"
                    placeholder={t('Phone number')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('phone', !value.length ? null : value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '100%',
                    }}
                  />
                </Row>

                <ButtonsWrapper>
                  <Button
                    width={isPhone ? '150rem' : '200rem'}
                    onClick={() => setIsOpen(false)}
                    label={t('Cancel')}
                    secondary
                    disabled={isLoadingCreate || isLoadingEdit}
                  />
                  <Button
                    disabled={isLoadingCreate || isLoadingEdit || !isValid}
                    data-testid="create-button"
                    width={isPhone ? '150rem' : '200rem'}
                    onClick={() => {
                      if (isValid) {
                        handleSubmit();
                      } else if (!areAllFieldsTouched) {
                        setAreAllFieldsTouched(true);
                      }
                    }}
                    label={addressForEditing ? t('Confirm') : t('Create')}
                    primary
                  />
                </ButtonsWrapper>
              </FormikContainer>
            );
          }}
        </Formik>
      </Container>
    </Modal>
  );
};

export default AddEditAddressModal;
