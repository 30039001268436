import { COLORS, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Form = styled.form`
  max-width: 100%;
  width: 100%;
`;

export const Label = styled.label`
  font-size: 20rem;
  font-weight: 300rem;
  color: ${COLORS.BLACK};
  text-align: center;
  margin-bottom: 20rem;
  display: block;

  ${respondTo.smallTablet`
    font-size: 18rem;
  `}

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const EmailInputWrapper = styled.div`
  margin-bottom: ${marginSm};
  position: relative;
  width: 580rem;

  ${respondTo.bigPhone`
    width: 100%;
    max-width: 100%;
  `}
`;

export const ButtonContainer = styled.div`
  width: 100%;
`;

export const BackToLoginLabel = styled.label`
  font-size: 14rem;
  font-weight: 200;
  cursor: pointer;
  color: ${COLORS.BLACK};
  display: block;
  margin-top: 20rem;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
`;
