import {
  AbsoluteBackWrapper,
  BackLabel,
  Container,
  NoContentLabel,
  Title,
  TitleWrapper,
} from './BomPage.styled';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useTranslation } from 'react-i18next';
import { useNavigateWithQuery } from 'utils/hooks/useNavigateWithQuery';
import { RoutesConfig } from 'navigation/routes';
import Icon from 'components/Icon/Icon';
import { setIncludeBomItems } from 'store/Form/actions/form';
import { useEffect } from 'react';
import Table from 'components/Table/Table';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { CaretLeft } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { UserCardSkeleton } from 'pages/UsersPage/UserCard/UserCardSkeleton';
import { BomItemCard } from './BomItemCard/BomItemCard';
import { columnKeyMap, tableConfig } from './constants';
import { BomTableFooter } from './BomTableFooter/BomTableFooter';
import { formatBomItems, IFormattedBOMItem } from './helpers';
import TableColumnSelector from './TableColumnSelector/TableColumnSelector';
import { savePageFilters } from 'store/Filters/actions/filters';
import { FiltersPageEnum } from 'store/Filters/constants';

const BomPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigateWithQuery();

  const bomItems =
    useSelector((state: IRootReducerState) => state.modelInfo.bomItems) || [];

  useEffect(() => {
    return () => {
      dispatch(setIncludeBomItems(false));
    };
  }, []);

  const visibleColumns = useSelector(
    (state: IRootReducerState) =>
      state.filtersInfo?.bomPage?.visibleColumns ??
      tableConfig.rowConfigs.map((col) => col.header)
  );

  useEffect(() => {
    if (!visibleColumns || visibleColumns.length === 0) {
      dispatch(
        savePageFilters({
          page: FiltersPageEnum.BOM,
          data: {
            visibleColumns: tableConfig.rowConfigs.map((col) => col.header),
          },
        })
      );
    }
  }, [dispatch, visibleColumns]);

  const queryRequestStatus = useSelector(
    (state: IRootReducerState) => state.modelInfo.queryStatus
  );

  const formattedBomItems: IFormattedBOMItem[] = formatBomItems(bomItems) || [];

  const filteredTableData = formattedBomItems.map((item) =>
    Object.fromEntries(
      Object.entries(item).filter(([key]) =>
        visibleColumns?.includes(
          Object.keys(columnKeyMap).find((col) => columnKeyMap[col] === key) ||
            ''
        )
      )
    )
  );

  const { isSmallTablet } = useBreakpointFlag();

  return (
    <Container>
      <AbsoluteBackWrapper
        onClick={() =>
          navigate(RoutesConfig.Configure.fullPath, { replace: true })
        }
      >
        <Icon
          svg={CaretLeft}
          color={COLORS.GREEN}
          size={20}
          weight="bold"
          onClick={() => null}
        />
        <BackLabel>{t('Back')}</BackLabel>
      </AbsoluteBackWrapper>
      <TitleWrapper>
        <Title>{t('Bill of materials')}</Title>

        {!isSmallTablet && (
          <TableColumnSelector
            columns={tableConfig.rowConfigs}
            visibleColumns={visibleColumns}
          />
        )}
      </TitleWrapper>
      {!queryRequestStatus?.attempt && !Object.keys(bomItems).length ? (
        <NoContentLabel>{t('There are currently no BOM items')}</NoContentLabel>
      ) : isSmallTablet ? (
        queryRequestStatus?.attempt ? (
          <>
            <UserCardSkeleton />
            <UserCardSkeleton />
            <UserCardSkeleton isLast />
          </>
        ) : (
          formattedBomItems.map(
            (formattedBomItem: IFormattedBOMItem, index: number) => (
              <BomItemCard
                bomItem={formattedBomItem}
                isLast={index === formattedBomItems.length - 1}
                key={`bom-item-card-${index}`}
              />
            )
          )
        )
      ) : (
        <Table
          isLoading={queryRequestStatus?.attempt}
          perPage={10}
          tableData={filteredTableData}
          tableConfig={{
            rowConfigs: tableConfig.rowConfigs.filter((col) =>
              visibleColumns?.includes(col.header)
            ),
          }}
          tableFooter={BomTableFooter}
        />
      )}
    </Container>
  );
};

export default BomPage;
