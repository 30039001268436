import * as Yup from 'yup';

export const addEditExtraPriceQuestionOptionSchema = Yup.object({
  label: Yup.string().required('Required field'),
  isDefault: Yup.boolean().required('Required field'),
  value: Yup.number()
    .typeError('Amount must be a number')
    .test('twoDecimal', 'Amount can only have two decimal places', (value) => {
      console.log('typeof value: ', typeof value);

      if (typeof value !== 'number' || !Number.isFinite(value)) {
        return false;
      }
      const roundedValue = Number(value.toFixed(2));
      return roundedValue === value;
    })
    .required('Required field'),
});
