import { Dispatch, SetStateAction, useState } from 'react';
import { Level } from '../Modal/type';
import { Modal } from '../Modal/Modal';
import {
  ButtonContainer,
  Container,
  DiscountsTypeWrapper,
  DiscountTypeOption,
  InputWrapper,
  Title,
  TitleContainer,
} from './EditDiscountModal.styled';
import Button from 'components/Button/Button';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { useTranslation } from 'react-i18next';
import Input from 'components/Input/Input';
import { CurrencyEur, Percent } from '@phosphor-icons/react';
import { validateDiscountValue } from './helpers';

interface EditDiscountModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: (discountValue: string, discountType: DiscountsType) => void;
  level?: Level;
  disabled?: boolean;
  defaultValue?: string | null;
  defaultType?: DiscountsType | null;
}

export enum DiscountsType {
  PERCENTAGE = 'percentage',
  AMOUNT = 'amount',
}

const EditDiscountModal = ({
  isOpen,
  setIsOpen,
  onConfirm,
  level = 'FIRST',
  disabled,
  defaultValue,
  defaultType,
}: EditDiscountModalProps) => {
  const { t } = useTranslation();
  const { isSmallPhone } = useBreakpointFlag();
  const [discount, setDiscount] = useState<string>(defaultValue ?? '');
  const [discountType, setDiscountType] = useState<DiscountsType>(
    defaultType ?? DiscountsType.PERCENTAGE
  );

  const errorMessage = validateDiscountValue(discount, discountType, t);

  return (
    <div onMouseDown={(e: any) => e.stopPropagation()}>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        modalStyle={{
          margin: 'auto',
          width: isSmallPhone ? '95%' : '500rem',
          minWidth: isSmallPhone ? '95%' : 'auto',
          position: 'fixed',
        }}
        level={level}
      >
        <Container>
          <TitleContainer>
            <Title>{t('Edit discount')}</Title>
          </TitleContainer>

          <DiscountsTypeWrapper>
            <DiscountTypeOption
              $activeDiscountOption={discountType === DiscountsType.PERCENTAGE}
              onClick={() => {
                setDiscountType(DiscountsType.PERCENTAGE);
                setDiscount('');
              }}
            >
              {t('Percentage')}
            </DiscountTypeOption>
            <DiscountTypeOption
              $activeDiscountOption={discountType === DiscountsType.AMOUNT}
              onClick={() => {
                setDiscountType(DiscountsType.AMOUNT);
                setDiscount('');
              }}
            >
              {t('Net Amount')}
            </DiscountTypeOption>
          </DiscountsTypeWrapper>

          <InputWrapper>
            <Input
              label=""
              placeholder=""
              value={discount}
              errorMessage={errorMessage}
              width={'100%'}
              icon={
                discountType === DiscountsType.PERCENTAGE
                  ? Percent
                  : CurrencyEur
              }
              hideNumberArrows
              type="number"
              wrapperStyles={{ width: '100%', maxWidth: '300rem' }}
              onChange={(e) => {
                const value = e.target.value;
                if (value === '') {
                  setDiscount(value);
                  return;
                }
                const number = Number(value);
                if (isNaN(number) || number < 0) {
                  return;
                }
                setDiscount(value);
              }}
              onBlur={() => {
                const value = parseInt(discount, 10);
                setDiscount(isNaN(value) || value < 0 ? '' : value.toString());
              }}
            />
          </InputWrapper>

          <ButtonContainer>
            <Button
              disabled={disabled}
              onClick={() => setIsOpen(false)}
              secondary
              width={isSmallPhone ? '150rem' : '200rem'}
              label={t('Cancel')}
            />
            <Button
              disabled={!discount || !!errorMessage}
              onClick={() => onConfirm(discount, discountType)}
              primary
              width={isSmallPhone ? '150rem' : '200rem'}
              label={t('Confirm')}
            />
          </ButtonContainer>
        </Container>
      </Modal>
    </div>
  );
};

export default EditDiscountModal;
