import { IQuestion } from 'types/Question.types';

export const getValuesForSingleListTableColorAndRadioQuestions = (
  question: IQuestion,
  skipSetDefault: boolean,
  isForTable = false
) => {
  const areOutputsNull = checkAreOutputsNull(question.outputs);
  const indexesOfInputsInOutputs: number[] = getIndexesOfInputsInOutputs(
    question.inputs,
    question.outputs
  );

  // Find the selected option based on the outputs
  // The default option is used if it exists and if the outputs are null
  for (const option of question.options) {
    let matchingOption = true;
    for (const index of indexesOfInputsInOutputs) {
      if (
        option[index] !==
        (areOutputsNull
          ? skipSetDefault
            ? null
            : question.default[index]
          : question.outputs[Object.keys(question.outputs)[index]])
      ) {
        matchingOption = false;
      }
    }
    if (matchingOption) {
      return isForTable ? [option] : option[0];
    }
  }
};

export const getValuesForMultipleListAndTableQuestions = (
  question: IQuestion,
  skipSetDefault: boolean,
  isForTable = false
) => {
  const areOutputsNull = checkAreOutputsNull(question.outputs);
  const indexesOfInputsInOutputs: number[] = getIndexesOfInputsInOutputs(
    question.inputs,
    question.outputs
  );
  const selectedValues: any[] = [];

  for (const option of question.options) {
    for (const indexOfInputInOutputs of indexesOfInputsInOutputs) {
      if (!areOutputsNull) {
        // Non-null outputs
        let outputsArray =
          question.outputs[
            Object.keys(question.outputs)[indexOfInputInOutputs]
          ];
        if (!Array.isArray(outputsArray)) {
          outputsArray = [outputsArray];
        }
        if (outputsArray.includes(option[indexOfInputInOutputs])) {
          selectedValues.push(isForTable ? option : option[0]);
        }
      } else {
        // Null outputs
        if (!skipSetDefault) {
          for (const defaultOption of question.default) {
            if (
              (defaultOption as string[]).includes(
                option[indexOfInputInOutputs]
              )
            ) {
              selectedValues.push(isForTable ? option : option[0]);
            }
          }
        }
      }
    }
  }
  return selectedValues;
};

export const getIndexesOfInputsInOutputs = (
  inputs: string[],
  outputs: object
): number[] => {
  const indexesOfInputsInOutputs: number[] = [];
  const outputKeys = Object.keys(outputs);
  // Finds the index of each input in the outputs object keys array
  for (const inputValue of inputs) {
    const indexOfInputInOutput = outputKeys.indexOf(inputValue);
    if (indexOfInputInOutput !== -1) {
      indexesOfInputsInOutputs.push(indexOfInputInOutput);
    }
  }
  return indexesOfInputsInOutputs;
};

export const checkAreOutputsNull = (outputs: object) => {
  return Object.values(outputs).every((value) => value === null);
};

export const questionHasValue = (values: any, question: IQuestion) => {
  return Array.isArray(values[question.initialKey])
    ? !!values[question.initialKey].length
    : hasValue(values[question.initialKey]);
};

const hasValue = (value: any) => {
  if ([undefined, '', null].includes(value)) {
    return false;
  } else {
    return true;
  }
};

// Questions param is used here to modify the keys of valuesFromOutputs object from question.initialKey to question.name, because erp expects that type of object structure
export const formatValuesFromOutputs = (
  valuesFromOutputs: object,
  questions: IQuestion[]
) => {
  const formattedValuesFromOutputs = {};
  for (const valuesFromOutputsInitialKey of Object.keys(valuesFromOutputs)) {
    const questionName =
      questions.find(
        (question: IQuestion) =>
          question.initialKey === valuesFromOutputsInitialKey
      )?.name || 'Unknown question';

    formattedValuesFromOutputs[questionName] =
      valuesFromOutputs[valuesFromOutputsInitialKey];
  }

  return formattedValuesFromOutputs;
};

export const extractQuestionPrice = (
  pricing: object,
  questionInitialKey: string,
  showNetPrices: boolean
): number | undefined => {
  if (!pricing) {
    return undefined;
  }
  let price = 0;
  Object.keys(pricing).forEach((pricingKey) => {
    const pricingItem = pricing[pricingKey];
    if (pricingItem.question === questionInitialKey) {
      if (showNetPrices && pricingItem.type.includes('net')) {
        price += pricing[pricingKey].value;
      } else if (!showNetPrices && pricingItem.type.includes('gross')) {
        price += pricing[pricingKey].value;
      }
    }
  });

  return price || undefined;
};
