import { COLORS, marginSm, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import { hoverLineOut } from 'assets/styled/commonStyles';
import { Form } from 'formik';
import styled from 'styled-components';

export const Container = styled.div`
  height: fit-content;
  display: flex;

  flex-direction: column;
  align-items: center;
  gap: 30rem;
  padding: ${paddingMd};

  ${respondTo.bigPhone`
    padding: 15rem;
    gap: 20rem;
  `}
`;

export const Title = styled.label`
  padding-left: 30rem;
  padding-right: 30rem;
  font-weight: 400;
  font-size: 24rem;
  line-height: 32rem;
  color: ${COLORS.BLACK};
  text-align: center;

  ${respondTo.bigPhone`
    font-size: 20rem;
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-self: center;
  margin-top: 30rem;
  gap: ${marginSm};
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20rem;
`;

export const IsDefaultDeleteRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  position: relative;
`;

export const DeleteDiv = styled.div`
  display: flex;
  gap: 5rem;
  color: ${COLORS.RED};
  font-size: 16rem;
  align-items: center;
  cursor: pointer;
`;

export const DeleteLabel = styled.label`
  cursor: pointer;
  ${hoverLineOut}
`;

export const InputLabel = styled.label`
  font-size: 16rem;
  color: ${COLORS.BLACK};
`;

export const StyledForm = styled(Form)`
  width: 100%;
`;

export const ErrorMessage = styled.label`
  color: ${COLORS.RED};
  position: absolute;
  bottom: -20rem;
  right: 0;
  font-size: 12rem;
`;
