import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: min-content;
  padding: 15rem;
  height: 100%;
  gap: 25rem;

  ${respondTo.bigPhone`
    width: 100%;
    max-width: 100%;
  `}
`;

export const LogoWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Logo = styled.img`
  width: 179rem;
  height: 261rem;

  @media (max-height: 760px) {
    height: 225rem;
  }

  ${respondTo.smallTablet`
    width: 150rem;
    height: 240rem;
  `}

  ${respondTo.bigPhone`
    width: 120rem;
    height: 200rem;
  `}
`;

export const SelectCompanyLabel = styled.label`
  text-align: center;
  font-size: 50rem;
  line-height: 75rem;
  font-weight: 500rem;
  color: ${COLORS.BLACK};

  ${respondTo.smallTablet`
    font-size: 40rem;
  `}

  ${respondTo.bigPhone`
    font-size: 30rem;
    line-height: 1.2;
  `}
`;

export const AbsoluteWrapper = styled.div`
  position: absolute;
  right: 0rem;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AbsoluteItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50rem;
`;
