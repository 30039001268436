import { useTranslation } from 'react-i18next';
import AuthWrapper from '../AuthWrapper/AuthWrapper';
import { useResetPassword } from './hooks';
import {
  ButtonContainer,
  EyeWrapper,
  PasswordErrorMessage,
  PasswordInputWrapper,
} from './ResetPasswordPage.styled';
import Input from 'components/Input/Input';
import { useState } from 'react';
import PageNotFound from 'pages/PageNotFound/PageNotFound';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import Button from 'components/Button/Button';
import { usePageTitle } from 'utils/hooks/usePageTitle';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { Eye, EyeSlash } from '@phosphor-icons/react';

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [hideConfirmPassword, setHideConfirmPassword] = useState<boolean>(true);
  const [newPassword, setNewPassword] = useState<string>('');
  const [hideNewPassword, setHideNewPassword] = useState<boolean>(true);
  const [passwordInputErrorMessage, setPasswordInputErrorMessage] =
    useState<string>('');

  const { isPhone } = useBreakpointFlag();

  const handleEyeClickNewPassword = () => {
    setHideNewPassword(!hideNewPassword);
  };

  const handleEyeClickConfirmPassword = () => {
    setHideConfirmPassword(!hideConfirmPassword);
  };

  const handleNewPasswordChange = (newPasswordInput: string) => {
    setNewPassword(newPasswordInput);
    doPasswordsMatch(newPasswordInput, confirmPassword)
      ? setPasswordInputErrorMessage('')
      : setPasswordInputErrorMessage('Passwords do not match');
  };

  const handleConfirmPasswordChange = (confirmPasswordInput: string) => {
    setConfirmPassword(confirmPasswordInput);
    doPasswordsMatch(confirmPasswordInput, newPassword)
      ? setPasswordInputErrorMessage('')
      : setPasswordInputErrorMessage('Passwords do not match');
  };

  const doPasswordsMatch = (firstPassword: string, secondPassword: string) => {
    return firstPassword === secondPassword;
  };

  const { mutate: resetPassword } = useResetPassword();

  if (!token) return <PageNotFound />;

  const submitResetPassword = () => {
    resetPassword({ newPassword, confirmPassword, token });
  };

  usePageTitle('New password');

  return (
    <AuthWrapper title={t('New password')}>
      <PasswordInputWrapper>
        <Input
          pwId={`new-password-field`}
          placeholder={t('Password')}
          value={newPassword}
          type={hideNewPassword ? 'password' : ''}
          onChange={(e) => handleNewPasswordChange(e.target.value)}
          height={'55rem'}
          wrapperStyles={{ width: '100%' }}
        />
        <EyeWrapper onClick={() => handleEyeClickNewPassword()}>
          <Icon
            svg={hideNewPassword ? Eye : EyeSlash}
            color={COLORS.BLACK}
            size={20}
          />
        </EyeWrapper>
      </PasswordInputWrapper>
      <PasswordInputWrapper>
        <Input
          pwId={`confirm-password-field`}
          placeholder={t('Confirm password')}
          value={confirmPassword}
          type={hideConfirmPassword ? 'password' : ''}
          onChange={(e) => handleConfirmPasswordChange(e.target.value)}
          height={'55rem'}
          error={true}
          wrapperStyles={{ width: '100%' }}
        />
        <EyeWrapper onClick={() => handleEyeClickConfirmPassword()}>
          <Icon
            svg={hideConfirmPassword ? Eye : EyeSlash}
            color={COLORS.BLACK}
            size={20}
          />
        </EyeWrapper>
        <PasswordErrorMessage>{passwordInputErrorMessage}</PasswordErrorMessage>
      </PasswordInputWrapper>
      <ButtonContainer>
        <Button
          primary
          width={isPhone ? '100%' : '580rem'}
          height={'55rem'}
          disabled={!newPassword.length || !confirmPassword.length}
          label={t('Submit')}
          onClick={() => submitResetPassword()}
        />
      </ButtonContainer>
    </AuthWrapper>
  );
};

export default ResetPasswordPage;
