import { CSSProperties, FC } from 'react';
import { RippleEffectIconButton, Wrapper } from './Icon.styled';

interface IconProps {
  svg: any;
  size?: number;
  weight?: string;
  color?: string;
  stroke?: string;
  fill?: string;
  wrapperStyle?: CSSProperties;
  className?: string;
  marginLeft?: string;
  marginRight?: string;
  marginBottom?: string;
  marginTop?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingBottom?: string;
  paddingTop?: string;
  onClick?: (e: any) => void;
  onMouseDown?: (e: any) => void;
  pointer?: boolean;
  rippleSize?: number;
  disabled?: boolean;
  noRipple?: boolean;
  tabIndex?: string;
}

const Icon: FC<IconProps> = ({
  svg: SvgIcon,
  size = 24,
  weight = 'light',
  color,
  stroke,
  fill,
  wrapperStyle,
  className,
  marginLeft,
  marginBottom,
  marginRight,
  marginTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  onClick,
  onMouseDown,
  pointer = true,
  rippleSize = size + 10,
  disabled,
  noRipple = false,
  tabIndex,
}) => {
  const hasRipple = !!onClick && !noRipple;

  const sizeRem = `${size}rem`;

  return (
    <Wrapper
      onClick={(e: any) => !disabled && onClick && onClick(e)}
      onMouseDown={(e: any) => !disabled && onMouseDown && onMouseDown(e)}
      marginLeft={marginLeft}
      marginBottom={marginBottom}
      marginRight={marginRight}
      marginTop={marginTop}
      paddingBottom={paddingBottom}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      paddingTop={paddingTop}
      pointer={pointer}
      wrapperStyle={wrapperStyle}
      className={className}
      hasRipple={hasRipple}
      disabled={disabled}
    >
      {hasRipple ? (
        <>
          <RippleEffectIconButton
            sx={{ width: rippleSize + 'rem', height: rippleSize + 'rem' }}
            disabled={disabled}
            tabIndex={tabIndex}
          >
            <SvgIcon
              weight={weight}
              style={{
                height: sizeRem,
                width: sizeRem,
                fill: fill || color,
                stroke: stroke || color,
                opacity: 0,
              }}
            />
          </RippleEffectIconButton>
          <SvgIcon
            className={'icon-ripple-center'}
            weight={weight}
            style={{
              height: sizeRem,
              width: sizeRem,
              fill: fill || color,
              stroke: stroke || color,
            }}
          />
          <SvgIcon
            className={'icon-pointer-events-none'}
            weight={weight}
            style={{
              height: sizeRem,
              width: sizeRem,
              fill: fill || color,
              stroke: stroke || color,
              opacity: 0,
            }}
          />
        </>
      ) : (
        <SvgIcon
          className={className}
          weight={weight}
          style={{
            height: sizeRem,
            width: sizeRem,
            fill: fill || color,
            stroke: stroke || color,
          }}
        />
      )}
    </Wrapper>
  );
};

export default Icon;
