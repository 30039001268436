import i18n, { FALLBACK_LOCALE } from 'providers/i18n/i18n';
import { useEffect, useRef, useState } from 'react';
import { getSelectedLanguageIcon } from './helpers';
import {
  LanguageImage,
  LanguageLabel,
  LanguageOption,
  LanguagesDropdown,
  Line,
  RelativeContainer,
} from './LanguageSwitcher.styled';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';
import { useDispatch } from 'react-redux';
import { resetConfigureForm } from 'store/Model/actions/model';
import {
  resetManuallyChangedQuestionNames,
  resetTouchedQuestionNames,
  setAreAllFieldsTouched,
} from 'store/Form/actions/form';
import RippleEffect from 'components/RippleEffect/RippleEffect';

const lngs = {
  en: { nativeName: 'English' },
  nl: { nativeName: 'Nederlands' },
  fr: { nativeName: 'Français' },
  de: { nativeName: 'Deutsch' },
  no: { nativeName: 'Norsk' },
  es: { nativeName: 'Español' },
  sv: { nativeName: 'Svenska' },
  sr: { nativeName: 'Srpski' },
  da: { nativeName: 'Dansk' },
};

const LanguageSwitcher = () => {
  const dispatch = useDispatch();
  const [isLanguagePickerShown, setIsLanguagePickerShown] = useState(false);
  const [selectedLanguageIcon, setSelectedLanguageIcon] = useState<string>(
    getSelectedLanguageIcon(i18n.resolvedLanguage || FALLBACK_LOCALE)
  );

  useEffect(() => {
    const handleLanguageChange = (lng: string) => {
      dispatch(resetConfigureForm());
      dispatch(resetTouchedQuestionNames());
      dispatch(resetManuallyChangedQuestionNames());
      dispatch(setAreAllFieldsTouched(false));
      setSelectedLanguageIcon(getSelectedLanguageIcon(lng));
    };
    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  const languageSwitcherWrapperRef = useRef();
  useOutsideAlerter(languageSwitcherWrapperRef, () =>
    setIsLanguagePickerShown(false)
  );

  const handleOnClick = () => {
    setIsLanguagePickerShown(!isLanguagePickerShown);
  };

  return (
    <RelativeContainer ref={languageSwitcherWrapperRef}>
      <RippleEffect size={40} onClick={handleOnClick}>
        <LanguageImage
          alt=""
          src={selectedLanguageIcon}
          onClick={() => setIsLanguagePickerShown(!isLanguagePickerShown)}
        />
      </RippleEffect>
      {isLanguagePickerShown && (
        <LanguagesDropdown>
          {Object.keys(lngs).map((lng, index) => (
            <>
              <LanguageOption
                key={lng}
                onClick={() => i18n.changeLanguage(lng)}
              >
                <LanguageImage
                  alt=""
                  src={getSelectedLanguageIcon(lng)}
                  onClick={() =>
                    setIsLanguagePickerShown(!isLanguagePickerShown)
                  }
                />
                <LanguageLabel
                  isSelectedLanguage={i18n.resolvedLanguage === lng}
                >
                  {lngs[lng].nativeName}
                </LanguageLabel>
              </LanguageOption>
              {index < Object.keys(lngs).length - 1 && <Line />}
            </>
          ))}
        </LanguagesDropdown>
      )}
    </RelativeContainer>
  );
};

export default LanguageSwitcher;
