import {
  SET_BASE64_METADATA,
  SET_INTENT_UUID,
  SET_IS_TEST_CONFIGURATION_MODAL_OPEN,
  SET_TEST_COMPANY_MODEL_CONFIGURATION,
  TRIGGER_SUBMIT_CONFIGURATION,
  SET_SELECTED_EXTRA_PRICE_QUESTION_OPTION,
} from 'store/constants';
import {
  IExtraPriceQuestionOption,
  ITestCompanyModelConfiguration,
} from 'types/Configuration.types';

export const setIntentUUID = (uuid: string) => ({
  type: SET_INTENT_UUID,
  payload: uuid,
});

export const triggerSubmitConfiguration = (trigger: boolean) => ({
  type: TRIGGER_SUBMIT_CONFIGURATION,
  payload: trigger,
});

export const setTestCompanyModelConfiguration = (
  testCompanyModelConfiguration: ITestCompanyModelConfiguration | null
) => ({
  type: SET_TEST_COMPANY_MODEL_CONFIGURATION,
  payload: testCompanyModelConfiguration,
});

export const setBase64Metadata = (base64Metadata: string | null) => ({
  type: SET_BASE64_METADATA,
  payload: base64Metadata,
});

export const setIsTestConfigurationModalOpen = (
  isTestConfigurationModalOpen: boolean
) => ({
  type: SET_IS_TEST_CONFIGURATION_MODAL_OPEN,
  payload: isTestConfigurationModalOpen,
});

export const setSelectedExtraPriceQuestionOption = (
  extraPriceQuestionOption: IExtraPriceQuestionOption | null
) => ({
  type: SET_SELECTED_EXTRA_PRICE_QUESTION_OPTION,
  payload: extraPriceQuestionOption,
});
