import {
  CopySimple,
  DotsThree,
  PencilSimple,
  Trash,
} from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { useTranslation } from 'react-i18next';
import { Container, DropDown, Row, Text } from './MobileActionDropdown.styled';
import Icon from 'components/Icon/Icon';
import { useRef, useState } from 'react';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';

interface IMobileActionDropdownProps {
  onDeleteClick?: (e: any) => void;
  onEditClick?: (e: any) => void;
}

const MobileActionDropdown = ({
  onEditClick,
  onDeleteClick,
}: IMobileActionDropdownProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dropdownRef = useRef();
  useOutsideAlerter(dropdownRef, () => setIsOpen(false));

  return (
    <>
      <Container ref={dropdownRef}>
        <Icon
          svg={DotsThree}
          color={COLORS.GREEN}
          size={30}
          weight="bold"
          onClick={() => setIsOpen(!isOpen)}
        />
        {isOpen && (
          <DropDown $isOpen={isOpen}>
            {onEditClick && (
              <Row onClick={(e: any) => onEditClick(e)}>
                <Icon svg={PencilSimple} color={COLORS.GREEN} size={15} />
                <Text>{t('Edit')}</Text>
              </Row>
            )}
            {onDeleteClick && (
              <Row onClick={(e: any) => onDeleteClick(e)}>
                <Icon svg={Trash} color={COLORS.RED} size={15} />
                <Text>{t('Delete')}</Text>
              </Row>
            )}
          </DropDown>
        )}
      </Container>
    </>
  );
};

export default MobileActionDropdown;
