import { AnyAction } from '@reduxjs/toolkit';
import {
  SET_IS_FORM_VALID,
  SET_ARE_ALL_FIELDS_TOUCHED,
  SET_TOUCHED_QUESTION_NAME,
  RESET_TOUCHED_QUESTION_NAMES,
  SET_MODEL_NOT_FOUND,
  SET_MANUALLY_CHANGED_QUESTION_NAMES,
  RESET_MANUALLY_CHANGED_QUESTION_NAMES,
  SET_INCLUDE_BOM_ITEMS,
  REMOVE_MANUALLY_CHANGED_QUESTION_NAME,
} from '../../constants';

export interface IFormReducerState {
  isFormValid: boolean;
  areAllFieldsTouched: boolean;
  touchedQuestionNames: string[];
  manuallyChangedQuestionNames: string[];
  modelNotFound: boolean;
  includeBomItems: boolean;
}

const INIT_STATE: IFormReducerState = {
  modelNotFound: false,
  isFormValid: false,
  areAllFieldsTouched: false,
  touchedQuestionNames: [],
  manuallyChangedQuestionNames: [],
  includeBomItems: false,
};

const formReducer = (state = INIT_STATE, action: AnyAction) => {
  switch (action.type) {
    case SET_TOUCHED_QUESTION_NAME: {
      if (state.touchedQuestionNames.includes(action.payload)) {
        return { ...state };
      } else {
        const touchedQuestionNames = [
          ...state.touchedQuestionNames,
          action.payload,
        ];
        return {
          ...state,
          touchedQuestionNames: touchedQuestionNames,
        };
      }
    }
    case SET_MANUALLY_CHANGED_QUESTION_NAMES: {
      if (state.manuallyChangedQuestionNames.includes(action.payload)) {
        return { ...state };
      } else {
        const manuallyChangedQuestionNames = [
          ...state.manuallyChangedQuestionNames,
          action.payload,
        ];
        return {
          ...state,
          manuallyChangedQuestionNames: manuallyChangedQuestionNames,
        };
      }
    }
    case REMOVE_MANUALLY_CHANGED_QUESTION_NAME: {
      return {
        ...state,
        manuallyChangedQuestionNames: state.manuallyChangedQuestionNames.filter(
          (name) => name !== action.payload
        ),
      };
    }
    case RESET_MANUALLY_CHANGED_QUESTION_NAMES:
      return {
        ...state,
        manuallyChangedQuestionNames: [],
      };
    case RESET_TOUCHED_QUESTION_NAMES:
      return {
        ...state,
        touchedQuestionNames: [],
      };
    case SET_ARE_ALL_FIELDS_TOUCHED:
      return {
        ...state,
        areAllFieldsTouched: action.payload,
      };
    case SET_IS_FORM_VALID:
      return {
        ...state,
        isFormValid: action.payload,
      };
    case SET_MODEL_NOT_FOUND:
      return {
        ...state,
        modelNotFound: action.payload,
      };
    case SET_INCLUDE_BOM_ITEMS:
      return {
        ...state,
        includeBomItems: action.payload,
      };

    default:
      return state;
  }
};

export default formReducer;
