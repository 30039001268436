import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 20rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 15rem;
`;

export const Title = styled.label`
  padding-left: 30rem;
  padding-right: 30rem;
  font-weight: 400;
  font-size: 22rem;
  color: ${COLORS.BLACK};
  text-align: center;

  ${respondTo.bigPhone`
    font-size: 20rem;
  `};
  margin-bottom: 20rem;
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  gap: 10rem;
`;

export const Label = styled.label`
  color: ${COLORS.BLACK};
  font-size: 16rem;
`;
