import { IRootReducerState } from 'store/store';
import { IQuestion, QuestionType } from 'types/Question.types';
import {
  formatValuesFromOutputs,
  getValuesForMultipleListAndTableQuestions,
  getValuesForSingleListTableColorAndRadioQuestions,
} from './helpers';
import { Dispatch, useEffect, useState } from 'react';
import { AnyAction } from 'redux';
import {
  triggerAddToShoppingCart,
  triggerEditCartItem,
} from 'store/ShoppingCart/actions/shoppingCart';
import { triggerSubmitConfiguration } from 'store/SubmitConfiguration/actions/submitConfiguration';
import { resetConfigureForm } from 'store/Model/actions/model';
import { useMutation } from 'react-query';
import { submitConfiguration } from 'services/ExternalService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import {
  useAddToShoppingCart,
  useEditShoppingCartItem,
} from 'pages/ShoppingCartPage/hooks';
import {
  SET_TRIGGER_SHOW_ADDED_TO_CART_MODAL,
  SET_TRIGGER_SHOW_CART_ITEM_EDITED_MODAL,
} from 'store/constants';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  IMobileMessage,
  MobileMessageTypes,
  postMobileMessage,
} from 'utils/mobile/postMobileMessage';
import { IExtraPriceQuestionOption } from 'types/Configuration.types';
import { useSelector } from 'react-redux';
import { setManuallyChangedQuestionNames } from 'store/Form/actions/form';
import { useNavigateWithQuery } from 'utils/hooks/useNavigateWithQuery';
import { RoutesConfig } from 'navigation/routes';

// Generate values for the form based of question outputs of the query response
// (Or from initial getQuestions response outputs if no query has been made yet)
export const useGetValuesFromQuestionOutputs = (
  cartItemQuestions: IQuestion[] | undefined = undefined
) => {
  const valuesFromOutputs = {};
  const storeQuestions = useSelector(
    (state: IRootReducerState) => state.modelInfo.questions
  );
  const manuallyChangedQuestionNames = useSelector(
    (state: IRootReducerState) => state.formInfo.manuallyChangedQuestionNames
  );
  const questions = cartItemQuestions || storeQuestions;
  for (const question of questions) {
    if (question.hidden) {
      continue;
    }

    const skipSetDefault = manuallyChangedQuestionNames.includes(
      question.initialKey
    );

    switch (question.type) {
      case QuestionType.BOOLEAN:
        if (
          !Array.isArray(question.default) &&
          question.default !== null &&
          question.default !== undefined &&
          !skipSetDefault
        ) {
          // Use default if available
          valuesFromOutputs[question.initialKey] = question.default;
        } else {
          // Use value from outputs
          valuesFromOutputs[question.initialKey] = !!question.outputs.value;
        }
        break;
      case QuestionType.LIST:
        if (question.multiple) {
          valuesFromOutputs[question.initialKey] =
            getValuesForMultipleListAndTableQuestions(question, skipSetDefault);
        } else {
          valuesFromOutputs[question.initialKey] =
            getValuesForSingleListTableColorAndRadioQuestions(
              question,
              skipSetDefault
            );
        }
        break;
      case QuestionType.NUMBER:
        if (question.outputs && question.outputs.value != null) {
          valuesFromOutputs[question.initialKey] = question.outputs.value;
        } else {
          if (skipSetDefault) {
            valuesFromOutputs[question.initialKey] = null;
          } else {
            // If null, handle default value
            valuesFromOutputs[question.initialKey] = Array.isArray(
              question.default
            )
              ? question.default.length
                ? question.default[0]
                : null
              : question.default || null;
          }
        }
        break;
      case QuestionType.TEXT:
        if (
          question.outputs &&
          question.outputs.value !== null &&
          question.outputs.value !== ''
        ) {
          valuesFromOutputs[question.initialKey] = question.outputs.value;
        } else {
          if (skipSetDefault) {
            valuesFromOutputs[question.initialKey] = '';
          } else {
            // If null, handle default value
            valuesFromOutputs[question.initialKey] = Array.isArray(
              question.default
            )
              ? question.default.length
                ? question.default[0]
                : ''
              : question.default || '';
          }
        }
        break;
      case QuestionType.TABLE:
        {
          const isForTable = true;
          if (question.multiple) {
            valuesFromOutputs[question.initialKey] =
              getValuesForMultipleListAndTableQuestions(
                question,
                skipSetDefault,
                isForTable
              );
          } else {
            valuesFromOutputs[question.initialKey] =
              getValuesForSingleListTableColorAndRadioQuestions(
                question,
                skipSetDefault,
                isForTable
              );
          }
        }
        break;
      case QuestionType.RADIO:
        valuesFromOutputs[question.initialKey] =
          getValuesForSingleListTableColorAndRadioQuestions(
            question,
            skipSetDefault
          );
        break;
      case QuestionType.COLOR:
        valuesFromOutputs[question.initialKey] =
          getValuesForSingleListTableColorAndRadioQuestions(
            question,
            skipSetDefault
          );
        break;
    }
  }

  return valuesFromOutputs;
};

export const useListenForAddToCartTrigger = (
  queryPayload: object,
  pricing: any,
  dispatch: Dispatch<AnyAction>
) => {
  const {
    triggerAddToShoppingCart: shouldAddCurrentFormValuesToShoppingCart,
    currentlyEditingShoppingCartLineQuantity: cartLineQuantity,
  } = useSelector((state: IRootReducerState) => state.shoppingCartInfo);
  const company = useSelector(
    (state: IRootReducerState) => state.userInfo.company
  );
  const modelInfo = useSelector((state: IRootReducerState) => state.modelInfo);
  const { mutate: addToCart, isSuccess: addToCartSuccess } =
    useAddToShoppingCart();

  useEffect(() => {
    if (addToCartSuccess) {
      dispatch({ type: SET_TRIGGER_SHOW_ADDED_TO_CART_MODAL, payload: true });
    }
  }, [addToCartSuccess]);

  useEffect(() => {
    if (shouldAddCurrentFormValuesToShoppingCart) {
      addToCart({
        cartLineQuantity,
        modelId: modelInfo.id,
        companyId: company.id,
        lang: modelInfo.lang,
        queryPayload: queryPayload,
        pricing: pricing,
      });
      dispatch(triggerAddToShoppingCart(false));
    }
  }, [shouldAddCurrentFormValuesToShoppingCart]);
};

export const useListenForEditCartItemTrigger = (
  queryPayload: object,
  pricing: any,
  dispatch: Dispatch<AnyAction>
) => {
  const {
    triggerEditCartItem: shouldEditCartItem,
    currentlyEditingShoppingCartLineId: cartLineId,
    currentlyEditingShoppingCartLineQuantity: cartLineQuantity,
  } = useSelector((state: IRootReducerState) => state.shoppingCartInfo);
  const company = useSelector(
    (state: IRootReducerState) => state.userInfo.company
  );
  const modelInfo = useSelector((state: IRootReducerState) => state.modelInfo);
  const { mutate: editCartItem, isSuccess: cartItemEditedSuccess } =
    useEditShoppingCartItem();

  useEffect(() => {
    if (cartItemEditedSuccess) {
      dispatch({
        type: SET_TRIGGER_SHOW_CART_ITEM_EDITED_MODAL,
        payload: true,
      });
    }
  }, [cartItemEditedSuccess]);

  useEffect(() => {
    if (shouldEditCartItem) {
      editCartItem({
        cartLineId,
        cartLineQuantity,
        modelId: modelInfo.id,
        companyId: company.id,
        lang: modelInfo.lang,
        queryPayload: queryPayload,
        pricing,
      });
      dispatch(triggerEditCartItem(false));
    }
  }, [shouldEditCartItem]);
};

export const useGetSortedQuestions = () => {
  const initialQuestions = useSelector(
    (state: IRootReducerState) => state.modelInfo.questions
  );
  const sortedQuestions = [...initialQuestions].sort((a, b) => {
    const orderA = a.order !== undefined ? a.order : Infinity;
    const orderB = b.order !== undefined ? b.order : Infinity;
    return orderA - orderB;
  });
  return sortedQuestions;
};

export interface IConfiguredProductData {
  modelId: number | null;
  versionNumber: number | null;
  pricing: object | null;
  roundPricing: boolean;
  questions: IQuestion[];
  general: object | null;
  valuesFromOutputs: object;
  lang: string;
  queryPayload: object; // Payload that was last sent to query the model before form submission
  extraPriceQuestionName?: string;
  selectedExtraPriceQuestionOption?: IExtraPriceQuestionOption;
}

export const useListenForSubmitConfigurationTrigger = (
  valuesFromOutputs: object,
  dispatch: Dispatch<AnyAction>
) => {
  const isFormValid = useSelector(
    (state: IRootReducerState) => state.formInfo.isFormValid
  );
  const {
    triggerSubmitConfiguration: shouldSubmitConfiguration,
    base64Metadata,
    selectedExtraPriceQuestionOption,
  } = useSelector((state: IRootReducerState) => state.submitConfigurationInfo);
  const modelInfo = useSelector((state: IRootReducerState) => state.modelInfo);
  const isQueryRequestAttempt = useSelector(
    (state: IRootReducerState) => state.modelInfo.queryStatus.attempt
  );
  const { mutate: submitConfiguration, isSuccess: submitConfigurationSuccess } =
    useSubmitConfigurationWithWebhook();

  useEffect(() => {
    if (isFormValid && shouldSubmitConfiguration) {
      const hasExtraPriceQuestionOptionSelected: boolean =
        !!modelInfo.extraPriceQuestionData?.name &&
        !!selectedExtraPriceQuestionOption;
      const configurationData: IConfiguredProductData = {
        modelId: modelInfo.id,
        versionNumber: modelInfo.versionNumber,
        pricing: modelInfo.pricing,
        roundPricing: modelInfo.roundPricing,
        questions: modelInfo.questions,
        general: modelInfo.general,
        valuesFromOutputs: formatValuesFromOutputs(
          valuesFromOutputs,
          modelInfo.questions
        ),
        lang: modelInfo.lang,
        queryPayload: modelInfo.queryPayload,
        ...(hasExtraPriceQuestionOptionSelected && {
          extraPriceQuestionName: modelInfo.extraPriceQuestionData?.name,
        }),
        ...(hasExtraPriceQuestionOptionSelected && {
          selectedExtraPriceQuestionOption: selectedExtraPriceQuestionOption,
        }),
      };
      const dto: ISubmitConfigurationWithWebhookDTO = {
        data: configurationData,
        base64Metadata,
      };
      submitConfiguration(dto);
      dispatch(triggerSubmitConfiguration(false));
    }
  }, [shouldSubmitConfiguration, isQueryRequestAttempt]);

  const isMobileApp = useSelector(
    (state: IRootReducerState) => state.commonInfo.isMobileApp
  );

  useEffect(() => {
    if (submitConfigurationSuccess) {
      if (isMobileApp) {
        const mobileMessage: IMobileMessage = {
          type: MobileMessageTypes.CLOSE_LOGYX,
        };
        postMobileMessage(mobileMessage);
      }
      window.close();
      dispatch(resetConfigureForm());
    }
  }, [submitConfigurationSuccess]);
};

export interface ISubmitConfigurationWithWebhookDTO {
  data: IConfiguredProductData;
  base64Metadata?: string;
}

const useSubmitConfigurationWithWebhook = () => {
  const { t } = useTranslation();
  return useMutation(
    (dto: ISubmitConfigurationWithWebhookDTO) =>
      submitConfiguration(dto.data, dto.base64Metadata),
    {
      onSuccess: () => {
        // Success
      },
      onError: () => {
        toast.error(t('Failed to submit configuration'));
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.SUBMIT_CONFIGURATION_WITH_WEBHOOK,
      retry: 3, // Retry the mutation 3 times on failure
      retryDelay: 2000, // Wait 2000 ms between retries
    }
  );
};

// If value is changed manually, add it to the store
export const useSetManuallyChangeQuestionName = (
  dispatch: Dispatch<AnyAction>
) => {
  return (newValue: any, questionKey: string) => {
    if (newValue !== undefined && newValue !== null) {
      dispatch(setManuallyChangedQuestionNames(questionKey));
    }
  };
};

// includeBomItems triggers a query inside QueryProvider, and once that gets caught in this useEffect, the query request has been triggered because of didValuesChange, and we navigate to BomPage
// hasJustClickedIncludeBomItems will be set to false once the user remounts ConfigureForm (comes back from BomPage), which will result in navigation not triggering again, even if includeBomItems value didnt compute yet as false in ConfigureForm
export const useHandleNavigateToBomPage = () => {
  const { includeBomItems } = useSelector(
    (state: IRootReducerState) => state.formInfo
  );
  const navigate = useNavigateWithQuery();
  const [hasJustClickedIncludeBomItems, setHasJustClickedIncludeBomItems] =
    useState<boolean>(false);
  useEffect(() => {
    if (includeBomItems && hasJustClickedIncludeBomItems) {
      navigate(RoutesConfig.Bom.fullPath);
    }
  }, [includeBomItems]);
  return { setHasJustClickedIncludeBomItems };
};
