import { Check, WarningCircle } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import Tooltip from 'components/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import {
  CheckmarkIconAbsoluteWrapper,
  ExtraPriceQuestionLabel,
  ExtraPriceQuestionRelativeWrapper,
  IconTooltipAbsoluteWrapper,
} from './ExtraPrice.styled';
import { areThereExtraPriceQuestionUnsavedChanges } from './helpers';
import { Dispatch, SetStateAction, useState } from 'react';
import { ExtraPriceQuestionModal } from '../ExtraPriceQuestionModal/ExtraPriceQuestionModal';
import { IExtraPriceQuestionOption } from 'types/Configuration.types';
import { Level } from 'components/Modals/Modal/type';

interface IExtraPriceProps {
  modelName: string;
  initialIsExtraPriceQuestionActive: boolean;
  isExtraPriceQuestionActive: boolean;
  setIsExtraPriceQuestionActive: Dispatch<SetStateAction<boolean>>;
  initialExtraPriceQuestionName: string;
  extraPriceQuestionName: string;
  setExtraPriceQuestionName: Dispatch<SetStateAction<string>>;
  initialExtraPriceQuestionOptions: IExtraPriceQuestionOption[];
  extraPriceQuestionOptions: IExtraPriceQuestionOption[];
  setExtraPriceQuestionOptions: Dispatch<
    SetStateAction<IExtraPriceQuestionOption[]>
  >;
  level?: Level;
  showThereAreUnsavedChanges?: boolean;
}

export const ExtraPrice = ({
  modelName,
  initialIsExtraPriceQuestionActive,
  isExtraPriceQuestionActive,
  setIsExtraPriceQuestionActive,
  initialExtraPriceQuestionName,
  extraPriceQuestionName,
  setExtraPriceQuestionName,
  initialExtraPriceQuestionOptions,
  extraPriceQuestionOptions,
  setExtraPriceQuestionOptions,
  level = 'FIRST',
  showThereAreUnsavedChanges = true,
}: IExtraPriceProps) => {
  const { t } = useTranslation();
  const [isExtraPriceQuestionModalOpen, setIsExtraPriceQuestionModalOpen] =
    useState<boolean>(false);

  return (
    <ExtraPriceQuestionRelativeWrapper>
      <ExtraPriceQuestionLabel
        isActive={isExtraPriceQuestionActive}
        onClick={() => setIsExtraPriceQuestionModalOpen(true)}
      >
        {t('Extra price')}
      </ExtraPriceQuestionLabel>
      {isExtraPriceQuestionActive && (
        <CheckmarkIconAbsoluteWrapper>
          <Tooltip content={t('Extra price question is active')}>
            <Icon svg={Check} color={COLORS.GREEN} />
          </Tooltip>
        </CheckmarkIconAbsoluteWrapper>
      )}
      {showThereAreUnsavedChanges &&
        areThereExtraPriceQuestionUnsavedChanges(
          isExtraPriceQuestionActive,
          extraPriceQuestionName,
          extraPriceQuestionOptions,
          initialIsExtraPriceQuestionActive,
          initialExtraPriceQuestionName,
          initialExtraPriceQuestionOptions
        ) && (
          <IconTooltipAbsoluteWrapper>
            <Tooltip content={t('There are unsaved changes')}>
              <Icon svg={WarningCircle} color={COLORS.YELLOW} />
            </Tooltip>
          </IconTooltipAbsoluteWrapper>
        )}
      <ExtraPriceQuestionModal
        level={level}
        modelName={modelName}
        isOpen={isExtraPriceQuestionModalOpen}
        onClose={() => setIsExtraPriceQuestionModalOpen(false)}
        isExtraPriceQuestionActive={isExtraPriceQuestionActive}
        setIsExtraPriceQuestionActive={setIsExtraPriceQuestionActive}
        extraPriceQuestionName={extraPriceQuestionName}
        setExtraPriceQuestionName={setExtraPriceQuestionName}
        extraPriceQuestionOptions={extraPriceQuestionOptions}
        setExtraPriceQuestionOptions={setExtraPriceQuestionOptions}
      />
    </ExtraPriceQuestionRelativeWrapper>
  );
};
