import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';
import { Checkbox as MUICheckbox } from '@mui/material';

interface StyledCheckboxProps {
  isSelected: boolean;
}

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  position: relative;
  padding-bottom: 10rem;
`;
export const List = styled.div``;
export const OptionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const Option = styled.div`
  display: flex;
  max-width: 50%;
  width: 100%;
  flex-wrap: wrap;
  border: 1px solid ${COLORS.BORDER};
  padding: 10rem;

  ${(props: StyledCheckboxProps) =>
    props.isSelected &&
    css`
      background-color: ${COLORS.BACKGROUND};
    `};

  ${respondTo.bigPhone`
    max-width: 100%;
  `}
`;
export const OptionHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 10rem;
`;
export const OptionHeader = styled.span`
  display: inline-block;
  font-size: 16rem;
  color: ${COLORS.BLACK};
  font-weight: bold;
  min-height: 45rem;
`;
export const OptionValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 10rem;
`;
export const OptionValue = styled.span`
  display: inline-block;
  font-size: 16rem;
  color: ${COLORS.BLACK};
  min-height: 45rem;
`;

export const CheckboxWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10rem;
`;

export const CheckboxLabel = styled.span`
  display: inline-block;
  font-size: 16rem;
  color: ${COLORS.BLACK};
`;

export const Checkbox = styled(MUICheckbox)`
  color: ${COLORS.BORDER};

  &.Mui-checked {
    color: ${COLORS.GREEN};
  }

  &:focus-visible {
    outline: 2rem solid ${COLORS.GREEN};
    outline-offset: 3rem;
    box-shadow: 0 0 0 3rem ${COLORS.GREEN};
  }
`;

export const ButtonsWrapper = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  gap: 30rem;
  justify-content: center;
  background-color: ${COLORS.WHITE};
  padding: 10rem;
  border-top: 1px solid ${COLORS.BORDER};

  ${respondTo.smallPhone`
    gap: 10rem;
  `}
`;
