import { AnyAction } from '@reduxjs/toolkit';
import { queryClient } from 'index';
import {
  LOGOUT,
  SET_USER_INFO,
  SET_COMPANY,
  SET_SUPPLIER,
  SET_UPDATED_USER_INFO,
  SET_IS_LOGGED_IN,
  SET_INTENT,
} from 'store/constants';
import { ICompany } from 'types/Company.types';
import { ISupplier } from 'types/Supplier.types';
import { IUserPermissions } from '../types';
import { IIntent } from 'types/Intent.types';

export interface IUserReducerState {
  id: number | null;
  email: string | null;
  first_name: string | null;
  last_name: string | null;
  company: ICompany | null;
  supplier: ISupplier | null;
  isLoggedIn: boolean;
  is_admin: boolean;
  permissions: IUserPermissions | null;
  intent: IIntent | null;
}

export const INIT_USER_INFO_STATE: IUserReducerState = {
  id: null,
  email: null,
  first_name: null,
  last_name: null,
  company: null,
  supplier: null,
  isLoggedIn: false, // In order to keep user in PublicRoute-land until both supplier and company have been selected (in case no intentUUID is provided)
  is_admin: false,
  permissions: null,
  intent: null,
};

const userReducer = (state = INIT_USER_INFO_STATE, action: AnyAction) => {
  switch (action.type) {
    case SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case SET_USER_INFO:
      return {
        ...state,
        id: action.payload?.id,
        email: action.payload?.email,
        first_name: action.payload?.first_name,
        last_name: action.payload?.last_name,
        is_admin: action.payload?.is_admin,
        permissions: action.payload?.permissions,
      };
    case LOGOUT: {
      queryClient.invalidateQueries();
      return {
        ...INIT_USER_INFO_STATE,
      };
    }
    case SET_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case SET_SUPPLIER:
      return {
        ...state,
        supplier: action.payload,
      };
    case SET_UPDATED_USER_INFO: {
      return {
        ...state,
        ...(action.payload.first_name && {
          first_name: action.payload.first_name,
        }),
        ...(action.payload.last_name && {
          last_name: action.payload.last_name,
        }),
      };
    }
    case SET_INTENT: {
      return {
        ...state,
        intent: action.payload,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
