import { COLORS } from 'assets/styled';
import styled from 'styled-components';
import { css } from 'styled-components';

interface StyledProps {
  isConfirmModalOpen: boolean;
}

export const Container = styled.div`
  width: 100%;
  padding: 20rem;
  background-color: ${COLORS.WHITE};
  ${(props: StyledProps) =>
    props.isConfirmModalOpen &&
    css`
      opacity: 0.5;
    `}
`;

export const Title = styled.h2`
  font-size: 18rem;
  color: ${COLORS.BLACK};
  margin-bottom: 20rem;
  text-align: center;
`;

export const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
  grid-gap: 20rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30rem;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 8rem;

    button {
      width: 100%;
    }
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
`;

export const ErrorMessage = styled.div`
  font-weight: 400;
  font-size: 12rem;
  line-height: 10rem;
  color: ${COLORS.RED_100};
  padding-top: 10rem;
`;

export const BackDrop = styled.div`
  position: fixed;
  inset: 0;
`;

export const checkboxLabelStyling = css`
  align-self: flex-start;
  font-weight: 700;
  font-size: 16rem;
  color: ${COLORS.BLACK};
`;
