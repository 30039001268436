import { COLORS } from 'assets/styled';
import styled, { css } from 'styled-components';
interface StyledProps {
  isDropAreaActive: boolean;
}

export const Container = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
`;
export const NoteWrapper = styled.div`
  border-bottom: 1px solid ${COLORS.BORDER};
  padding-bottom: 20rem;
  margin-bottom: 10rem;
`;
export const Note = styled.span`
  font-size: 16rem;
  color: ${COLORS.BLACK};
`;
export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20rem;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.BORDER};
  padding-bottom: 20rem;

  &:not(:first-of-type) {
    padding-top: 20rem;
  }

  &:last-of-type {
    padding-bottom: 0;
  }
`;

export const DropArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.LIGHT_GREEN};
  border: 1px dashed ${COLORS.BORDER};
  border-radius: 10rem;
  width: 100%;
  max-width: 100%;
  height: 120rem;
  transition: 0.3s ease;

  ${(props: StyledProps) =>
    props.isDropAreaActive &&
    css`
      transform: scale(1.1);
      border-color: ${COLORS.GREEN};
    `}
`;

export const DropText = styled.span`
  display: block;
  font-size: 16rem;
  color: ${COLORS.STRICT_BLACK};
`;
