export interface IColumnSort {
  columnIndex: number | null;
  sortOrder: SortOrder;
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none',
}

export interface ITableConfig {
  rowConfigs: ITableRowConfig[];
}

export interface ITableRowConfig {
  header: string;
  flexValue: number;
  isSortable?: boolean;
  isLocked?: boolean;
}
