import { COLORS, gapLg, paddingMd, paddingSm, paddingXs } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import { hoverLineOut } from 'assets/styled/commonStyles';
import Button from 'components/Button/Button';
import styled, { css } from 'styled-components';

interface StyledProps {
  isFirst: boolean;
  isToggled: boolean;
  withoutLine: boolean;
  isErrorVisible: boolean;
}

export const MODEL_CONFIGURATION_CONTAINER_HEIGHT = 110;

export const Container = styled.div`
  overflow: visible; // So ripple of plus icon is visible
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: ${MODEL_CONFIGURATION_CONTAINER_HEIGHT}rem;
  min-width: 1200rem;
  ${(props: StyledProps) =>
    !props.withoutLine &&
    css`
      border-bottom: 1rem solid ${COLORS.BORDER};
    `};
  ${(props: StyledProps) =>
    !props.isFirst &&
    css`
      padding-top: 5rem;
    `}

  ${respondTo.bigLaptop`
    height: 100%;
    min-width: 100%;
  `};
  zoom: 0.9;
`;

export const RowTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  ${respondTo.smallLaptop`
    flex-direction: column;
    gap: 10rem;
  `}
`;

export const RowBottom = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10rem;

  ${respondTo.desktop`
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 10rem; 
    padding-top: ${paddingXs};
    padding-bottom: ${paddingXs};
  `}

  ${respondTo.bigLaptop`
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 10rem; 
    padding-top: ${paddingXs};
    padding-bottom: ${paddingXs};
  `}

  ${respondTo.smallLaptop`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: ${paddingXs};
    padding-bottom: ${paddingXs};
  `}

  ${respondTo.smallTablet`
    display: flex;
    flex-direction: column;
    gap: 10rem;
    align-items: flex-start;
  `}
`;

export const ModelNameLabel = styled.label`
  font-weight: 400;
  font-size: 18rem;
  ${respondTo.bigTablet`
    font-size: 16rem;
  `}
  color: ${COLORS.GREEN};
  align-self: center;
`;

export const FixedAmountWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10rem;

  ${respondTo.bigTablet`
    text-align: center;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
  `}
`;
export const PriceInputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10rem;

  ${respondTo.bigTablet`
    text-align: center;
    flex-direction: column;
    width: 100%;
  `}
`;
export const RowTopRightPart = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 10rem;
  ${respondTo.smallTablet`
    flex-wrap: wrap;
    justify-content: center;  
    gap: 20rem;
  `};
`;

export const ToggleSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10rem;
`;

export const TooltipWrapper = styled.div`
  margin-left: 5rem;
  z-index: 2;
  align-self: center;
`;

export const ModelNameTooltipWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;

  ${respondTo.desktop`
    justify-content: flex-start;
  `}

  ${respondTo.bigTablet`
    align-self: center;
    justify-content: center;
  `}
`;

export const CheckboxLabel = styled.span`
  display: inline-block;
  font-size: 16rem;
  color: ${COLORS.BLACK};
`;

export const ToggleSwitchLabel = styled.label`
  font-weight: 400;
  font-size: 16rem;
  line-height: 22rem;
  color: ${COLORS.GREEN_DISABLED};
  ${(props: StyledProps) =>
    props.isToggled &&
    css`
      color: ${COLORS.GREEN};
    `};
`;

export const Label = styled.label`
  font-weight: 400;
  font-size: 16rem;
  line-height: 22rem;
  color: ${COLORS.BLACK};
`;

export const ErrorOverlay = styled.div`
  position: absolute;
  top: -25rem;
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  padding: 5rem;
  border-radius: 5rem;
  font-size: 16rem;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  ${(props: StyledProps) =>
    props.isErrorVisible &&
    css`
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
    `};
  left: 0;
`;

export const ManageQuestionVisibilityLabel = styled.label`
  text-align: center;
  font-size: 16rem;
  font-weight: 300;
  cursor: pointer;
  color: ${COLORS.GREEN};
  ${hoverLineOut}
  ${respondTo.smallTablet`
    text-align: center;
  `}
`;

export const TestButton = styled(Button)``;

export const ApplyButton = styled(Button)``;

export const IsUsingSupplierSystemDiscountsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10rem;
  ${respondTo.smallTablet`
    align-items: center;
    justify-content: center;
  `}
`;
