import { useRef, useState } from 'react';
import {
  Container,
  Title,
  ButtonWrapper,
  FormWrapper,
  IsDefaultDeleteRow,
  DeleteDiv,
  DeleteLabel,
  InputWrapper,
  InputLabel,
  StyledForm,
  ErrorMessage,
} from './AddEditExtraPriceQuestionOptionModal.styled';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Input } from 'components/Input/InputFormik';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { Modal } from 'components/Modals/Modal/Modal';
import { IExtraPriceQuestionOption } from 'types/Configuration.types';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { addEditExtraPriceQuestionOptionSchema } from './validation';
import Icon from 'components/Icon/Icon';
import { Trash } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { DangerModal } from 'components/Modals/DangerModal/DangerModal';
import { Level, upLevel } from 'components/Modals/Modal/type';

export interface IAddEditExtraPriceQuestionOptionModalProps {
  isOpen: boolean;
  optionForEditing: IExtraPriceQuestionOption | null;
  onClose: () => void;
  onEdit: (option: IExtraPriceQuestionOption) => void;
  onAdd: (option: IExtraPriceQuestionOption) => void;
  onDelete: () => void;
  level: Level;
}

export function AddEditExtraPriceQuestionOptionModal({
  isOpen,
  optionForEditing,
  onClose,
  onEdit,
  onAdd,
  onDelete,
  level,
}: IAddEditExtraPriceQuestionOptionModalProps) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const formikRef = useRef<FormikProps<any> | any>();
  const containerRef = useRef<HTMLDivElement>(null);

  const { isPhone } = useBreakpointFlag();

  const handleSubmit = () => {
    if (!formikRef.current) return;

    const { values } = formikRef.current;
    const option: IExtraPriceQuestionOption = {
      label: values.label,
      value: Number(values.value),
      is_default: values.is_default,
    };
    if (optionForEditing) {
      onEdit(option);
    } else {
      onAdd(option);
    }
    onClose();
  };

  return (
    <Modal
      level={level}
      isOpen={isOpen}
      onClose={() => onClose()}
      modalStyle={{
        margin: 'auto',
        width: isPhone ? '90%' : '500rem',
        minWidth: isPhone ? '90%' : 'auto',
        position: 'fixed',
      }}
    >
      <Container ref={containerRef}>
        <Title>{optionForEditing ? t('Edit option') : t('Add option')}</Title>
        <Formik
          innerRef={formikRef}
          initialValues={{
            label: optionForEditing?.label || '',
            value: optionForEditing?.value || '',
            is_default: optionForEditing?.is_default || false,
          }}
          validationSchema={addEditExtraPriceQuestionOptionSchema}
          validateOnChange={true}
          validateOnMount={true}
          validateOnBlur={true}
          onSubmit={() => {
            //
          }}
        >
          {({ values, errors, touched, setFieldTouched, setFieldValue }) => {
            const labelError = touched['label'] ? errors['label'] : '';
            const valueError = touched['value'] ? errors['value'] : '';
            return (
              <StyledForm>
                <FormWrapper>
                  <InputWrapper>
                    <InputLabel>{t('Label')}</InputLabel>
                    <Input
                      name="label"
                      placeholder={t('Label')}
                      height={'40rem'}
                      wrapperStyles={{ width: '100%' }}
                      onFocus={() => {
                        setFieldTouched('label', true);
                      }}
                    />
                    <ErrorMessage>{labelError}</ErrorMessage>
                  </InputWrapper>
                  <InputWrapper>
                    <InputLabel>{t('Amount')}</InputLabel>
                    <Input
                      name="value"
                      placeholder={t('Amount')}
                      height={'40rem'}
                      wrapperStyles={{ width: '100%' }}
                      onFocus={() => {
                        setFieldTouched('value', true);
                      }}
                      type="number"
                    />
                    <ErrorMessage>{valueError}</ErrorMessage>
                  </InputWrapper>

                  <IsDefaultDeleteRow>
                    <Checkbox
                      label={t('Set as default')}
                      isChecked={values.is_default}
                      onChange={() =>
                        setFieldValue('is_default', !values.is_default)
                      }
                    />
                    {optionForEditing && (
                      <DeleteDiv onClick={() => setIsDeleteModalOpen(true)}>
                        <Icon
                          svg={Trash}
                          size={25}
                          color={COLORS.RED}
                          onClick={() => null}
                        />
                        <DeleteLabel>{t('Delete')}</DeleteLabel>
                      </DeleteDiv>
                    )}
                  </IsDefaultDeleteRow>
                </FormWrapper>
                <ButtonWrapper>
                  <Button
                    secondary
                    label={t('Cancel')}
                    width={isPhone ? '100rem' : '200rem'}
                    onClick={() => onClose()}
                  />
                  <Button
                    primary
                    disabled={!!labelError || !!valueError}
                    label={t('Confirm')}
                    width={isPhone ? '100rem' : '200rem'}
                    onClick={handleSubmit}
                  />
                </ButtonWrapper>
              </StyledForm>
            );
          }}
        </Formik>
      </Container>
      <DangerModal
        level={upLevel(level)}
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        title={t('Delete option')}
        submit={{
          onClick: () => {
            optionForEditing && onDelete();
            setIsDeleteModalOpen(false);
          },
          text: t('Confirm'),
          disabled: false,
        }}
        cancel={{
          onClick: () => {
            setIsDeleteModalOpen(false);
          },
        }}
        description={t(
          'Are you sure you want to delete this extra price option?'
        )}
      />
    </Modal>
  );
}
