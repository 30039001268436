import {
  RememberMeRow,
  RememberMeLabel,
  RememberMeForgotPasswordWrapper,
  ForgotPasswordLabel,
  EyeWrapper,
  PasswordInputWrapper,
  ButtonContainer,
  EmailInputWrapper,
  Form,
} from './LoginPage.styled';
import { useEffect, useState } from 'react';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { useDispatch } from 'react-redux';
import { useLogin } from './hooks';
import LocalStorageService from 'services/LocalStorageService';
import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_REFRESH_TOKEN,
} from 'services/api/constants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthWrapper from '../AuthWrapper/AuthWrapper';
import { RoutesConfig } from 'navigation/routes';
import { usePageTitle } from 'utils/hooks/usePageTitle';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { Eye, EyeSlash } from '@phosphor-icons/react';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { decodeTokenAndSetUserData } from '../CompanySelectPage/helpers';

const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [hidePassword, setHidePassword] = useState<boolean>(true);
  const [rememberMe, setRememberMe] = useState<boolean>(true);

  const { isPhone } = useBreakpointFlag();

  usePageTitle('Login');

  const handleEyeClick = () => {
    setHidePassword(!hidePassword);
  };

  const handleCheckboxClick = () => {
    if (!isLoading) {
      setRememberMe(!rememberMe);
    }
  };

  const { data, mutate: login, isLoading } = useLogin();

  useEffect(() => {
    if (data) {
      decodeTokenAndSetUserData(data.access_token, dispatch);
      LocalStorageService.setItem(
        LOCAL_STORAGE_ACCESS_TOKEN,
        data.access_token
      );
      if (rememberMe) {
        LocalStorageService.setItem(
          LOCAL_STORAGE_REFRESH_TOKEN,
          data.refresh_token
        );
      }
      // Prevent using cached suppliers/companies when navigation to "Select" screens
      queryClient.resetQueries(ReactQueryKeys.GET_SUPPLIERS_INFINITE);
      queryClient.resetQueries(ReactQueryKeys.GET_COMPANIES_INFINITE);
      navigate(RoutesConfig.SupplierSelect.fullPath);
    }
  }, [data, dispatch]);

  const handleEnterKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      login({ email, password });
    }
  };

  return (
    <AuthWrapper title={t('Log in')}>
      <Form>
        <EmailInputWrapper>
          <Input
            height={'55rem'}
            placeholder={t('E-mail')}
            value={email}
            onChange={(e: any) => setEmail(e.target.value)}
            wrapperStyles={{ width: '100%' }}
            width={'100%'}
            autoComplete="email"
          />
        </EmailInputWrapper>
        <PasswordInputWrapper>
          <Input
            height={'55rem'}
            placeholder={t('Password')}
            type={hidePassword ? 'password' : ''}
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
            wrapperStyles={{ width: '100%' }}
            width={'100%'}
            onKeyDown={handleEnterKeyPress}
            autoComplete="current-password"
          />
          <EyeWrapper onClick={() => handleEyeClick()}>
            <Icon
              svg={hidePassword ? Eye : EyeSlash}
              color={COLORS.BLACK}
              size={20}
            />
          </EyeWrapper>
        </PasswordInputWrapper>
        <RememberMeForgotPasswordWrapper>
          <RememberMeRow>
            <ToggleSwitch
              isChecked={rememberMe}
              onClick={() => handleCheckboxClick()}
            />
            <RememberMeLabel onClick={() => handleCheckboxClick()}>
              {t('Remember me')}
            </RememberMeLabel>
          </RememberMeRow>
          <ForgotPasswordLabel
            onClick={() =>
              navigate(RoutesConfig.RequestPasswordResetPage.fullPath)
            }
          >
            {t('Forgot your password?')}
          </ForgotPasswordLabel>
        </RememberMeForgotPasswordWrapper>
        <ButtonContainer>
          <Button
            label={t('Continue')}
            primary
            disabled={isLoading}
            width={isPhone ? '100%' : '580rem'}
            height={'55rem'}
            onClick={() => login({ email, password })}
          />
        </ButtonContainer>
      </Form>
    </AuthWrapper>
  );
};

export default LoginPage;
