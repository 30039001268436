import { useRef } from 'react';
import {
  Container,
  ModelsContainer,
  NoContentLabel,
  NoContentLabelWrapper,
} from './AllModelsGridView.styled';
import { MODELS_PER_PAGE } from '../constants';
import ModelCard from '../../../components/ModelCard/ModelCard';
import { IModel } from 'types/Model.types';
import { useGetModelsInfinite } from './hooks';
import { useLoadNextPage } from 'utils/hooks/useLoadNextPage';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import ModelCardSkeleton from 'components/ModelCard/ModelCardSkeleton/ModelCardSkeleton';
import { useTranslation } from 'react-i18next';
import { getDataFromPages } from 'utils/getDataFromPages';

interface IAllModelsListViewProps {
  debouncedSearchBy: string;
  selectedCategoryId: number | null;
  supplierId?: number; // Used in my models page
}

const AllModelsGridView = ({
  debouncedSearchBy,
  selectedCategoryId,
  supplierId,
}: IAllModelsListViewProps) => {
  const { t } = useTranslation();
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const {
    data: modelsData,
    isLoading: isLoadingModels,
    isFetching: isFetchingModels,
    fetchNextPage,
    hasNextPage,
  } = useGetModelsInfinite(
    MODELS_PER_PAGE,
    debouncedSearchBy,
    supplierId || null,
    selectedCategoryId
  );

  const models: IModel[] = getDataFromPages(modelsData?.pages, 'models');

  useLoadNextPage(loadMoreRef, hasNextPage, fetchNextPage, isFetchingModels);

  return (
    <Container>
      <ModelsContainer>
        {models.map((model: IModel, index: number) => (
          <ModelCard
            model={model}
            key={index}
            onToggleFavoriteSuccess={() => {
              queryClient.invalidateQueries([ReactQueryKeys.GET_MODELS]);
              queryClient.invalidateQueries([
                ReactQueryKeys.GET_MODELS_INFINITE,
              ]);
            }}
          />
        ))}
        {isFetchingModels
          ? Array.from({ length: 5 }, (_, index) => (
              <ModelCardSkeleton key={index} index={index} />
            ))
          : null}
      </ModelsContainer>
      {!models.length && !isLoadingModels ? (
        <NoContentLabelWrapper>
          <NoContentLabel>{t('No models found')}</NoContentLabel>
        </NoContentLabelWrapper>
      ) : null}

      <div ref={loadMoreRef} style={{ height: '1px' }} />
    </Container>
  );
};

export default AllModelsGridView;
