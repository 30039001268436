import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  position: sticky;
  top: 100rem;
  height: fit-content;
`;

export const Headline = styled.span`
  display: block;
  font-size: 26rem;
  color: ${COLORS.BLACK};

  ${respondTo.horizontalTablet`
    font-size: 23rem;
  `}
`;

export const FieldTypeText = styled.span`
  display: block;
  font-size: 18rem;
  color: ${COLORS.BLACK};
  padding-bottom: 20rem;
  border-bottom: 1px solid ${COLORS.BORDER};
`;

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15rem;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const FormContainer = styled.div<{ animationKey: number }>`
  opacity: 0;
  transform: translateY(-20px);
  animation: ${fadeIn} 0.3s ease forwards;
  transition: opacity 0.3s ease, transform 0.3s ease;
`;
