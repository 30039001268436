import { Switch } from '@mui/material';
import { COLORS } from 'assets/styled';
import { useSetManuallyChangeQuestionName } from 'pages/ConfigurePage/ConfigureForm/hooks';
import { useDispatch } from 'react-redux';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface IToggleProps {
  isChecked: boolean;
  onClick: () => void;
  pwId: string;
  questionKey: string;
}

const Toggle = ({ isChecked, onClick, pwId, questionKey }: IToggleProps) => {
  const dispatch = useDispatch();
  const { isPhone } = useBreakpointFlag();
  const setManuallyChangeQuestionName =
    useSetManuallyChangeQuestionName(dispatch);

  return (
    <Switch
      checked={isChecked}
      onChange={() => {
        onClick();
        setManuallyChangeQuestionName(!isChecked, questionKey);
      }}
      data-testid={pwId}
      size={isPhone ? 'small' : 'medium'}
      sx={{
        '& .MuiSwitch-switchBase.Mui-checked': {
          color: COLORS.GREEN,
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: COLORS.GREEN,
        },
        '& .MuiSwitch-switchBase': {
          color: COLORS.GREEN_DISABLED,
        },
      }}
    />
  );
};

export default Toggle;
