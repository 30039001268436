import { useTranslation } from 'react-i18next';
import { ExtraPriceQuestionOption } from './ExtraPriceQuestionOption/ExtraPriceQuestionOption';
import { ExtraPriceQuestionOptionSelectWrapper } from './ExtraPriceQuestionSelect.styled';
import { Select } from 'components/Select/Select';
import { AddEditExtraPriceQuestionOptionModal } from './AddEditExtraPriceQuestionOptionModal/AddEditExtraPriceQuestionOptionModal';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { IExtraPriceQuestionOption } from 'types/Configuration.types';
import CustomMenuList from 'components/Select/components/CustomMenuList/CustomMenuList';
import {
  onEdit,
  onAdd,
  onDefaultChange,
  generateOptionFromExtraPriceQuestionOption,
  onDelete,
} from './helpers';
import { IOption } from 'components/Select/type';
import { Level } from 'components/Modals/Modal/type';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface IExtraPriceQuestionSelectProps {
  options: IExtraPriceQuestionOption[];
  setExtraPriceQuestionOptions: Dispatch<
    SetStateAction<IExtraPriceQuestionOption[]>
  >;
  placeholder: string;
  level: Level;
}

export const ExtraPriceQuestionSelect = ({
  options,
  setExtraPriceQuestionOptions,
  placeholder,
  level,
}: IExtraPriceQuestionSelectProps) => {
  const { t } = useTranslation();
  const scrollPositionRef = useRef(0);
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState<boolean>(false);
  const [optionForEditing, setOptionForEditing] =
    useState<IExtraPriceQuestionOption | null>(null);
  const [editingIndex, setEditingIndex] = useState<number>(-1);

  const CustomOption = (props: any) => (
    <ExtraPriceQuestionOption
      {...props}
      onEdit={() => {
        setEditingIndex(props.data.index);
        setIsAddEditModalOpen(true);
        setOptionForEditing(props.data);
      }}
      onDefaultChange={() =>
        onDefaultChange(props.data.index, setExtraPriceQuestionOptions)
      }
    />
  );

  const handleClose = () => {
    setIsAddEditModalOpen(false);
    setOptionForEditing(null);
    setEditingIndex(-1);
  };

  // Used to display the new default value after the menu has been closed
  const [selectKey, setSelectKey] = useState<string>(JSON.stringify(options));
  useEffect(() => {
    // Update the defaultValue of the select when a new option is added, and it is set as the default option
    if (JSON.parse(selectKey).length !== options.length) {
      setSelectKey(JSON.stringify(options));
    }
  }, [options]);

  const { isSmallPhone } = useBreakpointFlag();

  return (
    <ExtraPriceQuestionOptionSelectWrapper>
      <Select
        key={selectKey}
        onMenuClose={() => setSelectKey(JSON.stringify(options))}
        {...(isAddEditModalOpen && { menuIsOpen: false })}
        name="extraPriceQuestionOption"
        options={options.map(
          (extraPriceOption: IExtraPriceQuestionOption, index: number) => {
            const option: IOption = generateOptionFromExtraPriceQuestionOption(
              extraPriceOption,
              index
            );
            return option;
          }
        )}
        placeholder={placeholder}
        defaultValue={(() => {
          const defaultIndex = options.findIndex((option) => option.is_default);
          if (defaultIndex !== -1) {
            return generateOptionFromExtraPriceQuestionOption(
              options[defaultIndex],
              defaultIndex
            );
          }
          return undefined;
        })()}
        onChange={() => null}
        components={{
          Option: CustomOption,
          MenuList: (props) => (
            <CustomMenuList
              {...props}
              scrollPositionRef={scrollPositionRef}
              label={t('Add more')}
              onClick={() => setIsAddEditModalOpen(true)}
              onMenuScrollToBottom={() => null}
            />
          ),
        }}
        styles={{ menuPortal: { width: isSmallPhone ? '250rem' : '300rem' } }}
        width="100%"
        menuPlacement="top"
      />
      <AddEditExtraPriceQuestionOptionModal
        level={level}
        isOpen={isAddEditModalOpen}
        optionForEditing={optionForEditing}
        onClose={handleClose}
        onEdit={(option) => {
          onEdit(editingIndex, option, setExtraPriceQuestionOptions);
          handleClose();
        }}
        onDelete={() => {
          onDelete(editingIndex, setExtraPriceQuestionOptions);
          handleClose();
        }}
        onAdd={(option) => {
          onAdd(option, setExtraPriceQuestionOptions);
          handleClose();
        }}
      />
    </ExtraPriceQuestionOptionSelectWrapper>
  );
};
