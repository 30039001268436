import QuestionSkeleton from './QuestionSkeleton/QuestionSkeleton';

const QuestionSkeletons = () => {
  return (
    <>
      <QuestionSkeleton index={1} />
      <QuestionSkeleton index={2} />
      <QuestionSkeleton index={3} />
      <QuestionSkeleton index={4} />
      <QuestionSkeleton index={5} />
      <QuestionSkeleton index={6} showBorder={false} />
    </>
  );
};

export default QuestionSkeletons;
