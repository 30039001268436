import { TableModal } from 'components/Modals/TableModal/TableModal';
import { FieldArray, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { IQuestion } from 'types/Question.types';
import {
  ClearAllFieldsLabel,
  Container,
  FormContainer,
  ModelNotFoundLabel,
  LabelsWrapper,
  ViewBomItemsLabel,
} from './ConfigureForm.styled';
import QueryProvider from '../../../providers/QueryProvider/QueryProvider';
import {
  useGetSortedQuestions,
  useGetValuesFromQuestionOutputs,
  useHandleNavigateToBomPage,
  useListenForSubmitConfigurationTrigger,
} from './hooks';
import OverlayProvider from 'providers/OverlayProvider/OverlayProvider';
import { resetConfigureForm } from 'store/Model/actions/model';
import {
  resetManuallyChangedQuestionNames,
  resetTouchedQuestionNames,
  setAreAllFieldsTouched,
  setIncludeBomItems,
  setIsFormValid,
} from 'store/Form/actions/form';
import { extractQuestionPrice, questionHasValue } from './helpers';
import { useTranslation } from 'react-i18next';
import QuestionSkeletons from './QuestionSkeletons/QuestionSkeletons';
import { ColorModal } from 'components/Modals/ColorModal/ColorModal';
import { AppMode } from 'store/Common/reducers/common';
import { QuestionRow } from './QuestionRow/QuestionRow';
import { ExtraPriceQuestionRow } from './ExtraPriceQuestionRow/ExtraPriceQuestionRow';
import { setSelectedExtraPriceQuestionOption } from 'store/SubmitConfiguration/actions/submitConfiguration';

interface IConfigureFormProps {
  hasImages: boolean;
}

const ConfigureForm = ({ hasImages }: IConfigureFormProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const valuesFromOutputs = useGetValuesFromQuestionOutputs();
  const questions: IQuestion[] = useGetSortedQuestions();

  const { queryStatus, pricing, extraPriceQuestionData } = useSelector(
    (state: IRootReducerState) => state.modelInfo
  );
  const { isFormValid, modelNotFound } = useSelector(
    (state: IRootReducerState) => state.formInfo
  );

  const appMode: AppMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );

  useListenForSubmitConfigurationTrigger(valuesFromOutputs, dispatch);

  const { setHasJustClickedIncludeBomItems } = useHandleNavigateToBomPage();

  return (
    <Container data-testid={'form-container'} hasImages={hasImages}>
      <OverlayProvider>
        <Formik
          enableReinitialize
          initialValues={valuesFromOutputs}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={() => {
            return;
          }}
          validateOnMount={true}
        >
          {({ isValid, values, setFormikState }) => {
            if (
              queryStatus.success &&
              JSON.stringify(values) !== JSON.stringify(valuesFromOutputs)
            ) {
              // enableReinitialize doesnt trigger every time when the formik context values and the values from outputs missmatch
              // These objects can missmatch after the query request is successful and new form data is calculated from the response
              // This will update the formik values to match the query response data and rerender the form
              setFormikState((state) => ({
                ...state,
                values: valuesFromOutputs,
              }));
            }

            if (isFormValid !== isValid) {
              dispatch(setIsFormValid(isValid));
            }

            let nonHiddenQuestionIndex = extraPriceQuestionData ? -1 : 0;
            return (
              <Form>
                <LabelsWrapper>
                  <ViewBomItemsLabel
                    onClick={() => {
                      dispatch(setIncludeBomItems(true));
                      setHasJustClickedIncludeBomItems(true);
                    }}
                  >
                    {t('View BOM items')}
                  </ViewBomItemsLabel>

                  <ClearAllFieldsLabel
                    onClick={() => {
                      dispatch(resetConfigureForm());
                      dispatch(resetTouchedQuestionNames());
                      dispatch(resetManuallyChangedQuestionNames());
                      dispatch(setAreAllFieldsTouched(false));
                      dispatch(setSelectedExtraPriceQuestionOption(null));
                    }}
                  >
                    {t('Clear all fields')}
                  </ClearAllFieldsLabel>
                </LabelsWrapper>
                {modelNotFound && (
                  <ModelNotFoundLabel>
                    {t('Model not found')}
                  </ModelNotFoundLabel>
                )}
                <FieldArray
                  name="questions"
                  render={() => (
                    <QueryProvider>
                      <FormContainer>
                        {questions.length ? (
                          <>
                            {questions?.map(
                              (question: IQuestion, index: number) => {
                                if (!question.hidden) {
                                  nonHiddenQuestionIndex++;
                                }
                                const showBorder =
                                  nonHiddenQuestionIndex <=
                                  questions.filter(
                                    (question: IQuestion) => !question.hidden
                                  ).length -
                                    1;

                                const questionPrice = extractQuestionPrice(
                                  pricing,
                                  question.initialKey,
                                  appMode !== AppMode.BLUEPRINT
                                );
                                const hasValue = questionHasValue(
                                  values,
                                  question
                                );
                                return (
                                  <QuestionRow
                                    key={`${question.initialKey}-${index}`}
                                    showBorder={showBorder}
                                    question={question}
                                    hasValue={hasValue}
                                    hasImages={hasImages}
                                    questionPrice={questionPrice}
                                  />
                                );
                              }
                            )}
                            {extraPriceQuestionData ? (
                              <ExtraPriceQuestionRow
                                key={'extra-price-question-row'}
                                extraPriceQuestionData={extraPriceQuestionData}
                              />
                            ) : null}
                          </>
                        ) : (
                          <QuestionSkeletons />
                        )}
                      </FormContainer>
                      <TableModal />
                      <ColorModal />
                    </QueryProvider>
                  )}
                />
              </Form>
            );
          }}
        </Formik>
      </OverlayProvider>
    </Container>
  );
};

export default ConfigureForm;
