import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  isOff: boolean;
  optionPriceColor?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15rem 0 10rem;
  border-bottom: 1px solid ${COLORS.BORDER};
  position: relative;
`;

export const RowName = styled.label`
  font-size: 18rem;
  color: ${COLORS.BLACK};
  font-weight: 400;
  margin-bottom: 20rem;
  display: flex;
  align-items: flex-start;
  gap: 2rem;
`;

export const FieldName = styled.label`
  font-size: 16rem;
  color: ${COLORS.BLACK};
  font-weight: 400;
  margin-bottom: 10rem;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  justify-content: flex-start;
  padding-bottom: 15rem;
  gap: 20rem;

  ${respondTo.horizontalTablet`
    gap: 10rem;
  `}
`;

export const ToggleText = styled.span`
  font-size: 18rem;
  font-weight: 400;
  color: ${COLORS.BLACK};
  transition: 0.3s ease;

  ${(props: StyledProps) =>
    !props.isOff &&
    css`
      color: ${COLORS.BORDER};
    `}

  ${respondTo.horizontalTablet`
    font-size: 16rem;
  `}
`;

export const ErrorWrapper = styled.div`
  display: flex;
  min-height: 15rem;
  padding: 3rem 10rem 0;
`;

export const ErrorMessage = styled.span`
  display: block;
  font-weight: 400;
  line-height: 1;
  font-size: 12rem;
  color: ${COLORS.RED_100};
`;

export const AddOption = styled.span`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  color: ${COLORS.BLACK};
  font-size: 16rem;
  cursor: pointer;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10rem;
  width: 100%;
`;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
`;

export const FieldWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const PriceLabel = styled.label`
  display: block;
  font-size: 14rem;
  color: ${COLORS.BORDER};
  padding-left: 10rem;

  ${(props: StyledProps) =>
    props.optionPriceColor &&
    css`
      color: ${props.optionPriceColor};
    `}
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10rem;
  position: absolute;
  top: 50%;
  right: 10rem;
  transform: translateY(-50%);
  background-color: ${COLORS.WHITE};
`;

export const ChangePriceModal = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 300rem;
  padding: 15rem;
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.BORDER};
  border-radius: 10rem;
  transform: translate(calc(-100% - 30rem), -50%);
  z-index: 3;
`;

export const ChangePriceHeadline = styled.span`
  display: block;
  color: ${COLORS.BLACK};
  font-size: 20rem;
`;

export const ChangePriceOptionName = styled.span`
  display: block;
  padding: 15rem 0;
  color: ${COLORS.BLACK};
  font-size: 18rem;
  border-bottom: 1px solid ${COLORS.BORDER};
  max-height: 90rem;
  overflow-y: auto;
`;

export const ChangePriceCloseIcon = styled.div`
  position: absolute;
  top: 10rem;
  right: 10rem;
`;

export const DefaultSelectWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  padding-bottom: 10rem;
`;

export const ClearButton = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${COLORS.RED_100};
  font-size: 16rem;
  cursor: pointer;
`;
