import { IOption } from 'components/Select/type';
import { IExtraPriceQuestionData } from 'types/Configuration.types';
import { IQuestion } from 'types/Question.types';

export const parseQuestionsObjectResponseIntoArray = (questionsObj: object) => {
  return Object.keys(questionsObj).map((questionKey: string) => {
    return {
      ...questionsObj[questionKey],
      name: questionsObj[questionKey].name
        ? questionsObj[questionKey].name
        : questionKey,
      initialKey: questionsObj[questionKey].initialKey
        ? questionsObj[questionKey].initialKey
        : questionKey,
    };
  });
};

export const EXTRA_PRICE_QUESTION_INITIAL_KEY = 'extraPriceQuestion';

export const getInitialValuesFromQuestions = (
  questions: IQuestion[],
  extraPriceQuestionData: IExtraPriceQuestionData | undefined,
  documentVisibilityData: any,
  documentVisibilityOptions: IOption[]
) => {
  const fetchedVisibilityData =
    documentVisibilityData?.data?.document_visibility_options;

  const initialValues = {};
  const allSelectedDefaultValue = documentVisibilityOptions.map(
    (option) => option.value
  );
  questions.forEach((question: IQuestion) => {
    if (fetchedVisibilityData?.[question.initialKey]) {
      initialValues[question.initialKey] =
        fetchedVisibilityData[question.initialKey];
    } else {
      initialValues[question.initialKey] = allSelectedDefaultValue;
    }
  });

  if (extraPriceQuestionData) {
    if (fetchedVisibilityData?.[EXTRA_PRICE_QUESTION_INITIAL_KEY]) {
      initialValues[EXTRA_PRICE_QUESTION_INITIAL_KEY] =
        fetchedVisibilityData[EXTRA_PRICE_QUESTION_INITIAL_KEY];
    } else {
      initialValues[EXTRA_PRICE_QUESTION_INITIAL_KEY] = allSelectedDefaultValue;
    }
  }

  return initialValues;
};
