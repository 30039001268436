// Used for access to specific actions
export enum PermissionCompanyId {
  ADD_USER_TO_COMPANY = 1,
  REMOVE_USER_FROM_COMPANY = 2,
  READ_USERS_COMPANY = 3,
  EDIT_USER_PERMISSION_COMPANY = 4,
  READ_USER_PERMISSION_COMPANY = 5,
  CREATE_ORDER = 6,
  READ_MODELS_INFO_COMPANY = 7,
  READ_SUPPLIER_INFO = 8,
  CREATE_CONNECTOR_COMPANY = 9,
  DELETE_CONNECTOR_COMPANY = 10,
  READ_CONNECTOR_COMPANY = 11,
  CREATE_INTENT = 12,
  DELETE_INTENT = 13,
  READ_COMPANY = 14,
  EDIT_COMPANY_FIELDS = 15,
  EDIT_ADDRESS = 16,
  READ_ADDRESS = 17,
}

// Used for access to specific actions
export enum PermissionSupplierId {
  ADD_USER_TO_SUPPLIER = 1,
  REMOVE_USER_FROM_SUPPLIER = 2,
  READ_USERS_SUPPLIER = 3,
  EDIT_USER_PERMISSION_SUPPLIER = 4,
  READ_USER_PERMISSION_SUPPLIER = 5,
  CREATE_MODEL = 6,
  CREATE_MODEL_VERSION = 7,
  READ_MODELS_INFO_SUPPLIER = 8,
  CREATE_SUPPLIER_COMPANY_MODEL = 9,
  READ_COMPANIES_INFO = 10,
  CREATE_CONNECTOR_SUPPLIER = 11,
  DELETE_CONNECTOR_SUPPLIER = 12,
  READ_CONNECTOR_SUPPLIER = 13,
  READ_SUPPLIER = 14,
  EDIT_SUPPLIER_FIELDS = 15,
  EDIT_MODEL_ORDER = 16,
  EDIT_MODEL_QUERY = 17,
  EDIT_ADDRESS = 18,
  READ_ADDRESS = 19,
  ADD_COMPANY_TO_SUPPLIER = 20,
  REMOVE_COMPANY_FROM_SUPPLIER = 21,
  DELETE_SUPPLIER_COMPANY_MODEL = 22,
  ADD_CUSTOM_DISCOUNT_AND_AMOUNT = 23,
  READ_SUPPLIER_COMPANIES = 24,
}

// Used for blocking navigation in router
export enum PermissionGroupCompanyId {
  WRITE_CART = 1,
  WRITE_COMPANY = 2,
  READ_COMPANY = 3,
  READ_MODEL = 4,
  WRITE_INTENTS = 5,
  WRITE_CONNECTOR_COMPANY = 6,
  READ_CONNECTOR_COMPANY = 7,
  WRITE_PERMISSIONS_COMPANY = 8,
  READ_PERMISSIONS_COMPANY = 9,
  READ_SUPPLIER_INFO = 10,
}

// Used for blocking navigation in router
export enum PermissionGroupSupplierId {
  WRITE_SUPPLIER = 1,
  READ_SUPPLIER = 2,
  WRITE_CONNECTOR_SUPPLIER = 3,
  READ_CONNECTOR_SUPPLIER = 4,
  WRITE_PERMISSIONS_SUPPLIER = 5,
  READ_PERMISSIONS_SUPPLIER = 6,
  WRITE_MODEL = 7,
  READ_MODEL = 8,
  READ_COMPANY_INFO = 9,
  MODEL_EDITOR = 10,
  WRITE_CUSTOM_DISCOUNT_AND_AMOUNT = 11,
}
