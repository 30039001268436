import { COLORS } from 'assets/styled';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  width: 100%;
`;

export const Label = styled.label`
  font-size: 30rem;
  font-weight: 300rem;
  color: ${COLORS.BLACK};
  text-align: center;
`;
