import { COLORS, marginMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';
import { css } from 'styled-components';

interface StyledProps {
  hasImages: boolean;
  opacityIndex: number;
  opacityCoeficient: number;
  disabled?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${(props: StyledProps) =>
    props.opacityIndex !== undefined &&
    css`
      margin-top: 20rem;
      opacity: ${1 - (props.opacityIndex * props.opacityCoeficient + 0.2)};
    `}
`;

export const NameLabel = styled.label`
  display: flex;
  font-weight: 400;
  font-size: 24rem;
  line-height: 32rem;
  padding-right: 10rem;
  color: ${COLORS.BLACK};
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30rem;

  ${respondTo.smallTablet`
    flex-direction: column;
    align-items: flex-start;
    gap: 10rem;
  `}

  ${respondTo.bigPhone`
    align-items: center;
  `}
`;

export const ImagesConfigurationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30rem;

  ${respondTo.smallTablet`
    flex-direction: column;
  `}

  ${respondTo.bigPhone`
    gap: 10rem;
  `}
`;

export const GridPriceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Grid = styled.div`
  display: grid;
  grid-row-gap: 20rem;
  grid-column-gap: 50rem;
  ${(props: StyledProps) =>
    props.hasImages
      ? css`
          grid-template-columns: repeat(3, minmax(240rem, auto));
        `
      : css`
          grid-template-columns: repeat(4, minmax(240rem, auto));
        `}

  ${respondTo.smallLaptop`
    gap: 20rem;
  `}

    @media screen and (max-width: 1150px) {
    gap: 20rem 40rem;
    ${(props: StyledProps) =>
      props.hasImages
        ? css`
            grid-template-columns: repeat(2, minmax(220rem, auto));
          `
        : css`
            grid-template-columns: repeat(3, minmax(220rem, auto));
          `}
  }

  ${respondTo.midTablet`
    gap: 20rem;
    ${(props: StyledProps) =>
      props.hasImages
        ? css`
            grid-template-columns: repeat(2, minmax(200rem, auto));
          `
        : css`
            grid-template-columns: repeat(3, minmax(200rem, auto));
          `}
  `}

  ${respondTo.smallTablet`
    ${(props: StyledProps) =>
      props.hasImages
        ? css`
            grid-template-columns: repeat(2, minmax(200rem, auto));
          `
        : css`
            grid-template-columns: repeat(2, minmax(200rem, auto));
          `}
  `}

  ${respondTo.bigPhone`
    ${(props: StyledProps) =>
      props.hasImages
        ? css`
            grid-template-columns: repeat(1, minmax(100%, auto));
          `
        : css`
            grid-template-columns: repeat(1, minmax(100%, auto));
          `}
  `}
`;

export const LabelKey = styled.label`
  font-weight: 600;
  font-size: 14rem;
  line-height: 21rem;
  color: ${COLORS.BLACK};
`;

export const LabelValue = styled.label`
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
  color: ${COLORS.BLACK};
  text-align: right;
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10rem;
`;

export const HorizontalLine = styled.div`
  height: 1rem;
  background: ${COLORS.BORDER};
  margin-bottom: ${marginMd};
  ${(props: StyledProps) =>
    props.hasImages &&
    css`
      margin-top: 10rem;
    `}
`;

export const BottomWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  gap: 30rem;
  align-items: center;
  justify-content: flex-end;
  padding: 20rem 0;

  ${respondTo.smallPhone`
    flex-direction: column;
    gap: 10rem;
    padding: 10rem 0;
  `}
`;

export const QuantityWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;
`;

export const QuantityLabel = styled.span`
  display: block;
  font-size: 16rem;
  color: ${COLORS.BLACK};
`;

export const PriceWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const PriceLabel = styled.label`
  text-align: end;
  font-weight: 400;
  font-size: 24rem;
  line-height: 32rem;
  color: ${COLORS.BLACK};
  min-width: 180rem;

  ${respondTo.bigTablet`
    font-size: 22rem;
    min-width: 140rem;
  `}

  ${respondTo.smallTablet`
    font-size: 20rem;
    min-width: 130rem;
  `}

  ${respondTo.bigPhone`
    font-size: 18rem;
    min-width: 115rem;
  `}
`;

export const PriceValueLabel = styled.label`
  font-weight: 600;
  font-size: 30rem;
  line-height: 40rem;
  color: ${COLORS.GREEN};

  ${respondTo.smallLaptop`
    font-size: 26rem;
  `}

  ${respondTo.bigTablet`
    font-size: 22rem;
  `}

  ${respondTo.smallTablet`
    font-size: 20rem;
  `}

  ${respondTo.bigPhone`
    font-size: 18rem;
  `}
`;

export const EditLabel = styled.label<StyledProps>`
  font-weight: 400;
  font-size: 18rem;
  line-height: 24rem;
  color: ${COLORS.GREEN};
  cursor: pointer;

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}

  ${(props: StyledProps) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      color: ${COLORS.GRAY_200};
    `}
`;

export const EditIconLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
  justify-content: space-between;
  transition: transform ease-in-out 50ms;
  &:hover {
    ${EditLabel} {
      text-decoration: underline;
    }
  }
`;

export const RemoveLabel = styled.label`
  font-weight: 400;
  font-size: 18rem;
  line-height: 24rem;
  color: ${COLORS.RED};
  cursor: pointer;

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const RemoveIconLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5rem;
  justify-content: space-between;
  transition: transform ease-in-out 50ms;
  &:hover {
    ${RemoveLabel} {
      text-decoration: underline;
    }
  }
`;

export const DuplicateLabel = styled.label`
  font-weight: 400;
  font-size: 18rem;
  line-height: 24rem;
  color: ${COLORS.GREEN};
  cursor: pointer;

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const DuplicateIconLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5rem;
  justify-content: space-between;
  transition: transform ease-in-out 50ms;
  &:hover {
    ${DuplicateLabel} {
      text-decoration: underline;
    }
  }
`;

export const RightPart = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50rem;

  ${respondTo.smallTablet`
    gap: 20rem;
  `}
`;

export const LeftPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
