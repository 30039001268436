import { DatePicker as Calendar } from '@mui/x-date-pickers';
import moment from 'moment';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface IDatePickerProps {
  pwId?: string;
  width?: string;
  date: moment.Moment | null;
  setDate: (date: moment.Moment | null) => void;
  label?: string;
  deliveryDays?: number[];
  maxConnLeadTime?: number;
  disabled?: boolean;
}

const DatePicker = ({
  pwId,
  width,
  date,
  setDate,
  label,
  deliveryDays,
  maxConnLeadTime,
  disabled,
}: IDatePickerProps) => {
  const { isDesktop } = useBreakpointFlag();

  const today = moment().startOf('day');

  const shouldDisableDate = (day: any) => {
    if (
      !deliveryDays?.length ||
      maxConnLeadTime === undefined ||
      maxConnLeadTime === null
    ) {
      return false;
    }

    const selectedDate = moment(day).startOf('day');

    // Disable date before today + maxConnLeadTime
    if (selectedDate.isBefore(today.clone().add(maxConnLeadTime, 'days'))) {
      return true;
    }
    const isoWeekday = selectedDate.isoWeekday(); // 1-7

    // If date is not in deliveryDays -> disable
    if (!deliveryDays.includes(isoWeekday)) {
      return true;
    }

    return false;
  };

  return (
    <Calendar
      data-testid={pwId}
      value={moment(date)}
      disabled={disabled}
      onChange={(newValue) => setDate(newValue)}
      shouldDisableDate={
        deliveryDays &&
        maxConnLeadTime !== undefined &&
        maxConnLeadTime !== null
          ? shouldDisableDate
          : undefined
      }
      sx={{
        width: width ? width : '300rem',
        '& .MuiInputBase-input': {
          height: isDesktop ? '40rem' : '20rem',
          fontSize: isDesktop ? '16px' : '15px',
        },
        '& svg': {
          fontSize: isDesktop ? '25rem' : '20rem',
        },
      }}
      slotProps={{
        actionBar: { actions: ['clear', 'today'] },
        textField: {
          size: 'small',
          label: label || '',
        },
      }}
    />
  );
};
export default DatePicker;
