import { DiscountsType } from './EditDiscountModal';

export const validateDiscountValue = (
  discount: string,
  discountType: DiscountsType,
  t: (key: string) => string
): string | undefined => {
  const value = Number(discount);

  if (discount === '') return undefined;

  if (discountType === DiscountsType.PERCENTAGE) {
    if (value < 0 || value > 100) {
      return t('Percentage must be between 0 and 100');
    }
  }

  if (discountType === DiscountsType.AMOUNT) {
    if (value < 0) {
      return t('Amount must be greater than 0');
    }
  }

  return undefined;
};
