import { DiscountsType } from 'components/Modals/EditDiscountModal/EditDiscountModal';

export const updatePricingWithDiscount = (
  pricing: Record<string, any>,
  key: string,
  discountValue: string | null,
  discountType: DiscountsType
): Record<string, any> => {
  if (!pricing || !pricing[key]) {
    return pricing;
  }

  const parsedDiscount = Number(discountValue);

  return {
    ...pricing,
    [key]: {
      ...pricing[key],
      custom_discount:
        discountType === DiscountsType.PERCENTAGE && !isNaN(parsedDiscount)
          ? parsedDiscount / 100
          : null,
      custom_amount:
        discountType === DiscountsType.AMOUNT && !isNaN(parsedDiscount)
          ? parsedDiscount
          : null,
    },
  };
};
