import {
  ButtonWrapper,
  Container,
  NoContentLabel,
  Row,
  ClearShoppingCartWrapper,
  Title,
  ClearShoppingCartLabel,
  PriceWrapper,
  PriceLabel,
  PriceValueLabel,
  SupplierName,
  Wrapper,
} from './ShoppingCartPage.styled';
import ShoppingCartCard from './ShoppingCartCard/ShoppingCartCard';
import Button from 'components/Button/Button';
import { DangerModal } from 'components/Modals/DangerModal/DangerModal';
import { useState } from 'react';
import {
  useDeleteItemFromShoppingCart,
  useDeleteShoppingCart,
  useGetShoppingCart,
} from './hooks';
import { useTranslation } from 'react-i18next';
import { ICartItem } from 'types/CartItem.types';
import { useDispatch } from 'react-redux';
import {
  setCurrentlyEditingShoppingCartLineData,
  setMaxConnLeadTime,
} from 'store/ShoppingCart/actions/shoppingCart';
import {
  ISupplierLine,
  calculateTotalNetPriceForCart,
  groupLinesBySupplier,
} from './helpers';
import ShoppingCartCardSkeletons from './ShoppingCartCardSkeletons/ShoppingCartCardSkeletons';
import { usePageTitle } from 'utils/hooks/usePageTitle';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'navigation/routes';

const ShoppingCartPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isRemoveItemDangerModalOpen, setIsRemoveItemDangerModalOpen] =
    useState(false);
  const [cartItemIdForRemoval, setCartItemIdForRemoval] = useState<
    number | null
  >(null);

  const { data: cartData, isLoading: getShoppingCartIsLoading } =
    useGetShoppingCart();

  if (cartData) {
    dispatch(setMaxConnLeadTime(cartData.max_conn_lead_time));
  }

  const supplierLines: ISupplierLine[] = groupLinesBySupplier(
    cartData?.lines || []
  );

  const { mutate: deleteShoppingCart, isLoading: deleteShoppingCartIsLoading } =
    useDeleteShoppingCart();

  const {
    mutate: deleteItemFromShoppingCart,
    isLoading: deleteItemFromShoppingCartIsLoading,
  } = useDeleteItemFromShoppingCart();

  usePageTitle('Shopping cart');

  return (
    <Wrapper>
      <Container>
        <Row>
          <Title>{t('Shopping cart')}</Title>
        </Row>
        {cartData?.lines?.length && !getShoppingCartIsLoading ? (
          <ClearShoppingCartWrapper>
            <ClearShoppingCartLabel
              onClick={() => {
                if (!deleteShoppingCartIsLoading) {
                  deleteShoppingCart();
                  dispatch(setCurrentlyEditingShoppingCartLineData(null, null));
                }
              }}
            >
              {t('Clear shopping cart')}
            </ClearShoppingCartLabel>
          </ClearShoppingCartWrapper>
        ) : null}
        {supplierLines.map((supplierLine: ISupplierLine) => {
          return (
            <>
              <SupplierName>{supplierLine.supplier.name}</SupplierName>
              {supplierLine?.lines?.map((item: ICartItem, index: number) => (
                <ShoppingCartCard
                  item={item}
                  key={`cart-item-${index}`}
                  onRemove={() => {
                    setIsRemoveItemDangerModalOpen(true);
                    setCartItemIdForRemoval(item.id);
                  }}
                  isLast={index === supplierLine?.lines.length - 1}
                />
              ))}
            </>
          );
        })}
        {!cartData?.lines?.length ? (
          getShoppingCartIsLoading ? (
            <ShoppingCartCardSkeletons />
          ) : (
            <NoContentLabel>
              {t('Shopping cart is currently empty')}
            </NoContentLabel>
          )
        ) : (
          <>
            <PriceWrapper>
              <PriceLabel>
                {t('Total net price') + ':'}
                <PriceValueLabel>
                  &nbsp;
                  {'€' +
                    calculateTotalNetPriceForCart(
                      cartData?.lines || []
                    ).toFixed(2)}
                </PriceValueLabel>
              </PriceLabel>
            </PriceWrapper>
            <ButtonWrapper>
              <Button
                pwId="cancel-shopping-cart"
                secondary
                label={t('Cancel')}
                width={'200rem'}
                onClick={() => {
                  navigate(-1);
                }}
              />

              <Button
                pwId="next-shopping-cart"
                primary
                label={t('Next')}
                width={'200rem'}
                onClick={() => {
                  navigate(RoutesConfig.Checkout.fullPath);
                }}
              />
            </ButtonWrapper>
          </>
        )}
        <DangerModal
          isOpen={isRemoveItemDangerModalOpen}
          setIsOpen={setIsRemoveItemDangerModalOpen}
          title={t('Confirm removal')}
          submit={{
            onClick: () => {
              if (cartItemIdForRemoval !== null) {
                deleteItemFromShoppingCart(cartItemIdForRemoval);
                setIsRemoveItemDangerModalOpen(false);
              } else {
                alert('cartItemIdForRemoval is null');
              }
            },
            text: t('Remove'),
            disabled: deleteItemFromShoppingCartIsLoading,
          }}
          cancel={{
            onClick: () => {
              setCartItemIdForRemoval(null);
              setIsRemoveItemDangerModalOpen(false);
            },
          }}
          description={
            t('Are you sure you want to remove this Product from the cart') +
            '?'
          }
        />
      </Container>
    </Wrapper>
  );
};

export default ShoppingCartPage;
