import styled from 'styled-components';
import { COLORS } from 'assets/styled';

export const Grid = styled.div`
  position: relative;
  display: grid;
  grid-row-gap: 20rem;
  grid-column-gap: 50rem;
  grid-template-columns: repeat(2, minmax(25%, auto));
`;

export const LabelKey = styled.label`
  font-weight: 600;
  font-size: 14rem;
  line-height: 21rem;
  color: ${COLORS.BLACK};
`;

export const LabelValue = styled.label`
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
  color: ${COLORS.BLACK};
  overflow: hidden;
  white-space: normal;
  word-wrap: break-word;
`;

export const HorizontalLine = styled.div`
  height: 1rem;
  background: ${COLORS.BORDER};
  margin-top: 20rem;
  margin-bottom: 20rem;
`;
