import { IPostCartOrderDTO } from 'pages/CheckoutPage/hooks';
import { cleanPricingQueryData } from 'providers/QueryProvider/helpers';
import apiClient from 'services/api/apiService';

const BASE_URL = '/v1/carts';

export const getShoppingCart = async (): Promise<any> => {
  const url = `${BASE_URL}/default`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

// Cart id is also field that is optional, we use default cart for forms
export const addToShoppingCart = async (
  cartLineQuantity: number,
  model_id: number,
  company_id: number,
  lang: string,
  queryPayload: any,
  pricing: any
): Promise<any> => {
  const url = `${BASE_URL}`;
  const cleanedPricing = cleanPricingQueryData(pricing);
  const { data } = await apiClient.post<any>(url, {
    quantity: cartLineQuantity,
    model_id,
    company_id,
    questions: queryPayload.questions,
    language_code: lang,
    pricing: cleanedPricing,
  });

  return data.data;
};

// Cart id is also field that is optional, we use default cart for forms
export const editShoppingCartItem = async (
  cartLineId: number,
  cartLineQuantity: number,
  modelId: number,
  companyId: number,
  lang: string,
  queryPayload: any,
  pricing: any
): Promise<any> => {
  const url = `${BASE_URL}/default/${cartLineId}`;
  const cleanedPricing = cleanPricingQueryData(pricing);
  const { data } = await apiClient.put<any>(url, {
    quantity: cartLineQuantity,
    model_id: modelId,
    company_id: companyId,
    language_code: lang,
    questions: queryPayload.questions,
    pricing: cleanedPricing,
  });
  return data.data;
};

export const postCartOrder = async (
  postCardOrderDTO: IPostCartOrderDTO
): Promise<any> => {
  const payload = {
    order_reference: postCardOrderDTO.order_reference,
    ...(postCardOrderDTO.delivery_date && {
      requested_delivery_date: postCardOrderDTO.delivery_date,
    }),
    ...(postCardOrderDTO.addressId && {
      shipping_address_id: postCardOrderDTO.addressId,
    }),
  };
  const url = `${BASE_URL}/order`;
  const { data } = await apiClient.post<any>(url, payload);
  return data.data;
};

export const deleteShoppingCart = async (): Promise<any> => {
  const url = `${BASE_URL}/default`;
  const { data } = await apiClient.delete<any>(url, {});
  return data.data;
};

export const deleteShoppingCartItem = async (
  cartLineId: number
): Promise<any> => {
  const url = `${BASE_URL}/default/${cartLineId}`;

  const { data } = await apiClient.delete<any>(url, {});

  return data.data;
};

export const duplicateCartLine = async (cartLineId: number): Promise<any> => {
  const url = `${BASE_URL}/default/${cartLineId}/duplicate`;

  const { data } = await apiClient.post<any>(url);

  return data.data;
};

export const changeQuantityCartLine = async (
  cartLineId: number,
  quantity: number
): Promise<any> => {
  const payload = {
    quantity,
  };
  const url = `/v1/cart-lines/${cartLineId}/quantity`;
  const { data } = await apiClient.patch<any>(url, payload);

  return data.data;
};
