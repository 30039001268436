import { Checkbox } from 'components/Checkbox/Checkbox';
import {
  Container,
  EditLabel,
  EditLabelIconWrapper,
  FirstRow,
  Line,
  OptionNameLabel,
  OptionValueLabel,
  SecondRow,
  checkboxSpanStyles,
  checkboxWrapperStyles,
} from './ExtraPriceQuestionOption.styled';
import { useTranslation } from 'react-i18next';
import { PencilSimple } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import { IOption } from 'components/Select/type';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface IExtraPriceQuestionOptionProps {
  data: IOption;
  onEdit: () => void;
  onDefaultChange: (bool: boolean) => void;
}

export const ExtraPriceQuestionOption = ({
  data,
  onEdit,
  onDefaultChange,
}: IExtraPriceQuestionOptionProps) => {
  const { t } = useTranslation();

  const { isSmallPhone } = useBreakpointFlag();

  return (
    <Container
      key={`${data.is_default}-${data.index}`}
      isSmallPhone={isSmallPhone}
    >
      <FirstRow>
        <OptionNameLabel>{data.label}</OptionNameLabel>
        <EditLabelIconWrapper onClick={onEdit}>
          <EditLabel>{t('Edit')}</EditLabel>
          <Icon
            svg={PencilSimple}
            color={COLORS.GREEN}
            size={16}
            onClick={() => null}
          />
        </EditLabelIconWrapper>
      </FirstRow>
      <SecondRow>
        <OptionValueLabel
          color={Number(data.value) > 0 ? COLORS.GREEN : COLORS.RED}
        >
          {'€' + data.value}
        </OptionValueLabel>
        <Checkbox
          label={t('Set as default')}
          isChecked={data.is_default}
          onChange={(bool: boolean) => onDefaultChange(bool)}
          wrapperStyles={checkboxWrapperStyles(isSmallPhone)}
          labelStyles={checkboxSpanStyles(isSmallPhone)}
        />
      </SecondRow>
      <Line />
    </Container>
  );
};
