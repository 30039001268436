import { CSSProperties } from 'react';
import {
  CheckboxInput,
  CheckboxSpan,
  ClickableContainer,
  Container,
} from './Checkbox.styled';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';

interface ICheckboxProps {
  label: string;
  isChecked: boolean;
  onChange: (param: boolean) => void;
  labelStyles?: CSSProperties;
  wrapperStyles?: CSSProperties;
  isDisabled?: boolean;
}

export const Checkbox = ({
  label,
  onChange,
  isChecked,
  labelStyles,
  wrapperStyles,
  isDisabled,
}: ICheckboxProps) => {
  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );
  return (
    <Container
      wrapperStyles={wrapperStyles}
      onClick={() => onChange(!isChecked)}
    >
      {/* <ClickableContainer > */}
      <CheckboxInput
        darkMode={darkMode}
        onChange={() => null}
        checked={isChecked}
        disabled={isDisabled}
      />
      <CheckboxSpan labelStyles={labelStyles}>{label}</CheckboxSpan>
      {/* </ClickableContainer> */}
    </Container>
  );
};
