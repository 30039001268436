import { AnyAction } from '@reduxjs/toolkit';
import { ITestDealerPanelConfigurationNavigationState } from 'pages/DealerPanel/DealerPanelModelsPage/SelectVATAmountModal/SelectVATAmountModal';
import { Dispatch } from 'react';
import { setExtraPriceQuestionData } from 'store/Model/actions/model';
import { setSelectedExtraPriceQuestionOption } from 'store/SubmitConfiguration/actions/submitConfiguration';
import {
  IExtraPriceQuestionData,
  IExtraPriceQuestionOption,
  ITestCompanyModelConfiguration,
} from 'types/Configuration.types';

export const generateNewTestConfigurationFromFormikValues = (
  testConfiguration: ITestCompanyModelConfiguration,
  values: any,
  isExtraPriceQuestionActive: boolean, // Should be part of values, but need to be in useState instead of formik
  extraPriceQuestionData: IExtraPriceQuestionData, // Should be part of values, but need to be in useState instead of formik
  state: ITestDealerPanelConfigurationNavigationState
) => {
  const newTestConfiguration: ITestCompanyModelConfiguration = {
    model_id: Number(testConfiguration.model_id),
    base_markup: Number(values.base_markup),
    base_purchase_discount: Number(values.base_purchase_discount),
    surcharge_markup: Number(values.surcharge_markup),
    surcharge_purchase_discount: Number(values.surcharge_purchase_discount),
    fixed_amount: Number(values.fixed_amount),
    exclude_surcharge: values.exclude_surcharge,
    round_prices: values.round_prices,
    is_using_supplier_system_discounts: state.supplierHasSystemIntegration
      ? values.is_using_supplier_system_discounts
      : false,
    is_active: values.is_active,
    is_extra_price_question_active: isExtraPriceQuestionActive,
    extra_price_question_data: extraPriceQuestionData,
  };
  return newTestConfiguration;
};

export const handleChangeTestExtraPriceQuestion = (
  dispatch: Dispatch<AnyAction>,
  extraPriceQuestionData: IExtraPriceQuestionData,
  selectedExtraPriceQuestionOption: IExtraPriceQuestionOption
) => {
  dispatch(setExtraPriceQuestionData(extraPriceQuestionData));
  // Determine if the selected extra price option still exists as one of the options in the extra price question data
  if (selectedExtraPriceQuestionOption) {
    if (!extraPriceQuestionData?.options) {
      dispatch(setSelectedExtraPriceQuestionOption(null));
      return;
    }
    let optionFound = false;
    for (const option of extraPriceQuestionData.options) {
      if (
        option.label === selectedExtraPriceQuestionOption.label &&
        option.value === selectedExtraPriceQuestionOption.value
      ) {
        optionFound = true;
      }
    }
    if (!optionFound) {
      dispatch(setSelectedExtraPriceQuestionOption(null));
    }
  }
};
