import {
  Container,
  EditIconLabelWrapper,
  EditLabel,
  Grid,
  HorizontalLine,
  LabelKey,
  LabelValue,
  NameLabel,
  PriceLabel,
  PriceValueLabel,
  RemoveIconLabelWrapper,
  RemoveLabel,
  RowSpaceBetween,
  FirstRow,
  RightPart,
  LeftPart,
  DuplicateIconLabelWrapper,
  DuplicateLabel,
  ImagesConfigurationWrapper,
  GridPriceWrapper,
  PriceWrapper,
  BottomWrapper,
  QuantityWrapper,
  QuantityLabel,
} from './ShoppingCartCard.styled';
import { useChangeQuantityCartLine, useDuplicateCartLine } from './hooks';
import { parseQuestionsObjectResponseIntoArray } from 'store/Model/reducers/model';
import { calculateTotalNetPrice, handleTrueFalseLabel } from './helpers';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setImages, setPricing, setQuestions } from 'store/Model/actions/model';
import { useNavigate } from 'react-router-dom';
import { setCurrentlyEditingShoppingCartLineData } from 'store/ShoppingCart/actions/shoppingCart';
import FormImages from 'pages/ConfigurePage/FormImages/FormImages';
import {
  IImageSection,
  useFilterOutHiddenImages,
} from 'pages/ConfigurePage/FormImages/hooks';
import { RoutesConfig } from 'navigation/routes';
import Icon from 'components/Icon/Icon';
import { Copy, PencilSimple, Trash } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { formatValuesFromOutputs } from 'pages/ConfigurePage/ConfigureForm/helpers';
import { IQuestion } from 'types/Question.types';
import { ICartItem } from 'types/CartItem.types';
import { useGetValuesFromQuestionOutputs } from 'pages/ConfigurePage/ConfigureForm/hooks';
import Input from 'components/Input/Input';
import { useCallback, useState } from 'react';
import debounce from 'lodash.debounce';

interface IShoppingCartCardProps {
  item: ICartItem;
  onRemove: () => void;
  isLast: boolean;
}

const ShoppingCartCard = ({
  item,
  onRemove,
  isLast,
}: IShoppingCartCardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [quantity, setQuantity] = useState<string>(item.quantity.toString());

  const questions: IQuestion[] = parseQuestionsObjectResponseIntoArray(
    item.configuration.questions
  );
  const valuesFromOutputs = useGetValuesFromQuestionOutputs(questions);
  const formattedValuesFromOutputs = formatValuesFromOutputs(
    valuesFromOutputs,
    questions
  );

  const totalNetPrice = calculateTotalNetPrice(
    item.configuration.pricing,
    Number(quantity)
  );

  const { mutate: duplicateCartLine } = useDuplicateCartLine();
  const {
    mutate: changeQuantityCartLine,
    isLoading: isLoadingChangeQuantityCartLine,
  } = useChangeQuantityCartLine(item.id);

  // Quantity change for arrows
  const debouncedChangeQuantity = useCallback(
    debounce((value: number) => {
      changeQuantityCartLine(value);
    }, 500),
    [changeQuantityCartLine]
  );

  const hasImages =
    item.configuration.images && Object.keys(item.configuration.images).length;

  const visibleImages: IImageSection[] = useFilterOutHiddenImages(
    item.configuration.images
  );

  return (
    <Container>
      <FirstRow>
        <LeftPart>
          <NameLabel>{item.configuration.general.name}</NameLabel>
        </LeftPart>
        <RightPart>
          <EditIconLabelWrapper
            onClick={() => {
              if (!isLoadingChangeQuantityCartLine) {
                dispatch(setQuestions(item.configuration.questions));
                dispatch(setImages(item.configuration.images));
                dispatch(setPricing(item.configuration.pricing));
                dispatch(
                  setCurrentlyEditingShoppingCartLineData(
                    item.id,
                    Number(quantity)
                  )
                );
                navigate(
                  `${RoutesConfig.Configure.fullPath}?modelId=${item.model_id}`
                );
              }
            }}
          >
            <Icon
              disabled={isLoadingChangeQuantityCartLine}
              svg={PencilSimple}
              color={
                isLoadingChangeQuantityCartLine ? COLORS.GRAY_200 : COLORS.GREEN
              }
              onClick={() => null}
            />
            <EditLabel
              disabled={isLoadingChangeQuantityCartLine}
              onClick={() => null}
            >
              {t('Edit')}
            </EditLabel>
          </EditIconLabelWrapper>
          <RemoveIconLabelWrapper onClick={() => onRemove()}>
            <Icon svg={Trash} color={COLORS.RED_100} onClick={() => null} />
            <RemoveLabel onClick={() => onRemove()}>{t('Remove')}</RemoveLabel>
          </RemoveIconLabelWrapper>
          <DuplicateIconLabelWrapper onClick={() => duplicateCartLine(item.id)}>
            <Icon svg={Copy} color={COLORS.GREEN} onClick={() => null} />
            <DuplicateLabel>{t('Duplicate')}</DuplicateLabel>
          </DuplicateIconLabelWrapper>
        </RightPart>
      </FirstRow>
      <ImagesConfigurationWrapper>
        {hasImages ? (
          <FormImages visibleImages={visibleImages} singleImage />
        ) : null}
        <GridPriceWrapper>
          <Grid hasImages={hasImages}>
            {Object.keys(formattedValuesFromOutputs)?.map(
              (dataKey: string, index: number) => (
                <RowSpaceBetween key={index}>
                  <LabelKey>{dataKey}</LabelKey>
                  <LabelValue>
                    {handleTrueFalseLabel(
                      formattedValuesFromOutputs[dataKey]?.toString(),
                      t
                    )}
                  </LabelValue>
                </RowSpaceBetween>
              )
            )}
          </Grid>
          <BottomWrapper>
            <QuantityWrapper>
              <QuantityLabel>{t('Quantity')}:</QuantityLabel>
              <Input
                label=""
                placeholder=""
                width={'90rem'}
                type="number"
                value={quantity}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === '') {
                    setQuantity(value);
                    return;
                  }
                  const number = Number(value);
                  if (isNaN(number) || number < 0) {
                    return;
                  }
                  setQuantity(value);
                }}
                onBlur={() => {
                  debouncedChangeQuantity.cancel(); // Avoid double requests

                  let value = parseInt(quantity, 10);
                  if (isNaN(value) || value < 1) value = 1;
                  setQuantity(value.toString());
                  if (
                    value !== item.quantity &&
                    !isLoadingChangeQuantityCartLine
                  ) {
                    changeQuantityCartLine(value);
                  }
                }}
                onIncrease={() => {
                  let value = parseInt(quantity, 10);
                  if (isNaN(value) || value < 1) value = 1;
                  const newValue = value + 1;
                  setQuantity(newValue.toString());
                  debouncedChangeQuantity(newValue);
                }}
                onDecrease={() => {
                  let value = parseInt(quantity, 10);
                  if (isNaN(value) || value <= 1) value = 1;
                  else value = value - 1;
                  setQuantity(value.toString());
                  debouncedChangeQuantity(value);
                }}
              />
            </QuantityWrapper>
            <PriceWrapper>
              <PriceLabel hasImages={hasImages}>
                {t('Price')}:
                <PriceValueLabel>
                  &nbsp;
                  {totalNetPrice !== null
                    ? '€' + totalNetPrice.toFixed(2)
                    : 'Unknown price'}
                </PriceValueLabel>
              </PriceLabel>
            </PriceWrapper>
          </BottomWrapper>
        </GridPriceWrapper>
      </ImagesConfigurationWrapper>
      {isLast ? null : <HorizontalLine hasImages={hasImages} />}
    </Container>
  );
};
export default ShoppingCartCard;
