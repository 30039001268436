import { borderRadiusSm, COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 50rem 50rem 20rem;
  display: flex;
  flex-direction: column;

  ${respondTo.smallLaptop`
    padding: 50rem 30rem 30rem;
  `}

  ${respondTo.midTablet`
    padding: 30rem 15rem 20rem;
  `}
`;

export const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15rem;
  margin-bottom: 30rem;

  ${respondTo.smallTablet`
    margin-bottom: 20rem;
  `}
`;

export const Title = styled.label`
  font-weight: 300;
  font-size: 50rem;
  line-height: 68rem;
  font-weight: 300;
  color: ${COLORS.BLACK};

  ${respondTo.smallLaptop`
    font-size: 40rem;
    line-height: 50rem;
  `}

  ${respondTo.smallTablet`
    font-size: 30rem;
  `}
`;

export const BackLabel = styled.label`
  margin-left: 5rem;
  font-size: 18rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const BackWrapper = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  &:hover {
    ${BackLabel} {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-thickness: 1rem;
    }
  }
`;

export const Card = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  padding: 30rem;

  ${respondTo.bigPhone`
    padding: 15rem;
  `}
`;

export const ShippingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20rem;
`;

export const ShippingTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${respondTo.bigPhone`
    flex-direction: column;
    align-items: flex-start;
    gap: 20rem;
  `}
`;

export const ShippingAddress = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 3%;
  align-items: stretch;
  border-bottom: 1px solid ${COLORS.BORDER};

  ${respondTo.bigTablet`
    flex-direction: column;
  `}
`;

export const DeliveryDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  gap: 20rem;
  padding: 30rem 0;
  border-bottom: 1px solid ${COLORS.BORDER};
`;

export const SectionTitle = styled.span`
  display: block;
  font-size: 30rem;
  font-weight: 400;
  color: ${COLORS.BLACK};

  ${respondTo.smallTablet`
    font-size: 24rem;
  `}

  ${respondTo.bigPhone`
    font-size: 22rem;
  `}
`;

export const OrderReference = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  gap: 20rem;
  padding: 30rem 0;
`;

export const ConfirmWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;
