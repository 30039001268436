import { FormikContextType, getIn } from 'formik';
import { IQuestion, QuestionType } from 'types/Question.types';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import Input from '../Input';
import {
  Container,
  InputNumberWrapper,
  Overlay,
} from './OnBlurInputFormik.styled';
import { useValidateNumberAfterValueChange } from './hooks';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setTouchedQuestionName } from 'store/Form/actions/form';
import { useSetManuallyChangeQuestionName } from 'pages/ConfigurePage/ConfigureForm/hooks';

interface IOnBlurInputFormikNumberProps {
  question: IQuestion;
  context: FormikContextType<any>;
  isTouched: boolean;
  pwId: string;
}

const OnBlurInputFormikNumber = ({
  question,
  context,
  isTouched,
  pwId,
}: IOnBlurInputFormikNumberProps) => {
  const dispatch = useDispatch();
  // isFocused and valueAtTimeOfFocus are used to handle cases where users input values in OnBlur form items,
  // and proceed to click Submit button without triggering the onBlur (skipping the last required query request)

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const prevFormikValueRef = useRef(getIn(context.values, question.initialKey));
  const [valueAtTimeOfFocus, setValueAtTimeOfFocus] = useState<
    number | string | null
  >();
  // isHovered so we dont display the overlay if user is clicking arrow buttons to increase value
  const [isHovered, setIsHovered] = useState(false);

  const [numberValue, setNumberValue] = useState<number | string>(
    question.type === QuestionType.NUMBER
      ? getIn(context.values, question.initialKey)
      : ''
  );
  const errorMessage = useValidateNumberAfterValueChange(
    numberValue,
    question,
    context
  );

  const setManuallyChangeQuestionName =
    useSetManuallyChangeQuestionName(dispatch);

  useEffect(() => {
    const formikValue = getIn(context.values, question.initialKey);

    if (formikValue !== prevFormikValueRef.current) {
      prevFormikValueRef.current = formikValue;
      setNumberValue(formikValue ?? '');
    }
  }, [context.values]);

  return (
    <Container>
      {isFocused && !isHovered && valueAtTimeOfFocus !== numberValue && (
        <Overlay />
      )}
      <InputNumberWrapper>
        <Input
          pwId={pwId}
          value={numberValue}
          type="number"
          height={'40rem'}
          width={'100%'}
          wrapperStyles={{ width: '100%' }}
          placeholder={capitalizeFirstLetter(question.name)}
          onChange={(e) => {
            const value = e.target.value.trim();
            setNumberValue(value === '' ? value : Number(value));
          }}
          onBlur={(e) => {
            const value = e.target.value.trim();

            // Ensure Redux is updated only when the value actually changes.
            // This prevents unnecessary dispatches while still capturing deletions
            // (e.g., when a user selects and clears the input).
            if (value !== getIn(context.values, question.initialKey)) {
              setManuallyChangeQuestionName(value, question.initialKey);
            }

            if (!errorMessage) {
              context.setFieldValue(question.initialKey, value?.toString());
              context.handleBlur(e);
            }
            dispatch(setTouchedQuestionName(question.initialKey));
            setIsFocused(false);
            setValueAtTimeOfFocus(null);
          }}
          onIncrease={() => {
            const newValue = Number(numberValue) + 1;
            setNumberValue(newValue);
            setManuallyChangeQuestionName(newValue, question.initialKey);
          }}
          onDecrease={() => {
            const newValue = Number(numberValue) - 1;
            setNumberValue(newValue);
            setManuallyChangeQuestionName(newValue, question.initialKey);
          }}
          isError={isTouched && !!errorMessage} // for showing red border around input
          onFocus={() => {
            setIsFocused(true);
            setValueAtTimeOfFocus(numberValue);
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
      </InputNumberWrapper>
    </Container>
  );
};

export default OnBlurInputFormikNumber;
