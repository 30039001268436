import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${COLORS.BORDER};
  border-radius: 10rem;
  background-color: ${COLORS.WHITE};
  padding: 20rem;

  ${respondTo.horizontalTablet`
    padding: 15rem;
  `}
`;

export const Headline = styled.span`
  display: block;
  font-size: 30rem;
  color: ${COLORS.BLACK};

  ${respondTo.horizontalTablet`
    font-size: 25rem;
  `}
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20rem;
  gap: 50rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20rem;
`;

export const FieldNameWrapper = styled.div`
  display: flex;
  gap: 10rem;
  align-items: center;
  cursor: pointer;
`;

export const FieldName = styled.span`
  display: block;
  font-size: 24rem;
  color: ${COLORS.BLACK};
`;
