import { COLORS, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import { marginLg, marginMd, marginXs } from 'assets/styled/variables';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  margin-top: 100rem;
  width: 179rem;
  height: 261rem;
  margin-bottom: ${marginLg};

  ${respondTo.smallTablet`
    width: 150rem;
    height: 240rem;
    margin-bottom: 20rem;
    margin-top: 0;
  `}
`;

export const Form = styled.form`
  max-width: 100%;
  width: 100%;
`;

export const EmailInputWrapper = styled.div`
  margin-bottom: ${marginSm};
  position: relative;
  width: 580rem;

  ${respondTo.bigPhone`
    width: 100%;
    max-width: 100%;
  `}
`;

export const RememberMeLabel = styled.label`
  font-size: 16rem;
  font-weight: 400;
  line-height: 25rem;
  margin-left: ${marginXs};
  color: ${COLORS.BLACK};
  cursor: pointer;

  ${respondTo.smallPhone`
    font-size: 14rem;
  `}
`;

export const RememberMeRow = styled.div`
  display: flex;
  flex-direction: row;
  &:hover {
    ${RememberMeLabel} {
      text-decoration: underline;
    }
  }
`;

export const RememberMeForgotPasswordWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  width: 580rem;
  gap: 20rem;

  ${respondTo.bigPhone`
    width: 100%;
    max-width: 100%;
  `}
`;

export const ForgotPasswordLabel = styled.label`
  font-size: 16rem;
  font-weight: 400;
  line-height: 25rem;
  color: ${COLORS.BLACK};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  ${respondTo.smallPhone`
    font-size: 14rem;
    text-align: end;
  `}
`;

export const EyeWrapper = styled.div`
  cursor: pointer;
  height: fit-content;
  user-select: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15rem;
`;

export const PasswordInputWrapper = styled.div`
  position: relative;
  margin-bottom: ${marginXs};
  width: 580rem;

  ${respondTo.bigPhone`
    width: 100%;
    max-width: 100%;
  `}
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: ${marginMd};
  margin-bottom: ${marginMd};
`;
