export const calculateTotalNetPrice = (
  pricing: object | null,
  quantity: number
) => {
  if (!pricing) {
    return null;
  }
  let totalPrice = 0;
  for (const pricingItem of Object.values(pricing)) {
    if (pricingItem.value === 'Invalid') {
      return 0;
    } else {
      if (pricingItem.type === 'net_base' || pricingItem.type === 'net_extra') {
        totalPrice = totalPrice + pricingItem.value;
      }
    }
  }
  return totalPrice * quantity;
};

export const handleTrueFalseLabel = (
  label: string,
  t: (str: string) => string
) => {
  return ['true', 'false'].includes(label)
    ? label === 'true'
      ? t('true')
      : t('false')
    : label;
};
