import { IExtraPriceQuestionOption } from 'types/Configuration.types';

export const areThereExtraPriceQuestionUnsavedChanges = (
  initialIsExtraPriceQuestionActive: boolean | undefined,
  initialExtraPriceQuestionName: string | undefined,
  initialExtraPriceQuestionOptions: IExtraPriceQuestionOption[] | undefined,
  currentIsActive: boolean,
  currentName: string,
  currentOptions: IExtraPriceQuestionOption[]
): boolean => {
  const hasActiveChanged =
    !!initialIsExtraPriceQuestionActive !== !!currentIsActive;

  let hasNameChanged = initialExtraPriceQuestionName !== currentName;
  if (!initialExtraPriceQuestionName && !currentName) {
    hasNameChanged = false;
  }

  const hasOptionsChanged =
    JSON.stringify(initialExtraPriceQuestionOptions || []) !==
    JSON.stringify(currentOptions || []);

  return hasActiveChanged || hasNameChanged || hasOptionsChanged;
};
