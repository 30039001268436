import { getIn } from 'formik';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { IQuestion } from 'types/Question.types';
import { COLORS } from 'assets/styled';
import { useValidateRadioInput } from './hooks';
import { useSetManuallyChangeQuestionName } from 'pages/ConfigurePage/ConfigureForm/hooks';
import { useDispatch } from 'react-redux';

interface IRadioInputProps {
  pwId: string;
  question: IQuestion;
  context: any;
}

const RadioInput = ({ pwId, question, context }: IRadioInputProps) => {
  const dispatch = useDispatch();
  const selectedOptionValue = getIn(context.values, question.initialKey);
  const errorMessage = useValidateRadioInput(question, context);
  const setManuallyChangeQuestionName =
    useSetManuallyChangeQuestionName(dispatch);

  return (
    <FormControl component="fieldset" error={!!errorMessage}>
      <RadioGroup
        name={question.initialKey}
        value={selectedOptionValue}
        onChange={(event) => {
          const rawValue = event.target.value;

          // MUI Radio returns string values — parse back to original type (number/boolean) by matching stringified option and inferring type from it
          const originalOption = question.options.find(
            (opt) => String(opt[0]) === rawValue
          );

          let parsedValue: string | number | boolean = rawValue;

          if (originalOption) {
            const originalValue = originalOption[0];
            if (typeof originalValue === 'number') {
              parsedValue = Number(rawValue);
            } else if (typeof originalValue === 'boolean') {
              parsedValue = rawValue === 'true';
            }
          }

          context.setFieldValue(question.initialKey, parsedValue);
          setManuallyChangeQuestionName(parsedValue, question.initialKey);
        }}
        row={question.options.length <= 3}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          width: '100%',
        }}
      >
        {question.options.map((option: any[], index: number) => {
          const isChecked = selectedOptionValue === option[0];
          return (
            <FormControlLabel
              key={index}
              value={option[0]}
              control={
                <Radio
                  checked={isChecked}
                  data-testid={`${pwId}-${index}`}
                  size="small"
                  sx={{
                    color: COLORS.GREEN,
                    '&.Mui-checked': { color: COLORS.GREEN },
                  }}
                />
              }
              label={option[0]}
              sx={{
                color: COLORS.BLACK,
                fontSize: '16rem',
                fontFamily: 'inherit',
                '& .MuiTypography-root': {
                  fontSize: '16rem',
                  fontFamily: 'inherit',
                },
              }}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioInput;
