import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SET_IS_DARK_MODE } from 'store/constants';
import { IRootReducerState } from 'store/store';
import {
  setBase64Metadata,
  setIntentUUID,
} from 'store/SubmitConfiguration/actions/submitConfiguration';
import {
  setCompany,
  setIntent,
  setIsLoggedIn,
  setSupplier,
  setUserInfo,
} from 'store/User/actions/user';
import {
  setAppMode,
  setIsLoadingScreenShown,
  setIsMobileApp,
  setQueryParamsProcessed,
} from 'store/Common/actions/common';
import { AppMode } from 'store/Common/reducers/common';
import i18n from 'providers/i18n/i18n';
import LocalStorageService from 'services/LocalStorageService';
import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_REFRESH_TOKEN,
} from 'services/api/constants';
import { useGetCompanySupplierPermissionsAndIntent } from './hooks';
import { IUserData } from 'store/User/types';

interface IUrlParamsProcessorProps {
  children: React.ReactNode;
}

const EMPTY_USER_INFO: IUserData = {
  id: null,
  email: null,
  first_name: null,
  last_name: null,
  is_admin: false,
  permissions: null,
};

const removeAccessAndRefreshTokens = async () => {
  await LocalStorageService.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
  await LocalStorageService.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
};

export const UrlParamsProcessor = ({ children }: IUrlParamsProcessorProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { queryParamsProcessed, appMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  const { mutate: getCompanySupplierPermissionsAndIntent } =
    useGetCompanySupplierPermissionsAndIntent();

  useEffect(() => {
    if (queryParamsProcessed) return;

    // Process intentUUID (is present when opening in Blueprint or Dealer Panel mode)
    const intentUUID = queryParams.get('intentUUID');
    if (intentUUID) {
      const base64Metadata = queryParams.get('base64Metadata');
      dispatch(setIntentUUID(intentUUID));
      dispatch(setBase64Metadata(base64Metadata));
    } else {
      dispatch(setIntentUUID(''));
      dispatch(setIntent(null));
      dispatch(setBase64Metadata(null));
    }

    // Process isDealerPanel
    const isDealerPanel = queryParams.get('isDealerPanel') === 'true';

    // Process app mode

    if (isDealerPanel && intentUUID) {
      dispatch(setAppMode(AppMode.DEALER_PANEL));
    } else if (intentUUID) {
      dispatch(setAppMode(AppMode.BLUEPRINT));
    } else {
      // Preseve app mode on page refresh
      if (appMode !== AppMode.SUPPLIER) {
        dispatch(setAppMode(AppMode.COMPANY)); // User will log in through normal auth flow (if not already logged in)
      }
    }

    if (isDealerPanel || intentUUID) {
      // Process dark mode
      const isDarkMode = queryParams.get('isDarkMode') === 'true';
      dispatch({ type: SET_IS_DARK_MODE, payload: isDarkMode });

      // Process language
      const lang = queryParams.get('lang');
      if (lang) {
        i18n.changeLanguage(lang);
      }

      // Process isMobileApp
      const isMobileApp = queryParams.get('isMobileApp');
      if (isMobileApp) {
        dispatch(setIsMobileApp(true));
      }

      // Reset user info and company for non-authenticated modes
      removeAccessAndRefreshTokens(); // User is instead authenticated by intentUUID
      dispatch(setIsLoggedIn(false)); // User is not logged in as supplier or company
      dispatch(setUserInfo(EMPTY_USER_INFO));
      dispatch(setCompany(null));
      dispatch(setSupplier(null));
      dispatch(setIsLoadingScreenShown(true));

      getCompanySupplierPermissionsAndIntent(undefined, {
        onSuccess: (data) => {
          dispatch(setIntent(data.intent));
          dispatch(setCompany(data.company));
          dispatch(setSupplier(data.supplier));
          dispatch(
            setUserInfo({ ...EMPTY_USER_INFO, permissions: data.permissions })
          );
          // Loading screen will be hidden based on logic in useHandleLoadingScreenState()
        },
      });
    }

    dispatch(setQueryParamsProcessed(true));
  }, [queryParams, queryParamsProcessed]);

  return <>{children}</>;
};
