import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';
import { css } from 'styled-components';

interface StyledProps {
  bgColor?: boolean;
  isSelected?: boolean;
  imageLoaded?: boolean;
  color?: string;
}

export const Container = styled.div`
  cursor: pointer;
  width: 290rem;
  height: 120rem;
  border-radius: 20rem;
  border-bottom-right-radius: 20rem;
  border-top-right-radius: 20rem;
  display: flex;
  ${(props: StyledProps) =>
    props.bgColor &&
    css`
      background-color: ${(props: StyledProps) => props.bgColor};
    `};
  ${(props: StyledProps) =>
    props.isSelected
      ? css`
          box-shadow: 0px 0px 0px 5rem ${COLORS.GREEN};
        `
      : css`
          box-shadow: 0px 0px 0px 5rem ${COLORS.WHITE};
          &:hover {
            box-shadow: 0px 0px 0px 5rem ${COLORS.GREEN};
          }
        `}

  ${respondTo.smallTablet`
    width: 280rem;
  `}

  :focus-visible {
    outline: none;
    box-shadow: 0 0 0 1rem ${COLORS.GREEN};
  }
`;

export const LeftPart = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16rem;
  width: 126rem;
`;

export const COLOR_CARD_IMAGE_WIDTH = 174;

export const imageContainerStyle = css`
  height: 120rem;
  width: ${COLOR_CARD_IMAGE_WIDTH}rem;
`;
export const imageStyle = css`
  ${(props: StyledProps) =>
    props.imageLoaded
      ? css`
          height: 120rem;
          width: ${COLOR_CARD_IMAGE_WIDTH}rem;
          border-radius: 20rem;
        `
      : css`
          width: 0;
          height: 0;
          opacity: 0;
        `};
`;

export const Title = styled.label`
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  ${(props: StyledProps) =>
    props.color &&
    css`
      color: ${props.color};
    `};
  word-break: break-word;
`;

export const Subtitle = styled.label`
  color: ${COLORS.BLACK};
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  ${(props: StyledProps) =>
    props.color &&
    css`
      color: ${props.color};
    `};
  line-break: normal;
`;
