import { AnyAction } from '@reduxjs/toolkit';
import {
  SET_INTENT_UUID,
  TRIGGER_SUBMIT_CONFIGURATION,
  GET_EDIT_CONFIGURATION_ATTEMPT,
  GET_EDIT_CONFIGURATION_SUCCESS,
  GET_EDIT_CONFIGURATION_ERROR,
  GET_EDIT_CONFIGURATION_RESET,
  SET_TEST_COMPANY_MODEL_CONFIGURATION,
  SET_BASE64_METADATA,
  SET_IS_TEST_CONFIGURATION_MODAL_OPEN,
  SET_SELECTED_EXTRA_PRICE_QUESTION_OPTION,
} from '../../constants';
import {
  IExtraPriceQuestionOption,
  ITestCompanyModelConfiguration,
} from 'types/Configuration.types';

export interface ISubmitConfigurationReducerState {
  intentUUID: string | null;
  triggerSubmitConfiguration: boolean;
  getEditConfigurationStatus: {
    attempt: boolean;
    success: boolean;
    error: string | null;
  };
  includeBomItems: boolean;
  testCompanyModelConfiguration: ITestCompanyModelConfiguration | null;
  base64Metadata: string | null;
  isTestConfigurationModalOpen: boolean;
  selectedExtraPriceQuestionOption: IExtraPriceQuestionOption | null;
}

const INIT_STATE: ISubmitConfigurationReducerState = {
  intentUUID: null,
  triggerSubmitConfiguration: false,
  getEditConfigurationStatus: {
    attempt: false,
    success: false,
    error: null,
  },
  includeBomItems: false,
  testCompanyModelConfiguration: null,
  base64Metadata: null,
  isTestConfigurationModalOpen: false,
  selectedExtraPriceQuestionOption: null,
};

const submitConfigurationReducer = (state = INIT_STATE, action: AnyAction) => {
  switch (action.type) {
    case SET_INTENT_UUID:
      return {
        ...state,
        intentUUID: action.payload,
      };
    case TRIGGER_SUBMIT_CONFIGURATION:
      return {
        ...state,
        triggerSubmitConfiguration: action.payload,
      };
    case GET_EDIT_CONFIGURATION_ATTEMPT:
      return {
        ...state,
        getEditConfigurationStatus: {
          attempt: true,
          success: false,
          error: null,
        },
      };
    case GET_EDIT_CONFIGURATION_SUCCESS:
      return {
        ...state,
        getEditConfigurationStatus: {
          attempt: false,
          success: true,
          error: null,
        },
      };
    case GET_EDIT_CONFIGURATION_ERROR:
      return {
        ...state,
        getEditConfigurationStatus: {
          attempt: false,
          success: false,
          error: action.payload,
        },
      };
    case GET_EDIT_CONFIGURATION_RESET:
      return {
        ...state,
        getEditConfigurationStatus: {
          attempt: false,
          success: false,
          error: null,
        },
      };
    case SET_TEST_COMPANY_MODEL_CONFIGURATION:
      return {
        ...state,
        testCompanyModelConfiguration: action.payload,
      };
    case SET_BASE64_METADATA:
      return {
        ...state,
        base64Metadata: action.payload,
      };
    case SET_IS_TEST_CONFIGURATION_MODAL_OPEN:
      return {
        ...state,
        isTestConfigurationModalOpen: action.payload,
      };
    case SET_SELECTED_EXTRA_PRICE_QUESTION_OPTION:
      return {
        ...state,
        selectedExtraPriceQuestionOption: action.payload,
      };
    default:
      return state;
  }
};

export default submitConfigurationReducer;
