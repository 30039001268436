import { useTranslation } from 'react-i18next';
import {
  checkboxLabelStyling,
  ClickableLabel,
  Container,
  Dropdown,
  Item,
} from './TableColumnSelector.styled';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { useRef, useState } from 'react';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';
import { useDispatch } from 'react-redux';
import { savePageFilters } from 'store/Filters/actions/filters';
import { FiltersPageEnum } from 'store/Filters/constants';
import Tooltip from 'components/Tooltip/Tooltip';

interface ITableColumnSelectorProps {
  columns: { header: string; isLocked?: boolean }[];
  visibleColumns: string[];
}

const TableColumnSelector = ({
  columns,
  visibleColumns,
}: ITableColumnSelectorProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);

  const dropDownRef = useRef();
  useOutsideAlerter(dropDownRef, () => setIsDropDownOpen(false));

  const toggleColumn = (column: string) => {
    dispatch(
      savePageFilters({
        page: FiltersPageEnum.BOM,
        data: {
          visibleColumns: visibleColumns.includes(column)
            ? visibleColumns.filter((col) => col !== column)
            : [...visibleColumns, column],
        },
      })
    );
  };

  return (
    <Container>
      <Tooltip content={t('Show/hide columns in table')}>
        <ClickableLabel onClick={() => setIsDropDownOpen((prev) => !prev)}>
          {t('Manage table')}
        </ClickableLabel>
      </Tooltip>

      {isDropDownOpen && (
        <Dropdown ref={dropDownRef}>
          {columns.map((col) => (
            <Item key={col.header} $isDisabled={col.isLocked}>
              <Checkbox
                isChecked={visibleColumns.includes(col.header)}
                label={t(col.header)}
                labelStyles={checkboxLabelStyling}
                onChange={() => {
                  if (!col.isLocked) toggleColumn(col.header);
                }}
                wrapperStyles={{ gap: '10rem' }}
                isDisabled={col.isLocked}
              />
            </Item>
          ))}
        </Dropdown>
      )}
    </Container>
  );
};

export default TableColumnSelector;
