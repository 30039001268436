import { IModel } from 'types/Model.types';
import {
  AbsoluteCircleWrapper,
  AbsoluteVersionWrapper,
  Container,
  ModelIdLabel,
  NameLabel,
  RowCenter,
  favoriteIconStyle,
  imageStyle,
} from './ModelCard.styled';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setModel, setPricing, setQuestions } from 'store/Model/actions/model';
import { useTranslation } from 'react-i18next';
import { formatModelName } from './helpers';
import { IRootReducerState } from 'store/store';
import { setCurrentlyEditingShoppingCartLineData } from 'store/ShoppingCart/actions/shoppingCart';
import { RoutesConfig } from 'navigation/routes';
import { AppMode } from 'store/Common/reducers/common';
import {
  AdaptiveImage,
  AdaptiveImageType,
} from 'components/AdaptiveImage/AdaptiveImage';
import {
  resetManuallyChangedQuestionNames,
  resetTouchedQuestionNames,
  setAreAllFieldsTouched,
} from 'store/Form/actions/form';
import { FavoriteButton } from './FavoriteButton/FavoriteButton';

interface IModelCardProps {
  model: IModel;
  onToggleFavoriteSuccess?: () => void;
}

const ModelCard = ({ model, onToggleFavoriteSuccess }: IModelCardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const appMode: AppMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );

  const handleOnClick = () => {
    if (appMode === AppMode.COMPANY) {
      dispatch(resetTouchedQuestionNames());
      dispatch(resetManuallyChangedQuestionNames());
      dispatch(setAreAllFieldsTouched(false));
      dispatch(setCurrentlyEditingShoppingCartLineData(null, null));
      navigate(`${RoutesConfig.Configure.fullPath}?modelId=${model.id}`);
      dispatch(setModel({ id: model.id }));
      dispatch(setQuestions({}));
      dispatch(setPricing({}));
    } else {
      navigate(
        RoutesConfig.SupplierModelCompanies.fullPath.replace(
          ':id',
          String(model.id)
        )
      );
    }
  };

  return (
    <Container
      data-testid={`model-card-${model.id}`}
      onClick={() => handleOnClick()}
    >
      <AbsoluteCircleWrapper>
        <ModelIdLabel>{model.id}</ModelIdLabel>
      </AbsoluteCircleWrapper>
      {appMode === AppMode.SUPPLIER && (
        <AbsoluteVersionWrapper>
          {t('Version')}: {model.version_number}
        </AbsoluteVersionWrapper>
      )}
      <AdaptiveImage
        imageUrl={model.general.images[0]}
        type={AdaptiveImageType.MODEL}
        imageStyle={imageStyle}
        absoluteComponent={
          <>
            {onToggleFavoriteSuccess && (
              <FavoriteButton
                key={`${model.id}${model.is_favorite}`}
                favoriteIconStyle={favoriteIconStyle}
                modelId={model.id}
                initialIsFavorite={model.is_favorite}
                onToggleSuccess={onToggleFavoriteSuccess}
              />
            )}
          </>
        }
      />
      <RowCenter>
        <NameLabel>
          {model.general.name
            ? formatModelName(model.general.name)
            : t('Unnamed model')}
        </NameLabel>
      </RowCenter>
    </Container>
  );
};
export default ModelCard;
