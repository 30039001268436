import { useEffect, useState } from 'react';
import { IModel } from 'types/Model.types';
import {
  ModelRowsContainer,
  NoContentLabel,
  NoContentLabelWrapper,
} from './RecentlyViewedSectionList.styled';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import Pagination from 'components/Pagination/Pagination';
import { RECENTLY_VIEWED_MODELS_PER_PAGE_LIST } from './constants';
import { useTranslation } from 'react-i18next';
import useIsFocused from 'utils/hooks/useIsFocused';
import ModelRow from 'pages/ModelsPage/ModelsPageList/SupplierCategoriesList/CategoryList/ModelRow/ModelRow';
import ModelRowSkeleton from 'pages/ModelsPage/ModelsPageList/SupplierCategoriesList/CategoryList/ModelRow/ModelRowSkeleton/ModelRowSkeleton';
import { useGetRecentlyViewedModels } from 'pages/ModelsPage/hooks';

const RecentlyViewedSectionList = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const { data: modelsData, isLoading: isLoadingModels } =
    useGetRecentlyViewedModels(
      page,
      RECENTLY_VIEWED_MODELS_PER_PAGE_LIST,
      '',
      null,
      null
    );

  const models: IModel[] = modelsData?.models || [];

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === ReactQueryKeys.GET_RECENTLY_VIEWED_MODELS &&
          query.queryKey[2] === RECENTLY_VIEWED_MODELS_PER_PAGE_LIST &&
          query.queryKey[3] === '' &&
          query.queryKey[4] === null &&
          query.queryKey[5] === null &&
          query.queryKey[6] === true &&
          query.queryKey[7] === false,
      });
    }
  }, [isFocused]);

  const showPagination =
    modelsData?.pagination?.total &&
    modelsData?.pagination?.total >= RECENTLY_VIEWED_MODELS_PER_PAGE_LIST;

  return (
    <>
      {models?.length ? (
        <ModelRowsContainer>
          {models?.map((model: IModel, index: number) => (
            <ModelRow
              model={model}
              key={`recently-viewed-model-row-${index}`}
              withoutLine={models.length - 1 === index}
              onToggleFavoriteSuccess={() => {
                queryClient.invalidateQueries([
                  ReactQueryKeys.GET_RECENTLY_VIEWED_MODELS,
                ]);
                queryClient.invalidateQueries([
                  ReactQueryKeys.GET_FAVORITE_MODELS,
                ]);
                queryClient.invalidateQueries([
                  ReactQueryKeys.GET_MODELS_INFINITE,
                ]);
              }}
            />
          ))}
          {showPagination ? (
            <Pagination
              page={page}
              perPage={RECENTLY_VIEWED_MODELS_PER_PAGE_LIST}
              total={modelsData?.pagination?.total || '0'}
              setPage={setPage}
            />
          ) : null}
        </ModelRowsContainer>
      ) : isLoadingModels ? (
        <ModelRowsContainer isSkeleton>
          {Array.from(
            { length: RECENTLY_VIEWED_MODELS_PER_PAGE_LIST },
            (_, index) => (
              <ModelRowSkeleton
                key={index}
                index={index}
                withoutLine={RECENTLY_VIEWED_MODELS_PER_PAGE_LIST - 1 === index}
              />
            )
          )}
        </ModelRowsContainer>
      ) : (
        <NoContentLabelWrapper>
          <NoContentLabel>
            {t('No recently viewed models found')}
          </NoContentLabel>
        </NoContentLabelWrapper>
      )}
    </>
  );
};

export default RecentlyViewedSectionList;
