import {
  REMOVE_MANUALLY_CHANGED_QUESTION_NAME,
  RESET_MANUALLY_CHANGED_QUESTION_NAMES,
  RESET_TOUCHED_QUESTION_NAMES,
  SET_ARE_ALL_FIELDS_TOUCHED,
  SET_INCLUDE_BOM_ITEMS,
  SET_IS_FORM_VALID,
  SET_MANUALLY_CHANGED_QUESTION_NAMES,
  SET_MODEL_NOT_FOUND,
  SET_TOUCHED_QUESTION_NAME,
} from 'store/constants';

export const setAreAllFieldsTouched = (areAllFieldsTouched: boolean) => ({
  type: SET_ARE_ALL_FIELDS_TOUCHED,
  payload: areAllFieldsTouched,
});

export const setTouchedQuestionName = (questionName: string) => ({
  type: SET_TOUCHED_QUESTION_NAME,
  payload: questionName,
});

export const setManuallyChangedQuestionNames = (questionName: string) => ({
  type: SET_MANUALLY_CHANGED_QUESTION_NAMES,
  payload: questionName,
});

export const resetManuallyChangedQuestionNames = () => ({
  type: RESET_MANUALLY_CHANGED_QUESTION_NAMES,
});

export const removeManuallyChangedQuestionName = (questionName: string) => ({
  type: REMOVE_MANUALLY_CHANGED_QUESTION_NAME,
  payload: questionName,
});

export const resetTouchedQuestionNames = () => ({
  type: RESET_TOUCHED_QUESTION_NAMES,
});

export const setIsFormValid = (isFormValid: boolean) => ({
  type: SET_IS_FORM_VALID,
  payload: isFormValid,
});

export const setModelNotFound = (modelNotFound: boolean) => ({
  type: SET_MODEL_NOT_FOUND,
  payload: modelNotFound,
});

export const setIncludeBomItems = (boolean: boolean) => ({
  type: SET_INCLUDE_BOM_ITEMS,
  payload: boolean,
});
