import { IOption } from 'components/Select/type';
import i18n from 'providers/i18n/i18n';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import countriesJSON from 'assets/countries.json';
import { ICountry } from 'types/Country.types';

export const useGetCountryOptions = () => {
  const { t } = useTranslation();

  const countries = [...countriesJSON];
  const countryOptions: IOption[] = useMemo(
    () =>
      countries
        .sort((a: any, b: any) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();

          if (nameA < nameB) {
            return -1;
          } else if (nameA > nameB) {
            return 1;
          } else {
            return 0;
          }
        })
        .map((country: ICountry) => {
          return {
            value: country.cca2,
            label: `${country.cca2} - ${t(country.name)}`,
            key: country.name,
          };
        }),
    [countries, i18n.resolvedLanguage]
  );

  return countryOptions;
};
