export interface IFormattedBOMItem {
  id: string;
  description: string;
  length: string;
  quantity_per: string;
  unit: string;
  not_sell_before?: string;
  not_sell_after?: string;
}

export const formatBomItems = (bomItems: object): IFormattedBOMItem[] => {
  if (!bomItems) {
    return [];
  }
  const formattedBomItems: IFormattedBOMItem[] = [];
  for (const bomItemKey of Object.keys(bomItems)) {
    const initialBomItem = bomItems[bomItemKey];
    if (initialBomItem.hidden || !initialBomItem.id) {
      continue;
    }

    const formattedBomItem: IFormattedBOMItem = {
      id: initialBomItem.id,
      description: initialBomItem.description,
      length: initialBomItem.cutting_length,
      quantity_per: initialBomItem.quantity_per,
      unit: initialBomItem.unit,
      not_sell_before: initialBomItem.notbefore,
      not_sell_after: initialBomItem.notafter,
    };

    formattedBomItems.push(formattedBomItem);
  }

  return formattedBomItems;
};
