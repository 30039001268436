import apiClient from 'services/api/apiService';
import { getUrlWithQueryParams } from './api/getUrlWithQueryParams';
import { IModelGeneral } from 'types/Model.types';
import { IImageSection } from 'pages/ConfigurePage/FormImages/hooks';
import {
  IExtraPriceQuestionData,
  ITestCompanyModelConfiguration,
} from 'types/Configuration.types';
import { SortOrder } from 'components/Table/types';
import { cleanPricingQueryData } from 'providers/QueryProvider/helpers';

const BASE_URL = '/v1/models';

export const getModels = async (
  page: number,
  perPage: number,
  searchBy: string,
  supplierId: number | null,
  categoryId: number | null
): Promise<any> => {
  const url = `${BASE_URL}`;
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    search_term: searchBy,
    supplier_id: supplierId,
    category_id: categoryId,
  };
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getModelById = async (modelId: number): Promise<any> => {
  const url = `${BASE_URL}/${modelId}`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const getQuestions = async (modelId: string): Promise<any> => {
  const url = `${BASE_URL}/${modelId}/questions`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const getVariables = async (modelId: string): Promise<any> => {
  const url = `${BASE_URL}/${modelId}/variables`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export interface IQueryResponseDTO {
  id: number;
  general: IModelGeneral;
  images: Record<string, IImageSection>;
  is_using_supplier_system_discounts: boolean;
  pricing: Record<string, object>;
  questions: Record<string, object>;
  round_pricing?: boolean;
  version_number: number;
  extra_price_question_data?: IExtraPriceQuestionData;
  bom_items?: Record<string, object>;
  conn_lead_mpr: string | null;
  conn_lead_time: number | null;
  supplier_id: number | null;
}

export const queryModel = async (
  modelId: string,
  queryPayload: object,
  lang: string,
  taxRate?: number,
  testConfiguration?: ITestCompanyModelConfiguration | null,
  editVersionId?: number,
  includeBomItems?: boolean,
  pricing?: Record<string, any>
): Promise<IQueryResponseDTO> => {
  const cleanedPricing = cleanPricingQueryData(pricing);
  const taxRatePresent = taxRate == 0 || taxRate;
  const url = `${BASE_URL}/${modelId}/query`;
  const { data } = await apiClient.post<any>(url, {
    ...queryPayload,
    language_code: lang,
    ...(taxRatePresent && { tax_rate: taxRate }),
    ...(taxRatePresent &&
      !!testConfiguration && {
        test_company_model_configuration: testConfiguration,
      }),
    ...(editVersionId && { edit_version_id: editVersionId }),
    ...(includeBomItems && { include_bom_items: includeBomItems }),
    ...(cleanedPricing && { pricing: cleanedPricing }),
  });

  return data.data;
};

export const getDealerPanelModelQuestions = async (
  modelId: number
): Promise<any> => {
  const { data } = await apiClient.get<any>(
    `${BASE_URL}/${modelId}/dealer-panel`
  );
  return data.data;
};

export const getVersions = async (
  id: string,
  page: number,
  perPage: number,
  sortBy: string,
  sortDirection: SortOrder
): Promise<any> => {
  const url = `${BASE_URL}/${id}/versions`;
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    sort_order: sortDirection,
    sort_by: sortBy,
  };
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getRecentlyViewedModels = async (
  page: number,
  perPage: number,
  searchBy: string,
  supplierId: number | null,
  categoryId: number | null,
  archived?: boolean
): Promise<any> => {
  const url = `${BASE_URL}/recently-viewed`;
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    search_term: searchBy,
    supplier_id: supplierId,
    category_id: categoryId,
    archived,
  };
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getFavoritesModels = async (
  page: number,
  perPage: number,
  searchBy: string,
  supplierId: number | null,
  categoryId: number | null,
  archived?: boolean
): Promise<any> => {
  const url = `${BASE_URL}/favorites`;
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    search_term: searchBy,
    supplier_id: supplierId,
    category_id: categoryId,
    archived,
  };
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getCustomModels = async (
  page: number,
  perPage: number,
  searchBy: string,
  supplierId: number | null,
  categoryId: number | null,
  archived?: boolean
): Promise<any> => {
  const url = `${BASE_URL}/custom`;
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    search_term: searchBy,
    supplier_id: supplierId,
    category_id: categoryId,
    archived,
  };
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};
