import { IQuestion, QuestionType } from 'types/Question.types';
import {
  AdditionalMargin,
  ClearButton,
  Column,
  ContainerGrayBottomBorder,
  FormItemContainer,
  QuestionName,
  QuestionNameAsteriskInfoRow,
  RedAsterisk,
  RedAsteriskWrapper,
  TooltipWrapper,
} from './QuestionRow.styled';
import Tooltip from 'components/Tooltip/Tooltip';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { Info, X } from '@phosphor-icons/react';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import FormItem from '../FormItem/FormItem';
import { FormikContextType, useFormikContext } from 'formik';
import { useClearFormItemValue } from './hooks';

interface IQuestionRowProps {
  question: IQuestion;
  hasValue: boolean;
  hasImages: boolean;
  showBorder: boolean;
  questionPrice: number | undefined;
}

export const QuestionRow = ({
  question,
  hasValue,
  hasImages,
  showBorder,
  questionPrice,
}: IQuestionRowProps) => {
  const clearFormItemValue = useClearFormItemValue();

  const context: FormikContextType<any> = useFormikContext();

  return question.hidden ? null : (
    <ContainerGrayBottomBorder
      showBorder={showBorder}
      allowClearField={question.type !== QuestionType.BOOLEAN && hasValue}
    >
      <FormItemContainer hasValue={hasValue}>
        <Column>
          <QuestionNameAsteriskInfoRow>
            <QuestionName>
              {capitalizeFirstLetter(question.name).replace('_', ' ')}
            </QuestionName>
            {question.required && !hasValue && (
              <RedAsteriskWrapper>
                <RedAsterisk>*</RedAsterisk>
              </RedAsteriskWrapper>
            )}
            {question.description && (
              <TooltipWrapper>
                <Tooltip content={question.description}>
                  {question.description ? (
                    <Icon svg={Info} size={20} color={COLORS.GREEN} />
                  ) : null}
                </Tooltip>
              </TooltipWrapper>
            )}
          </QuestionNameAsteriskInfoRow>
          <AdditionalMargin />
        </Column>
        <FormItem
          question={question}
          hasImages={hasImages}
          questionPrice={questionPrice}
        />
      </FormItemContainer>
      {question.type !== QuestionType.BOOLEAN && (
        <ClearButton>
          <Icon
            tabIndex={'-1'}
            svg={X}
            color={COLORS.RED_100}
            onClick={() =>
              clearFormItemValue(
                context.setFieldValue,
                question,
                question.initialKey
              )
            }
          />
        </ClearButton>
      )}
    </ContainerGrayBottomBorder>
  );
};
