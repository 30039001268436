import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  color: string;
  imageLoaded: boolean;
}

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ImageMedium = styled.img`
  width: 0;
  height: 0;
  border-radius: 5rem;
  opacity: 0;
  ${(props: StyledProps) =>
    props.color &&
    css`
      background: ${(props: StyledProps) => props.color};
    `};
  ${(props: StyledProps) =>
    props.imageLoaded &&
    css`
      opacity: 1;
      max-width: 75rem;
      width: 100%;
      height: 100%;
      object-fit: contain;

      ${respondTo.smallTablet`
        width: 50%;
        height: 50%;
      `}
    `};
`;
