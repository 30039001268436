export type Level = 'FIRST' | 'SECOND' | 'THIRD';

export const upLevel = (currentLevel: Level): Level => {
  switch (currentLevel) {
    case 'FIRST':
      return 'SECOND';
    case 'SECOND':
      return 'THIRD';
    case 'THIRD':
      return 'THIRD'; // cap at maximum
  }
};
