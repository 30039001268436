import { CSSProperties } from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
import { COLORS } from '../../assets/styled/colors';
import {
  paddingXs,
  gapSm,
  borderRadiusSm,
  paddingMd,
  paddingSm,
} from 'assets/styled';
import Icon from 'components/Icon/Icon';
import { SymbolPlacementEnum } from './PriceInput/PriceInput';

interface StyledProps {
  search: boolean;
  height: string;
  width: string;
  wrapperStyles?: CSSProperties;
  isError: boolean;
  iconPlacement: SymbolPlacementEnum;
  countArrows?: boolean;
  disabled?: boolean;
  style: CSSProperties;
  hasError?: boolean;
}

interface IInputLabel {
  type: string;
}

export const Container = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  position: relative;
  ${({ wrapperStyles }: StyledProps) => wrapperStyles}
`;

export const InputLabel = styled.label`
  margin-bottom: 10rem;
  align-self: flex-start;
  font-weight: 700;
  font-size: 16rem;
  line-height: 16rem;
  color: ${COLORS.BLACK};

  ${({ type }: IInputLabel) =>
    type === 'file' &&
    css`
      margin-bottom: 0;
      display: flex;
      align-items: center;
      border: 1rem solid ${COLORS.BORDER};
      background-color: ${COLORS.GRAY_100};
      height: 100%;
      padding: 10rem;
      cursor: pointer;
    `}
`;

export const StyledInput = styled.input`
  height: ${(props: StyledProps) => props.height || '40rem'};
  border: none;
  border: 1rem solid ${COLORS.BORDER};
  background-color: ${COLORS.WHITE};
  border-radius: 20rem;
  font-size: 16rem;
  flex-grow: 0;
  width: ${(props: StyledProps) => props.width};
  padding-left: 15rem;
  outline: none !important;
  transition: all 0.3s;
  color: ${COLORS.BLACK};

  ${(props: StyledProps) =>
    props.countArrows &&
    css`
      padding-right: 30rem;
    `}

  :focus {
    border: 1rem solid ${COLORS.GREEN};
    box-shadow: 3rem 3rem 5rem ${COLORS.BORDER};
  }

  :hover {
    border: 1rem solid ${COLORS.GREEN};
    background-color: ${COLORS.FORM_ELEMENT_HOVER};
  }

  ${(props: StyledProps) =>
    props.isError
      ? css`
          border: 1rem solid ${COLORS.RED_100};
        `
      : css`
          border: 1rem solid ${COLORS.BORDER};
        `}

  ::placeholder {
    font-size: 14rem;
    line-height: 21rem;
  }

  &:disabled {
    background-color: ${COLORS.GRAY_100};
    cursor: not-allowed;
  }

  ${({ type }: IInputLabel) => {
    if (type !== 'file') return;
    return css`
      display: none;
    `;
  }}

  ${(props: StyledProps) =>
    props.search &&
    css`
      background-color: ${COLORS.WHITE};
      color: ${COLORS.BLACK};
      :focus {
        border: 1rem solid ${COLORS.BLACK} !important;
      }
    `}


  ${(props: StyledProps) => {
    return (
      props.iconPlacement === SymbolPlacementEnum.BEFORE &&
      css`
        padding-left: ${paddingMd};
        padding-right: ${paddingSm};
      `
    );
  }}

  ${({ style }: StyledProps) => style}
`;

export const StyledTextareaInput = styled.textarea`
  width: 100%;
  height: ${(props: StyledProps) => props.height || '150rem'};
  font-family: inherit;
  ${(props: StyledProps) =>
    props.isError
      ? css`
          border: 1rem solid ${COLORS.RED_100};
        `
      : css`
          border: 1rem solid ${COLORS.BORDER};
        `}
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  flex-grow: 0;
  padding: ${paddingXs};
  gap: ${gapSm};
  transition: all 0.3s;
  resize: none;
  outline: none !important;
  color: ${COLORS.BLACK};
  ${({ type }: IInputLabel) => {
    if (type !== 'file') return;
    return css`
      display: none;
    `;
  }}

  :focus {
    box-shadow: 0rem 2rem 2rem 0rem ${COLORS.WHITE};
    border: 1rem solid ${COLORS.GREEN};
  }

  :hover {
    border: 1rem solid ${COLORS.GREEN};
    background-color: ${COLORS.GRAY_300};
  }

  &:disabled {
    background-color: ${COLORS.GRAY_100};
    cursor: not-allowed;
  }

  ${(props: StyledProps) =>
    props.search &&
    css`
      background-color: ${COLORS.WHITE};
      color: ${COLORS.BLACK};
      :focus {
        border: 1rem solid ${COLORS.BLACK} !important;
      }
    `}

  ${(props: StyledProps) =>
    props.isError
      ? css`
          border: 1rem solid ${COLORS.RED_100};
        `
      : css`
          border: 1rem solid ${COLORS.BORDER};
        `}
`;

export const ErrorMessage = styled.div`
  font-weight: 400;
  font-size: 12rem;
  line-height: 10rem;
  color: ${COLORS.RED_100};
  padding-top: 10rem;
`;

export const StyledIcon = styled(Icon)`
  width: 100%;
  height: 100%;
`;

export const IconWrap = styled.div`
  width: 15rem;
  height: 100%;
  position: absolute;

  ${(props: StyledProps) =>
    props.iconPlacement === SymbolPlacementEnum.BEFORE
      ? css`
          left: 10rem;
        `
      : css`
          right: 10rem;
        `}

  ${(props: StyledProps) =>
    props.hasError
      ? css`
          top: -9rem;
        `
      : css`
          top: auto;
        `}
`;
