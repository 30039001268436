import { queryClient } from 'index';
import { RoutesConfig } from 'navigation/routes';
import i18n, { FALLBACK_LOCALE } from 'providers/i18n/i18n';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { getToastErrorMessage, LogyxError } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getCustomModels } from 'services/ModelService';
import { createCustomModel } from 'services/SupplierService';
import { useNavigateWithQuery } from 'utils/hooks/useNavigateWithQuery';

export const useCreateCustomModel = (supplierId: number) => {
  const { t } = useTranslation();
  const navigate = useNavigateWithQuery();
  return useMutation(
    () => {
      return createCustomModel(supplierId);
    },
    {
      onSuccess: (data) => {
        toast.success(t('New custom model created'));
        queryClient.invalidateQueries([ReactQueryKeys.GET_CUSTOM_MODELS]);
        navigate(
          `${RoutesConfig.Builder.fullPath.replace(':id', data.version_id)}`
        );
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.CREATE_CUSTOM_MODEL,
    }
  );
};

export const useGetCustomModels = (
  page: number,
  perPage: number,
  searchBy: string,
  supplierId: number | null,
  categoryId: number | null,
  archived?: boolean,
  isEnabled = true
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_CUSTOM_MODELS,
      page,
      perPage,
      searchBy,
      supplierId,
      categoryId,
      archived,
      i18n.resolvedLanguage || FALLBACK_LOCALE,
    ],
    queryFn: () => {
      return getCustomModels(
        page,
        perPage,
        searchBy,
        supplierId,
        categoryId,
        archived
      );
    },
    enabled: isEnabled,
  });
