import { ColorOption } from 'components/Select/components/Color/ColorOption';
import { ColorSingleValue } from 'components/Select/components/Color/ColorSingleValue';
import { ImageLargeOption } from 'components/Select/components/ImageLarge/ImageLargeOption';
import { ImageLargeSingleValue } from 'components/Select/components/ImageLarge/ImageLargeSingleValue';
import { ImageSmallOption } from 'components/Select/components/ImageSmall/ImageSmallOption';
import { ImageSmallSingleValue } from 'components/Select/components/ImageSmall/ImageSmallSingleValue';
import ClearIndicator from 'components/Select/components/CustomClearIndicator/CustomClearIndicator';
import { IOption } from 'components/Select/type';
import { IQuestion } from 'types/Question.types';
import { DropdownType } from './constants';
import { DefaultOption } from 'components/Select/components/Default/DefaultOption';
import { DefaultSingleValue } from 'components/Select/components/Default/DefaultSingleValue';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import { ImageMediumOption } from 'components/Select/components/ImageMedium/ImageMediumOption';
import { ImageMediumSingleValue } from 'components/Select/components/ImageMedium/ImageMediumSingleValue';

export const getSelectOptionsFromQuestion = (
  question: IQuestion
): IOption[] => {
  const dropdownType: DropdownType = getDropdownTypeFromListQuestion(question); // Used for injecting additional data into select options
  const selectOptions: IOption[] = [];

  const indexOfFirstInputInOutputs = Object.keys(question.outputs).indexOf(
    question.inputs[0]
  ); // Options element at this index is going to be used as label and value for the select options

  const indexOfPriceInOutputs = Object.keys(question.outputs).findIndex((key) =>
    ['price', 'Price'].includes(key.toLowerCase())
  );

  const indexOfColorIdInOutputs = Object.keys(question.outputs).findIndex(
    (key) => ['colorid'].includes(key.toLowerCase())
  );

  let index = 0;
  for (const questionOption of question.options) {
    let selectOption: IOption = {
      value: questionOption[indexOfFirstInputInOutputs],
      label:
        questionOption[indexOfFirstInputInOutputs] +
        (indexOfPriceInOutputs !== -1
          ? ` (${questionOption[indexOfPriceInOutputs]})`
          : '') +
        (indexOfColorIdInOutputs !== -1
          ? ` (${questionOption[indexOfColorIdInOutputs]})`
          : ''),
      price: questionOption[indexOfPriceInOutputs || 0],
      key: questionOption[0],
      pwId: `Option_${question.initialKey}_${index}`,
      pwIdLabel: `Option_${question.initialKey}_${questionOption[0]}`,
    };
    selectOption = injectDataIntoOptionBasedOnDropdownType(
      selectOption,
      dropdownType,
      questionOption,
      Object.keys(question.outputs)
    );

    selectOptions.push(selectOption);
    index++;
  }

  return selectOptions;
};

const injectDataIntoOptionBasedOnDropdownType = (
  resultOption: IOption,
  dropdownType: DropdownType,
  option: any[],
  outputKeys: string[]
) => {
  switch (dropdownType) {
    case DropdownType.DEFAULT:
      break;
    case DropdownType.COLOR: {
      const indexOfSmallImageInOutputs = outputKeys.findIndex((key) =>
        ['small_image'].includes(key.toLowerCase())
      );
      resultOption['color_small_image'] = option[indexOfSmallImageInOutputs];
      break;
    }
    case DropdownType.SMALL_IMAGE: {
      const indexOfSmallImageKeyInOutputs = outputKeys.findIndex(
        (key) => key.toLowerCase() === 'small_image'
      );
      resultOption['small_image'] = option[indexOfSmallImageKeyInOutputs];
      break;
    }
    case DropdownType.MEDIUM_IMAGE: {
      const indexOfLargeImageKeyInOutputs = outputKeys.findIndex(
        (key) => key.toLowerCase() === 'medium_image'
      );
      resultOption['medium_image'] = option[indexOfLargeImageKeyInOutputs];
      break;
    }
    case DropdownType.LARGE_IMAGE: {
      const indexOfLargeImageKeyInOutputs = outputKeys.findIndex(
        (key) => key.toLowerCase() === 'large_image'
      );
      resultOption['large_image'] = option[indexOfLargeImageKeyInOutputs];
      break;
    }
  }
  return resultOption;
};

export const getTooltipText = (
  optionLabels: string[],
  optionValues: string[],
  t: (str: string) => string
) => {
  let tooltipText = '';
  for (let i = 0; i < optionLabels.length; i++) {
    tooltipText += `${t(capitalizeFirstLetter(optionLabels[i]))}:  ${
      optionValues[i]
    }`;
    if (i < optionLabels.length - 1) {
      tooltipText += ', ';
    }
  }
  return tooltipText;
};

export const getDropdownTypeFromListQuestion = (
  question: IQuestion
): DropdownType => {
  const outputKeys = Object.keys(question.outputs);
  for (const outputKey of outputKeys) {
    if (['Color', 'color'].includes(outputKey)) {
      return DropdownType.COLOR;
    }
    if (outputKey === 'small_image') {
      return DropdownType.SMALL_IMAGE;
    }
    if (outputKey === 'large_image') {
      return DropdownType.LARGE_IMAGE;
    }
  }
  return DropdownType.DEFAULT;
};

export const getComponentsBasedOnDropdownType = (
  dropdownType: DropdownType
) => {
  switch (dropdownType) {
    case DropdownType.DEFAULT:
      return {
        Option: DefaultOption,
        SingleValue: DefaultSingleValue,
        ClearIndicator: undefined, // Added clear button for every form item
      };
    case DropdownType.COLOR:
      return {
        Option: ColorOption,
        SingleValue: ColorSingleValue,
        ClearIndicator: ClearIndicator,
      };
    case DropdownType.SMALL_IMAGE:
      return {
        Option: ImageSmallOption,
        SingleValue: ImageSmallSingleValue,
        ClearIndicator: ClearIndicator,
      };
    case DropdownType.MEDIUM_IMAGE:
      return {
        Option: ImageMediumOption,
        SingleValue: ImageMediumSingleValue,
        ClearIndicator: ClearIndicator,
      };
    case DropdownType.LARGE_IMAGE:
      return {
        Option: ImageLargeOption,
        SingleValue: ImageLargeSingleValue,
        ClearIndicator: ClearIndicator,
      };
    default:
      return { ClearIndicator: ClearIndicator };
  }
};

export const questionHasValue = (value: any) => {
  return Array.isArray(value) ? !!value.length : value !== undefined;
};
