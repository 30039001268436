import { FormikContextType, getIn } from 'formik';
import { IQuestion, QuestionType } from 'types/Question.types';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import Input from '../Input';
import { Container, InputWrapper, Overlay } from './OnBlurInputFormik.styled';
import { useValidateTextAfterValueChange } from './hooks';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setTouchedQuestionName } from 'store/Form/actions/form';
import { useSetManuallyChangeQuestionName } from 'pages/ConfigurePage/ConfigureForm/hooks';

interface IOnBlurInputFormikTextProps {
  question: IQuestion;
  context: FormikContextType<any>;
  isTouched: boolean;
  pwId: string;
}

const OnBlurInputFormikText = ({
  question,
  context,
  isTouched,
  pwId,
}: IOnBlurInputFormikTextProps) => {
  const dispatch = useDispatch();
  // isFocused and valueAtTimeOfFocus are used to handle cases where users input values in OnBlur form items,
  // and proceed to click Submit button without triggering the onBlur (skipping the last required query request)
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [valueAtTimeOfFocus, setValueAtTimeOfFocus] = useState<string | null>();
  const prevFormikValueRef = useRef(getIn(context.values, question.initialKey));

  const [textValue, setTextValue] = useState<string>(
    question.type === QuestionType.TEXT
      ? getIn(context.values, question.initialKey)
      : ''
  );

  const errorMessage = useValidateTextAfterValueChange(
    textValue,
    question,
    context
  );

  useEffect(() => {
    const formikValue = getIn(context.values, question.initialKey);

    if (formikValue !== prevFormikValueRef.current) {
      prevFormikValueRef.current = formikValue;
      setTextValue(formikValue ?? '');
    }
  }, [context.values]);

  const isSmallInput = !!(question?.max && Number(question.max) <= 50);
  const setManuallyChangeQuestionName =
    useSetManuallyChangeQuestionName(dispatch);

  return (
    <Container>
      {isFocused && valueAtTimeOfFocus !== textValue && <Overlay />}
      <InputWrapper>
        <Input
          pwId={pwId}
          defaultValue={getIn(context.values, question.initialKey)}
          value={textValue}
          isTextArea={!isSmallInput}
          placeholder={capitalizeFirstLetter(question.name)}
          onChange={(e) => {
            const value = e.target.value;
            setTextValue(value);
          }}
          onBlur={(e) => {
            const value = e.target.value.trim();

            // Ensure Redux is updated only when the value actually changes.
            // This prevents unnecessary dispatches while still capturing deletions
            // (e.g., when a user selects and clears the input).
            if (value !== getIn(context.values, question.initialKey)) {
              setManuallyChangeQuestionName(value, question.initialKey);
            }

            if (!errorMessage) {
              context.setFieldValue(question.initialKey, value);
              context.handleBlur(e);
            }
            dispatch(setTouchedQuestionName(question.initialKey));
            setIsFocused(false);
            setValueAtTimeOfFocus(null);
          }}
          height={isSmallInput ? '45rem' : '120rem'}
          wrapperStyles={{ width: '100%' }}
          width={'100%'}
          isError={isTouched && !!errorMessage} // for showing red border around input
          onFocus={() => {
            setIsFocused(true);
            setValueAtTimeOfFocus(textValue);
          }}
        />
      </InputWrapper>
    </Container>
  );
};

export default OnBlurInputFormikText;
