import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { Modal } from 'components/Modals/Modal/Modal';
import {
  Column,
  Container,
  Label,
  RowSpaceBetween,
  Title,
} from './ExtraPriceQuestionModal.styled';
import Tooltip from 'components/Tooltip/Tooltip';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { useTranslation } from 'react-i18next';
import Input from 'components/Input/Input';
import { ExtraPriceQuestionSelect } from './ExtraPriceQuestionSelect/ExtraPriceQuestionSelect';
import Button from 'components/Button/Button';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { IExtraPriceQuestionOption } from 'types/Configuration.types';
import { Level } from 'components/Modals/Modal/type';

export interface IExtraPriceQuestionModalProps {
  modelName: string;
  isOpen: boolean;
  onClose: () => void;
  isExtraPriceQuestionActive: boolean;
  setIsExtraPriceQuestionActive: Dispatch<SetStateAction<boolean>>;
  extraPriceQuestionName: string;
  setExtraPriceQuestionName: Dispatch<SetStateAction<string>>;
  extraPriceQuestionOptions: IExtraPriceQuestionOption[];
  setExtraPriceQuestionOptions: Dispatch<
    SetStateAction<IExtraPriceQuestionOption[]>
  >;
  level: Level;
}

export function ExtraPriceQuestionModal({
  modelName,
  isOpen,
  onClose,
  isExtraPriceQuestionActive,
  setIsExtraPriceQuestionActive,
  extraPriceQuestionName,
  setExtraPriceQuestionName,
  extraPriceQuestionOptions,
  setExtraPriceQuestionOptions,
  level,
}: IExtraPriceQuestionModalProps) {
  const { isPhone } = useBreakpointFlag();
  const { t } = useTranslation();

  useEffect(() => {
    if (!extraPriceQuestionOptions?.length || !extraPriceQuestionName) {
      setIsExtraPriceQuestionActive(false);
    }
  }, [extraPriceQuestionOptions, extraPriceQuestionName]);

  return (
    <Modal
      level={level}
      isOpen={isOpen}
      onClose={() => onClose()}
      modalStyle={{
        margin: 'auto',
        width: isPhone ? '90%' : '500rem',
        minWidth: isPhone ? '90%' : 'auto',
        position: 'fixed',
      }}
    >
      <Container>
        <Title>
          {t('Manage extra price question for {{MODEL_NAME}}').replace(
            '{{MODEL_NAME}}',
            modelName
          )}
        </Title>
        <RowSpaceBetween>
          <Label>{t('Is extra price question active')}</Label>
          <Tooltip
            content={
              !extraPriceQuestionName || !extraPriceQuestionOptions.length
                ? t(
                    'Both the question name and options must be defined to activate the extra price question.'
                  )
                : undefined
            }
          >
            <ToggleSwitch
              disabled={
                !extraPriceQuestionName || !extraPriceQuestionOptions.length
              }
              isChecked={isExtraPriceQuestionActive}
              onClick={() =>
                setIsExtraPriceQuestionActive(!isExtraPriceQuestionActive)
              }
              size="large"
            />
          </Tooltip>
        </RowSpaceBetween>
        <Column>
          <Label>{t('Question name')}</Label>
          <Input
            value={extraPriceQuestionName}
            placeholder={t('Extra price question name')}
            onChange={(e: any) => setExtraPriceQuestionName(e.target.value)}
            wrapperStyles={{ width: '100%' }}
          />
        </Column>
        <Column>
          <Label>{t('Options')}</Label>
          <ExtraPriceQuestionSelect
            level={level} // AddEditExtraPriceQuestionOptionModal should be same level as ExtraPriceQuestionModal as they have the same dimensions
            options={extraPriceQuestionOptions}
            setExtraPriceQuestionOptions={setExtraPriceQuestionOptions}
            placeholder={t('Default option label')}
          />
        </Column>
        <Tooltip
          content={t(
            'After configuring the extra price question and closing this modal, you need to press <b>Apply</b> to save the changes'
          )}
        >
          <Button
            secondary
            label={t('Close')}
            onClick={onClose}
            width="200rem"
          />
        </Tooltip>
      </Container>
    </Modal>
  );
}
