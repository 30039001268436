import { IShippingAddressDTO } from 'pages/CheckoutPage/types';
import apiClient from './api/apiService';

const BASE_URL = '/v1/shipping-addresses';

export const createShippingAddress = async (
  dto: IShippingAddressDTO
): Promise<any> => {
  const url = `${BASE_URL}`;
  const { data } = await apiClient.post<any>(url, dto);

  return data.data;
};

export const editShippingAddress = async (
  id: number,
  dto: IShippingAddressDTO
): Promise<any> => {
  const url = `${BASE_URL}/${id}`;
  const { data } = await apiClient.patch<any>(url, dto);

  return data.data;
};

export const deleteShippingAddress = async (id: number): Promise<any> => {
  const url = `${BASE_URL}/${id}`;
  const { data } = await apiClient.delete<any>(url);

  return data.data;
};
