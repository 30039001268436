import { COLORS } from 'assets/styled';
import styled from 'styled-components';
import { css } from 'styled-components';

interface StyledProps {
  color: string;
  isSmallPhone: boolean;
}

export const Container = styled.div`
  padding: 20rem;
  width: ${(props: StyledProps) => (props.isSmallPhone ? '248rem' : '298rem')};
  display: flex;
  flex-direction: column;
  gap: 10rem;
  padding-bottom: 0;
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const SecondRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const OptionNameLabel = styled.label`
  color: ${COLORS.BLACK};
  font-size: 14rem;
`;
export const EditLabel = styled.label`
  color: ${COLORS.GREEN};
  cursor: pointer;
  font-size: 14rem;
`;
export const OptionValueLabel = styled.label`
  color: ${COLORS.RED};
  font-size: 14rem;
  color: ${(props: StyledProps) => props.color || COLORS.BLACK};
`;

export const Line = styled.div`
  border-bottom: 1rem solid ${COLORS.BORDER};
  display: flex;
  width: 100%;
`;

export const EditLabelIconWrapper = styled.div`
  display: flex;
  gap: 5rem;
  cursor: pointer;
`;

export const checkboxSpanStyles = (isSmallPhone: boolean) => css`
  ${isSmallPhone &&
  `
    text-align: end;
    min-width: 70rem;
  `}
`;

export const checkboxWrapperStyles = (isSmallPhone: boolean) => css`
  ${isSmallPhone &&
  `
    width: min-content;
    background-color: red;
  `}
`;
