import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const EntityList = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  border: 1rem solid ${COLORS.GREEN_DISABLED};
  border-radius: 10rem;
  overflow-y: scroll;
  max-height: 50vh;
  padding-left: 8rem;
  padding-bottom: 1rem;
  /* CSS to make scrollbars always visible in webkit-based browsers */
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  ${respondTo.bigPhone`
    width: 100%;
    max-width: 100%;
  `}
`;

export const InputWrapper = styled.div`
  width: 300rem;
  margin-bottom: 20rem;

  ${respondTo.bigPhone`
    max-width: 100%;
    width: 100%;
  `}
`;

export const EntityRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 500rem;
  border-radius: 10rem;
  font-size: 24rem;
  padding: 20rem;
  color: ${COLORS.BLACK};
  text-align: center;

  cursor: pointer;
  &:hover {
    background-color: ${COLORS.BACKGROUND};
  }

  ${respondTo.midTablet`
    font-size: 20rem;
    padding: 15rem;
  `};

  ${respondTo.smallTablet`
    width: 100%;
  `}

  ${respondTo.bigPhone`
    width: 100%;
    max-width: 100%;
    font-size: 18rem;
    text-align: center;
  `};
`;

export const HorizontalLine = styled.div`
  height: 0;
  width: 100%;
  border-bottom: 1px solid ${COLORS.GRAY_100};
`;

export const NoContentLabel = styled.label`
  width: 100%;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;
