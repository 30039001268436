import styled, { css } from 'styled-components';
import { COLORS, paddingLg } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import { respondTo } from 'assets/styled/breakpoints';
import { hoverLineOut } from 'assets/styled/commonStyles';

interface StyledProps {
  isExpanded: boolean;
  isPricingSectionVisible: boolean;
  showRedBorder: boolean;
}

export const SubHeaderContainer = styled.div`
  width: 100%;
  padding: 0 ${paddingLg};
  border-top: 1rem solid ${COLORS.BORDER};
  background-color: ${COLORS.WHITE};
  transition: height ease 200ms;

  ${respondTo.bigTablet`
    padding: 0 30rem;
  `}

  ${(props: StyledProps) =>
    props.isExpanded
      ? css`
          height: 75rem;

          ${respondTo.midTablet`
            height: 150rem;
            padding: 10rem 20rem;
          `}

          ${respondTo.smallPhone`
            height: 200rem;
            padding: 10rem;
          `}
        `
      : css`
          height: 0;
          border-top: 0;
        `}

  ${respondTo.midTablet`
    position: fixed;
    bottom: 0;
    z-index: 2;
  `}
`;

export const SubHeaderContentWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: opacity ease 100ms;
  ${(props: StyledProps) =>
    props.isExpanded
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `}

  ${respondTo.midTablet`
    flex-wrap: wrap;
    justify-content: center;
  `}
`;

export const PricingContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10rem;
  flex-direction: row;
`;
export const TotalPrice = styled.div`
  font-size: 30rem;
  font-weight: 600;
  color: ${COLORS.GREEN};
  line-height: 40.85rem;

  ${respondTo.smallLaptop`
    font-size: 26rem;
  `}

  ${respondTo.bigTablet`
    font-size: 24rem;
  `}

   ${respondTo.bigPhone`
    font-size: 20rem;
  `}
`;

export const ModelNameLabel = styled.label`
  font-size: 25rem;
  line-height: 30rem;
  font-weight: 400;
  color: ${COLORS.BLACK};

  ${respondTo.smallLaptop`
    font-size: 22rem;
  `}

  ${respondTo.bigTablet`
    font-size: 20rem;
  `}

  ${respondTo.midTablet`
    font-size: 18rem;
  `}
`;

export const AbsolutePricingWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  ${respondTo.midTablet`
    left: auto;
    position: static;
    width: 50%;
    transform: translateX(0);
  `}
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10rem;
  max-width: 600rem;
  width: 100%;

  ${respondTo.bigLaptop`
    max-width: 480rem;
  `}

  ${respondTo.horizontalTablet`
    max-width: 400rem;
  `}

  ${respondTo.midTablet`
    width: 100%;
    max-width: 100%;
    justify-content: center;
  `}
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 30rem;
  justify-content: center;

  ${respondTo.horizontalTablet`
    gap: 15rem;
  `}

  ${respondTo.bigTablet`
    gap: 10rem;
  `}

  ${respondTo.midTablet`
    max-width: 100%;
    width: 100%;
  `}

  ${respondTo.smallPhone`
    flex-wrap: wrap;
  `}
`;

export const QuantityWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;

  ${respondTo.smallPhone`
    width: 100%;
    justify-content: center;
  `}
`;

export const QuantityLabel = styled.span`
  display: block;
  font-size: 16rem;
  color: ${COLORS.BLACK};
`;

export const InfoIconWrapper = styled.div`
  height: 40rem;
`;

export const StyledIcon = styled(Icon)`
  ${respondTo.midTablet`
    transform: rotate(180deg);
  `}

  ${(props: StyledProps) =>
    props.isPricingSectionVisible &&
    css`
      transform: rotate(180deg);

      ${respondTo.midTablet`
        transform: rotate(0);
      `}
    `};
`;

export const EditTestConfigLabel = styled.label`
  text-align: center;
  font-size: 16rem;
  font-weight: 300;
  cursor: pointer;
  color: ${COLORS.GREEN};
  ${hoverLineOut};
  ${respondTo.midTablet`
    text-align: center;
  `}
`;
