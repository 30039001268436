import {
  AbsoluteWrapper,
  Container,
  Logo,
  SelectSupplierLabel,
  AbsoluteItem,
  Content,
  LogoWrapper,
} from './SupplierSelectPage.styled';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ISupplier } from 'types/Supplier.types';
import LogyxLogoSVG from 'assets/icons/logyxLogomark.svg';
import LanguageSwitcher from 'components/LanguageSwitcher/LanguageSwitcher';
import { useEffect, useState } from 'react';
import { setSupplier } from 'store/User/actions/user';
import { useGetSuppliersInfinite } from 'pages/ModelsPage/hooks';
import { SUPPLIERS_PER_PAGE } from './constants';
import ToggleDarkMode from 'components/ToggleDarkMode/ToggleDarkMode';
import { useDebounce } from 'utils/hooks/useDebounce';
import { usePageTitle } from 'utils/hooks/usePageTitle';
import ScrollableList from 'components/ScrollableList/ScrollableList';
import { getDataFromPages } from 'utils/getDataFromPages';
import { useSelectEntities } from '../CompanySelectPage/hooks';
import { handleSelectEntities } from '../CompanySelectPage/helpers';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'navigation/routes';
import { setAppMode } from 'store/Common/actions/common';
import { AppMode } from 'store/Common/reducers/common';

const SupplierSelectPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchBySupplier, setSearchBySupplier] = useState<string>('');
  const debouncedSearchBySupplier = useDebounce(searchBySupplier);

  const {
    data: suppliersData,
    isLoading: isLoadingGetSuppliers,
    isFetching: isFetchingGetSuppliers,
    isSuccess: isSuccessGetSuppliers,
    hasNextPage,
    fetchNextPage,
  } = useGetSuppliersInfinite(SUPPLIERS_PER_PAGE, debouncedSearchBySupplier);

  const suppliers: ISupplier[] = getDataFromPages(
    suppliersData?.pages,
    'suppliers'
  );

  useEffect(() => {
    // If only 1 supplier is available, select it
    if (isSuccessGetSuppliers && debouncedSearchBySupplier === '') {
      const supplierCount = suppliers?.length;
      dispatch(setAppMode(AppMode.SUPPLIER));
      switch (supplierCount) {
        case 1:
          dispatch(setSupplier(suppliers[0]));
          handleSelectEntities(
            dispatch,
            selectEntities,
            undefined,
            suppliers[0].id
          );
          break;
        case 0: {
          navigate(RoutesConfig.CompanySelect.fullPath);
        }
      }
    }
  }, [isSuccessGetSuppliers]);

  usePageTitle('Select supplier');

  const {
    data,
    mutate: selectEntities,
    isLoading: isLoadingSelectEntities,
  } = useSelectEntities();

  useEffect(() => {
    if (data) navigate(RoutesConfig.CompanySelect.fullPath);
  }, [data]);

  return (
    <Container>
      <Content>
        <LogoWrapper>
          <Logo src={LogyxLogoSVG} alt="" />
          <AbsoluteWrapper>
            <AbsoluteItem>
              <ToggleDarkMode />
            </AbsoluteItem>
            <AbsoluteItem>
              <LanguageSwitcher />
            </AbsoluteItem>
          </AbsoluteWrapper>
        </LogoWrapper>
        <SelectSupplierLabel>{t('Select supplier')}</SelectSupplierLabel>

        <ScrollableList
          items={suppliers || []}
          onClick={(supplier: ISupplier) => {
            if (isLoadingSelectEntities) {
              return;
            }
            dispatch(setSupplier(supplier));
            handleSelectEntities(
              dispatch,
              selectEntities,
              undefined,
              supplier.id
            );
          }}
          onSearch={(str: string) => setSearchBySupplier(str)}
          noContentLabel={t('No suppliers found')}
          searchLabel={t('Search suppliers')}
          isLoading={isLoadingGetSuppliers}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetching={isFetchingGetSuppliers}
        />
      </Content>
    </Container>
  );
};

export default SupplierSelectPage;
