import styled from 'styled-components';
import { COLORS, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: center;
  min-height: 700rem;
  max-width: 1400rem;
  width: calc(100% - 30rem);
  border-radius: 20rem;
  background-color: ${COLORS.WHITE};
  padding: ${paddingMd};
  margin-left: 15rem;
  margin-right: 15rem;
  margin-top: 125rem;
  margin-bottom: 30rem;

  ${respondTo.midTablet`
    margin-bottom: 160rem;
  `}

  ${respondTo.smallTablet`
    margin-top: 50rem;
  `}

  ${respondTo.smallPhone`
    margin-bottom: 220rem;
  `}
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${paddingMd};
`;

export const Title = styled.span`
  display: block;
  font-weight: 400;
  font-size: 24rem;
  color: ${COLORS.BLACK};
`;

export const NoContentLabel = styled.label`
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const BackLabel = styled.label`
  margin-left: 5rem;
  font-size: 18rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const AbsoluteBackWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: -30rem;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10rem;
  &:hover {
    ${BackLabel} {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-thickness: 1rem;
    }
  }
`;
