import {
  CategoryNameLabel,
  ChevronImg,
  Container,
  CategoryNameAndChevronWrapper,
  ModelsWrapper,
  FlexWrap,
  ChevronImgWrapper,
  NoContentLabel,
} from './CategoryGrid.styled';
import { useRef, useState } from 'react';
import { IModel } from 'types/Model.types';
import { ICategory } from 'types/Category.types';
import ModelCard from 'components/ModelCard/ModelCard';
import { useLoadNextPage } from 'utils/hooks/useLoadNextPage';
import { CATEGORY_MODELS_PER_PAGE } from './constants';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { useGetModelsInfinite } from 'pages/AllModelsPage/AllModelsGridView/hooks';
import ModelCardSkeleton from 'components/ModelCard/ModelCardSkeleton/ModelCardSkeleton';
import { useTranslation } from 'react-i18next';
import { getDataFromPages } from 'utils/getDataFromPages';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { CaretDown } from '@phosphor-icons/react';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';

interface ICategoryGridProps {
  supplierId: number;
  category: ICategory;
}

const CategoryGrid = ({ supplierId, category }: ICategoryGridProps) => {
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { t } = useTranslation();

  const {
    data: supplierModelsData,
    isFetching: isFetchingSupplierModels,
    hasNextPage,
    fetchNextPage,
  } = useGetModelsInfinite(
    CATEGORY_MODELS_PER_PAGE,
    '',
    supplierId,
    category.id,
    isExpanded
  );

  const models: IModel[] = getDataFromPages(
    supplierModelsData?.pages,
    'models'
  );

  useLoadNextPage(
    loadMoreRef,
    hasNextPage,
    fetchNextPage,
    isFetchingSupplierModels
  );

  const { isDesktop, isBigTablet, isSmallTablet, isSmallPhone } =
    useBreakpointFlag();

  const getSkeletonNumber = () => {
    if (isSmallPhone) {
      return 1;
    } else if (isSmallTablet) {
      return 2;
    } else if (isBigTablet) {
      return 3;
    } else if (isDesktop) {
      return 5;
    } else {
      return 3;
    }
  };

  return (
    <Container>
      <CategoryNameAndChevronWrapper
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <CategoryNameLabel>{category.name}</CategoryNameLabel>
        <ChevronImgWrapper>
          <ChevronImg isExpanded={isExpanded}>
            <Icon svg={CaretDown} color={COLORS.GREEN} size={30} />
          </ChevronImg>
        </ChevronImgWrapper>
      </CategoryNameAndChevronWrapper>

      <ModelsWrapper>
        <FlexWrap isExpanded={isExpanded}>
          {models.map((model: IModel, index: number) => (
            <ModelCard
              model={model}
              key={`modelrow-${index}`}
              onToggleFavoriteSuccess={() => {
                queryClient.invalidateQueries([
                  ReactQueryKeys.GET_MODELS_INFINITE,
                ]);
                queryClient.invalidateQueries([ReactQueryKeys.GET_MODELS]);
              }}
            />
          ))}
          {isFetchingSupplierModels
            ? Array.from({ length: getSkeletonNumber() }, (_, index) => (
                <ModelCardSkeleton key={index} index={index} />
              ))
            : null}
        </FlexWrap>
        {isExpanded && !isFetchingSupplierModels && !models.length ? (
          <NoContentLabel>
            {t('No models found for this category')}
          </NoContentLabel>
        ) : null}
        <div ref={loadMoreRef} style={{ height: '1px' }} />
      </ModelsWrapper>
    </Container>
  );
};

export default CategoryGrid;
