import { getIndexesOfInputsInOutputs } from 'pages/ConfigurePage/ConfigureForm/helpers';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppMode } from 'store/Common/reducers/common';
import { setModel } from 'store/Model/actions/model';
import { SET_TRIGGER_QUERY_REQUEST_AFTER_FORM_RESET } from 'store/constants';
import { IRootReducerState } from 'store/store';
import { ITestCompanyModelConfiguration } from 'types/Configuration.types';
import { IQuestion, QuestionType } from 'types/Question.types';
import { havePricingValuesChanged } from './helpers';

export const useFormatFormValuesIntoQueryPayload = (
  values: object,
  questionInitialKeysToExcludeInQueryRef: MutableRefObject<string[]>
) => {
  const company = useSelector(
    (state: IRootReducerState) => state.userInfo.company
  );
  const appMode: AppMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );
  // Company id is extracted from the intent when using DealerPanel or Blueprint app modes
  const shouldIncludeCompanyId = [AppMode.COMPANY, AppMode.SUPPLIER].includes(
    appMode
  );
  const payload = {
    questions: {},
    ...(shouldIncludeCompanyId && { company_id: company?.id }),
  };

  const questions: IQuestion[] = useSelector(
    (state: IRootReducerState) => state.modelInfo.questions
  );

  for (const valuesKey of Object.keys(values)) {
    const question = questions.find(
      (question) => question.initialKey === valuesKey
    );

    if (
      !question ||
      questionInitialKeysToExcludeInQueryRef.current.includes(
        question?.initialKey
      )
    ) {
      continue;
    }

    if (values[valuesKey] !== undefined) {
      switch (question.type) {
        case QuestionType.BOOLEAN:
          payload.questions[question.initialKey] = {
            values: [values[valuesKey]],
          };
          break;
        case QuestionType.NUMBER:
          payload.questions[question.initialKey] = {
            values: [
              values[valuesKey] !== null
                ? Number(values[valuesKey])
                : values[valuesKey],
            ],
          };
          break;
        case QuestionType.TABLE: {
          const tablePayloadValues = formatTablePayloadValues(
            question,
            values[valuesKey]
          );
          if (tablePayloadValues.length) {
            payload.questions[question.initialKey] = {
              values: tablePayloadValues,
            };
          }
          break;
        }
        case QuestionType.LIST:
          payload.questions[question.initialKey] = {
            values: Array.isArray(values[valuesKey])
              ? values[valuesKey]
              : [values[valuesKey]],
          };
          break;
        case QuestionType.TEXT:
          payload.questions[question.initialKey] = {
            values: [values[valuesKey]],
          };
          break;
        case QuestionType.RADIO:
          payload.questions[question.initialKey] = {
            values: [values[valuesKey]],
          };
          break;
        case QuestionType.COLOR: {
          payload.questions[question.initialKey] = {
            values: [values[valuesKey]],
          };
          break;
        }
      }
    }
  }

  return payload;
};

export const formatTablePayloadValues = (
  question: IQuestion,
  tableValues: any
) => {
  const formattedTableValues: any[] = [];
  const indexesOfInputsInOutputs: number[] = getIndexesOfInputsInOutputs(
    question.inputs,
    question.outputs
  );
  for (const tableValue of tableValues) {
    const formattedTableValue = [];
    for (const tableValueElementIndex in tableValue) {
      if (indexesOfInputsInOutputs.includes(Number(tableValueElementIndex)))
        formattedTableValue.push(tableValue[tableValueElementIndex]);
    }
    formattedTableValues.push(formattedTableValue);
  }
  return formattedTableValues;
};

// UNUSED
function getChangedQuestionKey(prevValues: any, newValues: any): string | null {
  if (prevValues && newValues) {
    for (const key in newValues) {
      // JSON.stringify is required so it works with arrays
      if (JSON.stringify(prevValues[key]) !== JSON.stringify(newValues[key])) {
        return key;
      }
    }
  }
  return null;
}

export const useValuesDidUpdate = (
  values: any,
  currentModelId: any,
  testConfiguration: ITestCompanyModelConfiguration | null,
  taxRate: number | undefined,
  includeBomItems: boolean,
  pricing: Record<string, any>
): boolean => {
  const dispatch = useDispatch();
  const triggerQueryRequestAfterFormReset = useSelector(
    (state: IRootReducerState) =>
      state.modelInfo.triggerQueryRequestAfterFormReset
  );
  const prevValuesRef = useRef<any>(null);
  const prevTestConfigurationRef = useRef<any>(null);
  const prevTaxRateRef = useRef<any>(null);
  const prevModelIdRef = useRef<any>(null);
  const prevIncludeBomItemsRef = useRef<any>(null);
  const prevPricingRef = useRef<any>(null);

  // Check only changes of custom_amount and custom_discount
  const didPricingValuesChange = havePricingValuesChanged(
    prevPricingRef.current,
    pricing
  );

  if (triggerQueryRequestAfterFormReset) {
    dispatch({
      type: SET_TRIGGER_QUERY_REQUEST_AFTER_FORM_RESET,
      payload: false,
    });
  }

  const didValuesChange =
    currentModelId !== prevModelIdRef.current ||
    JSON.stringify(values) !== JSON.stringify(prevValuesRef.current) ||
    JSON.stringify(testConfiguration) !==
      JSON.stringify(prevTestConfigurationRef.current) ||
    JSON.stringify(taxRate) !== JSON.stringify(prevTaxRateRef.current) ||
    includeBomItems !== prevIncludeBomItemsRef.current ||
    didPricingValuesChange ||
    triggerQueryRequestAfterFormReset;

  if (didValuesChange) {
    prevValuesRef.current = values;
    prevTestConfigurationRef.current = testConfiguration;
    prevTaxRateRef.current = taxRate;
    prevModelIdRef.current = currentModelId;
    prevIncludeBomItemsRef.current = includeBomItems;
    prevPricingRef.current = pricing;
  }

  return didValuesChange;
};

export const useIsEditMode = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isEditModeString = queryParams.get('isEdit');
  return isEditModeString === 'true';
};

export const getModelIdFromPath = () => {
  const queryParams = new URLSearchParams(location.search);
  return queryParams.get('modelId');
};

export const useModelIdChange = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  // Refs to store the previous modelId
  const prevModelIdRef = useRef<string | null>(null);

  // State to store the current modelId
  const [currentModelId, setCurrentModelId] = useState<string | null>(null);

  // State to store the comparison result
  const [hasModelIdChanged, setHasModelIdChanged] = useState<boolean>(false);

  useEffect(() => {
    const modelId = getModelIdFromPath();
    setCurrentModelId(modelId); // Update the current modelId

    // Compare the previous modelId with the current one
    if (prevModelIdRef.current !== modelId) {
      dispatch(setModel({ id: modelId }));
      setHasModelIdChanged(true);
    } else {
      setHasModelIdChanged(false);
    }

    // Update the ref to the current modelId for the next render
    prevModelIdRef.current = modelId;

    // On unmount, reset the state
    return () => {
      setHasModelIdChanged(false);
    };
  }, [location]); // Effect runs only when location changes

  return { hasModelIdChanged, currentModelId };
};

export const useGetTaxRate = (): number | undefined => {
  const appMode: AppMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const taxRateParam = queryParams.get('taxRate');
  const isDealerPanel = appMode === AppMode.DEALER_PANEL;

  if (isDealerPanel && taxRateParam !== null) {
    const parsedTaxRate = parseFloat(taxRateParam);
    if (!isNaN(parsedTaxRate) && parsedTaxRate >= 0 && parsedTaxRate <= 100) {
      return parsedTaxRate;
    }
  }

  return undefined;
};

export const useGetBuilderEditVersionId = (): number | undefined => {
  const appMode: AppMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const editVersionIdParam = queryParams.get('editVersionId');

  if (
    [AppMode.SUPPLIER, AppMode.DEALER_PANEL].includes(appMode) &&
    editVersionIdParam !== null
  ) {
    const parsedEditVersionId = parseFloat(editVersionIdParam);
    if (!isNaN(parsedEditVersionId)) {
      return parsedEditVersionId;
    }
  }

  return undefined;
};
