import {
  SET_CURRENTLY_EDITING_SHOPPING_CART_LINE_DATA,
  SET_MAX_CONN_LEAD_TIME,
  TRIGGER_ADD_TO_SHOPPING_CART,
  TRIGGER_EDIT_CART_ITEM,
} from 'store/constants';

export const triggerAddToShoppingCart = (trigger: boolean) => ({
  type: TRIGGER_ADD_TO_SHOPPING_CART,
  payload: trigger,
});

export const setCurrentlyEditingShoppingCartLineData = (
  id: number | null,
  quantity: number | null
) => ({
  type: SET_CURRENTLY_EDITING_SHOPPING_CART_LINE_DATA,
  payload: { id, quantity },
});

export const triggerEditCartItem = (trigger: boolean) => ({
  type: TRIGGER_EDIT_CART_ITEM,
  payload: trigger,
});

export const setMaxConnLeadTime = (maxConnLeadTime: number) => ({
  type: SET_MAX_CONN_LEAD_TIME,
  payload: maxConnLeadTime,
});
