import Button from 'components/Button/Button';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Modal } from '../Modal/Modal';
import { Level } from '../Modal/type';
import {
  ButtonContainer,
  Container,
  Description,
  Title,
  TitleContainer,
  TopContainer,
} from './InfoModal.styled';

interface InfoModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  buttonClick: () => void;
  buttonText: string;
  description?: string | ReactNode;
  title: string;
  level?: Level;
  disabled?: boolean;
  onCloseAction?: () => void;
}

export const InfoModal = ({
  isOpen,
  setIsOpen,
  buttonClick,
  buttonText,
  description,
  title,
  level = 'FIRST',
  disabled,
  onCloseAction,
}: InfoModalProps) => {
  const handleClose = () => {
    setIsOpen(false);
    onCloseAction && onCloseAction();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      modalStyle={{
        margin: 'auto',
        width: '600rem',
        minWidth: 'auto',
        position: 'fixed',
      }}
      level={level}
    >
      <Container>
        <TopContainer>
          <TitleContainer>
            <Title>{title}</Title>
          </TitleContainer>
          <TitleContainer>
            <Description>
              {typeof description === 'string' ? (
                <p>{description}</p>
              ) : (
                description
              )}
            </Description>
          </TitleContainer>
        </TopContainer>
        <ButtonContainer>
          <Button
            disabled={disabled}
            onClick={buttonClick}
            secondary
            width="200rem"
            label={buttonText}
          />
        </ButtonContainer>
      </Container>
    </Modal>
  );
};
