import {
  COLORS,
  borderRadiusSm,
  marginSm,
  paddingMd,
  paddingSm,
} from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 1400rem;
  width: 100%;
  margin: 50rem 0;
  padding: 0 15rem;
  align-self: center;
  display: flex;

  ${respondTo.smallTablet`
    margin: 30rem 0;
  `}

  ${respondTo.bigPhone`
    margin: 20rem 0;
  `}
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #f6fcf7;
  padding: ${paddingSm};
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  width: 100%;
  align-self: center;

  ${respondTo.bigPhone`
    padding: 10rem;
  `}
`;

export const Title = styled.label`
  font-weight: 600;
  font-size: 30rem;
  line-height: 40rem;
  color: ${COLORS.BLACK};

  ${respondTo.smallLaptop`
    font-size: 26rem;
  `}

  ${respondTo.bigTablet`
    font-size: 24rem;
  `}

  ${respondTo.bigPhone`
    font-size: 20rem;
  `}
`;

export const NoContentLabel = styled.label`
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${marginSm};
  align-self: center;
  align-items: center;
  justify-content: center;

  ${respondTo.smallTablet`
    align-items: stretch;
    padding-bottom: 20rem;
    width: 100%;
    max-width: 100%;
  `}

  ${respondTo.bigPhone`
    flex-direction: column;
  `}
`;

export const ClearShoppingCartWrapper = styled.div`
  position: absolute;
  right: 20rem;
  top: 30rem;
  display: flex;

  ${respondTo.bigPhone`
    top: 20rem;
  `}

  ${respondTo.smallPhone`
    top: 10rem;
  `}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${respondTo.smallPhone`
    padding-top: 20rem;
  `}
`;

export const ClearShoppingCartLabel = styled.label`
  font-size: 17rem;
  line-height: 21rem;
  font-weight: 400;
  color: ${COLORS.RED_100};
  cursor: pointer;
  user-select: none;
  z-index: 8;
  &:hover {
    text-decoration: underline;
  }

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const PriceWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  border-top: 1px solid ${COLORS.BORDER};
  padding-top: 10rem;
  margin-top: 10rem;
`;

export const PriceLabel = styled.label`
  text-align: end;
  font-weight: 400;
  font-size: 24rem;
  line-height: 32rem;
  color: ${COLORS.BLACK};
  margin-bottom: ${marginSm};

  ${respondTo.bigTablet`
    font-size: 20rem;
  `}

  ${respondTo.bigPhone`
    font-size: 18rem;
  `}
`;

export const PriceValueLabel = styled.label`
  font-weight: 600;
  font-size: 30rem;
  line-height: 40rem;
  color: ${COLORS.GREEN};

  ${respondTo.bigTablet`
    font-size: 26rem;
  `}

  ${respondTo.smallTablet`
    font-size: 22rem;
  `}

  ${respondTo.bigPhone`
    font-size: 20rem;
  `}
`;

export const SupplierName = styled.label`
  font-weight: 600;
  font-size: 30rem;
  ${respondTo.bigTablet`
    font-size: 26rem;
  `}

  ${respondTo.smallTablet`
    font-size: 22rem;
  `}

  ${respondTo.bigPhone`
    font-size: 20rem;
  `}
  color: ${COLORS.BLACK};
  margin-bottom: 20rem;
  margin-top: 20rem;
`;
