import { IColor } from '../ColorModal';
import {
  Container,
  LeftPart,
  Subtitle,
  Title,
  imageContainerStyle,
  imageStyle,
} from './ColorCard.styled';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { getContrastingLabelColor } from '../helpers';
import Tooltip from 'components/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { formatPrice } from './helpers';
import {
  AdaptiveImage,
  AdaptiveImageType,
} from 'components/AdaptiveImage/AdaptiveImage';

interface IColorCardProps {
  isSelected: boolean;
  color: IColor;
  handleChooseColor: (color: IColor) => void;
}

const ColorCard = ({
  isSelected,
  color,
  handleChooseColor,
}: IColorCardProps) => {
  const { t } = useTranslation();
  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  const bgColor = color.image
    ? darkMode
      ? '#222737' // --color-background
      : '#f6fcf7'
    : color.hex_code;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleChooseColor(color);
    }
  };

  return (
    <Tooltip content={formatPrice(color.additional_price, t)}>
      <Container
        isSelected={isSelected}
        bgColor={bgColor}
        onClick={() => handleChooseColor(color)}
        tabIndex={0}
        onKeyDown={handleKeyDown}
      >
        <LeftPart>
          <Title color={getContrastingLabelColor(bgColor)}>{color.name}</Title>
          <Subtitle color={getContrastingLabelColor(bgColor)}>
            {color.code ? `(${color.code})` : color.description || ''}
          </Subtitle>
        </LeftPart>
        <AdaptiveImage
          containerStyle={imageContainerStyle}
          imageStyle={imageStyle}
          imageUrl={color.image}
          type={AdaptiveImageType.COLOR}
        />
      </Container>
    </Tooltip>
  );
};

export default ColorCard;
