import { COLORS } from 'assets/styled';
import { hoverLineIn } from 'assets/styled/commonStyles';
import styled, { css } from 'styled-components';

interface StyledProps {
  $isDisabled: boolean;
}

export const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

export const ClickableLabel = styled.span`
  color: ${COLORS.GREEN};
  font-size: 16rem;
  display: block;
  ${hoverLineIn}
  cursor: pointer;
`;

export const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15rem 10rem;
  border: 1px solid ${COLORS.BORDER};
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  z-index: 2;
  background-color: ${COLORS.WHITE};
  border-radius: 5rem;
  width: 200rem;
  max-height: 250rem;
  overflow-y: auto;
`;

export const Item = styled.div`
  display: flex;
  gap: 10rem;
  align-items: center;
  margin-bottom: 10rem;
  border-bottom: 1px solid ${COLORS.BORDER};
  transition: 0.3s ease;
  padding: 5rem 10rem;

  ${(props: StyledProps) =>
    props.$isDisabled
      ? css`
          background-color: transparent;
        `
      : css`
          &:hover {
            background-color: ${COLORS.BACKGROUND};
          }
        `};

  &:last-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

export const checkboxLabelStyling = css`
  align-self: flex-start;
  font-size: 16rem;
  color: ${COLORS.BLACK};
`;
