import { IOption } from 'components/Select/type';
import { queryClient } from 'index';
import i18n from 'providers/i18n/i18n';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import {
  addToShoppingCart,
  deleteShoppingCart,
  deleteShoppingCartItem,
  editShoppingCartItem,
  getShoppingCart,
} from 'services/ShoppingCartService';
import { LogyxError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';

export const useGetShoppingCart = () =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SHOPPING_CART, i18n.resolvedLanguage],
    queryFn: () => {
      return getShoppingCart();
    },
  });

interface IAddToShoppingCartParams {
  cartLineQuantity: number;
  modelId: number;
  companyId: number;
  lang: string;
  queryPayload: any;
  pricing: any;
}

export const useAddToShoppingCart = () =>
  useMutation(
    (params: IAddToShoppingCartParams) => {
      return addToShoppingCart(
        params.cartLineQuantity,
        params.modelId,
        params.companyId,
        params.lang,
        params.queryPayload,
        params.pricing
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ReactQueryKeys.GET_SHOPPING_CART]);
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.ADD_TO_SHOPPING_CART,
    }
  );

interface IEditShoppingCartItem {
  cartLineId: number;
  cartLineQuantity: number;
  modelId: number;
  companyId: number;
  lang: string;
  queryPayload: any;
  pricing: any;
}

export const useEditShoppingCartItem = () => {
  return useMutation(
    (params: IEditShoppingCartItem) => {
      return editShoppingCartItem(
        params.cartLineId,
        params.cartLineQuantity,
        params.modelId,
        params.companyId,
        params.lang,
        params.queryPayload,
        params.pricing
      );
    },
    {
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      onSettled: () => {
        queryClient.removeQueries(ReactQueryKeys.GET_SHOPPING_CART);
      },
      mutationKey: ReactMutationKeys.EDIT_SHOPPING_CART_ITEM,
    }
  );
};

export const useDeleteShoppingCart = () => {
  const { t } = useTranslation();
  return useMutation(() => deleteShoppingCart(), {
    onSuccess: () => {
      toast.success(t('Shopping cart cleared'), {
        toastId: ReactMutationKeys.DELETE_SHOPPING_CART,
      });
    },
    onError: (error: LogyxError) => {
      toast.error(getToastErrorMessage(error));
    },
    onSettled: () => {
      queryClient.removeQueries(ReactQueryKeys.GET_SHOPPING_CART);
    },
    mutationKey: ReactMutationKeys.DELETE_SHOPPING_CART,
  });
};

export const useDeleteItemFromShoppingCart = () => {
  const { t } = useTranslation();
  return useMutation((itemId: number) => deleteShoppingCartItem(itemId), {
    onSuccess: () => {
      toast.success(t('Item removed from cart'), {
        toastId: ReactMutationKeys.DELETE_SHOPPING_CART_ITEM,
      });
    },
    onError: (error: LogyxError) => {
      toast.error(getToastErrorMessage(error));
    },
    onSettled: () => {
      queryClient.removeQueries(ReactQueryKeys.GET_SHOPPING_CART);
    },
    mutationKey: ReactMutationKeys.DELETE_SHOPPING_CART_ITEM,
  });
};
