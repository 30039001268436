import { COLORS, marginMd, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import CustomSelect from 'components/Select/FormikSelect';
import { Field } from 'formik';
import styled from 'styled-components';

export const Container = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${paddingMd};

  ${respondTo.bigPhone`
     padding: 15rem;
   `}
`;

export const Title = styled.span`
  padding-bottom: 15rem;
  font-weight: 400;
  font-size: 24rem;
  color: ${COLORS.BLACK};
  text-align: center;

  ${respondTo.bigPhone`
    font-size: 20rem;
  `}
`;

export const Row = styled.div`
  margin-top: 20rem;
  display: flex;
  gap: 30rem;
  width: 100%;
  max-width: 100%;

  ${respondTo.smallTablet`
    flex-direction: column;
    gap: 20rem;
  `}
`;

export const RowSplit = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  margin-top: 20rem;
  gap: 20rem;

  ${respondTo.bigPhone`
    flex-direction: column;
  `}
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: calc(50% - 15rem);
  width: 100%;
  margin-bottom: 14rem;

  ${respondTo.bigPhone`
    max-width: 100%;
  `}
`;

export const StyledCustomSelect = styled(CustomSelect)`
  height: 100%; // Prevent error message from overflowing
  width: 100%;
`;

export const StyledSelect = styled(Field)`
  width: 100%;
`;

export const FormikContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  width: 100%;
  max-width: 100%;
`;

export const ButtonsWrapper = styled.div`
  margin-top: ${marginMd};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10rem;
`;

export const SelectWrapper = styled.div`
  width: 100%;
  max-width: 100%;
`;
