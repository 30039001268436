import { useDispatch } from 'react-redux';
import { setManuallyChangedQuestionNames } from 'store/Form/actions/form';
import { IQuestion, QuestionType } from 'types/Question.types';

export const useClearFormItemValue = () => {
  const dispatch = useDispatch();

  return (
    setFieldValue: (field: string, value: any) => void,
    question: IQuestion,
    questionName: string
  ) => {
    const value = (() => {
      switch (question.type) {
        case QuestionType.BOOLEAN:
          return false;
        case QuestionType.TEXT:
          return '';
        case QuestionType.NUMBER:
          return null;
        case QuestionType.RADIO:
        case QuestionType.TABLE:
        case QuestionType.LIST:
        case QuestionType.COLOR:
          return undefined;
        default:
          return undefined;
      }
    })();

    setFieldValue(questionName, value);

    // Allow user to clear default values on all fields
    dispatch(setManuallyChangedQuestionNames(questionName));
  };
};
