import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { getToastErrorMessage, LogyxError } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  getCompanyShippingAddresses,
  getDeliveryDays,
} from 'services/CompanyService';
import { postCartOrder } from 'services/ShoppingCartService';
import { IShippingAddressDTO } from './types';
import {
  createShippingAddress,
  deleteShippingAddress,
  editShippingAddress,
} from 'services/ShippingAddressService';

export interface IPostCartOrderDTO {
  order_reference: string;
  delivery_date: string | null;
  addressId: number | null;
}

export const usePostCartOrder = (onSuccessCallback?: () => void) => {
  return useMutation(
    (postCardOrderDTO: IPostCartOrderDTO) => {
      return postCartOrder(postCardOrderDTO);
    },
    {
      onSuccess: () => {
        if (onSuccessCallback) onSuccessCallback();
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },

      onSettled: () => {
        queryClient.removeQueries(ReactQueryKeys.GET_SHOPPING_CART);
      },
      mutationKey: ReactMutationKeys.POST_CARD_ORDER,
    }
  );
};

export const useGetCompanyShippingAddresses = (companyId: number) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_COMPANY_SHIPPING_ADDRESSES, companyId],
    queryFn: () => {
      return getCompanyShippingAddresses(companyId);
    },
  });

export const useGetDeliveryDays = (companyId: number, supplierId: number) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_DELIVERY_DAYS, companyId, supplierId],
    queryFn: () => {
      return getDeliveryDays(companyId, supplierId);
    },
  });

export const useCreateShippingAddress = () => {
  const { t } = useTranslation();
  return useMutation(
    (createShippingAddressDTO: IShippingAddressDTO) =>
      createShippingAddress(createShippingAddressDTO),
    {
      onSuccess: () => {
        toast.success(t('Successfully added new address'), {
          className: ReactMutationKeys.CREATE_SHIPPING_ADDRESS,
        });
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_COMPANY_SHIPPING_ADDRESSES,
        ]);
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.CREATE_SHIPPING_ADDRESS,
    }
  );
};

interface IEditShippingAddressParams {
  id: number;
  data: IShippingAddressDTO;
}

export const useEditShippingAddress = () => {
  const { t } = useTranslation();
  return useMutation(
    ({ id, data }: IEditShippingAddressParams) => editShippingAddress(id, data),
    {
      onSuccess: () => {
        toast.success(t('Successfully edited address'), {
          className: ReactMutationKeys.EDIT_SHIPPING_ADDRESS,
        });
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_COMPANY_SHIPPING_ADDRESSES,
        ]);
      },
      onError: (error: LogyxError) => {
        toast.error(getToastErrorMessage(error));
      },
      mutationKey: ReactMutationKeys.EDIT_SHIPPING_ADDRESS,
    }
  );
};

export const useDeleteShippingAddress = () => {
  const { t } = useTranslation();
  return useMutation((id: number) => deleteShippingAddress(id), {
    onSuccess: () => {
      toast.success(t('Successfully deleted address'), {
        className: ReactMutationKeys.DELETE_SHIPPING_ADDRESS,
      });
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_COMPANY_SHIPPING_ADDRESSES,
      ]);
    },
    onError: (error: LogyxError) => {
      toast.error(getToastErrorMessage(error));
    },
    mutationKey: ReactMutationKeys.DELETE_SHIPPING_ADDRESS,
  });
};
