import { useSelector } from 'react-redux';
import { AppMode } from 'store/Common/reducers/common';
import { IRootReducerState } from 'store/store';
import { IExtraPriceQuestionOption } from 'types/Configuration.types';
import { IFormattedPricingItem, IPricingItem } from '../hooks';

// Show net prices if not blueprint/dealerPanel mode or if isUsingSupplierSystemDiscounts while in blueprint/dealerPanel mode
export const useShouldShowSpecificPrices = (): {
  showNet: boolean;
  showGross: boolean;
  isUsingSupplierSystemDiscounts: boolean;
} => {
  const appMode: AppMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );
  const isUsingSupplierSystemDiscounts: boolean = useSelector(
    (state: IRootReducerState) => state.modelInfo.isUsingSupplierSystemDiscounts
  );

  if ([AppMode.COMPANY, AppMode.SUPPLIER].includes(appMode)) {
    return {
      showNet: true,
      showGross: true,
      isUsingSupplierSystemDiscounts,
    };
  }
  if (isUsingSupplierSystemDiscounts) {
    return {
      showNet: true,
      showGross: false,
      isUsingSupplierSystemDiscounts,
    };
  } else {
    return {
      showNet: false,
      showGross: true,
      isUsingSupplierSystemDiscounts,
    };
  }
};
