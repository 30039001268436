import { useTranslation } from 'react-i18next';
import {
  Grid,
  HorizontalLine,
  LabelKey,
  LabelValue,
} from './BomItemCard.styled';
import { IFormattedBOMItem } from '../helpers';

interface IBomItemCardProps {
  bomItem: IFormattedBOMItem;
  isLast: boolean;
}

export const BomItemCard = ({ bomItem, isLast }: IBomItemCardProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid>
        <LabelKey>{t('Article number')}:</LabelKey>
        <LabelValue>{bomItem.id}</LabelValue>
        <LabelKey>{t('Description')}:</LabelKey>
        <LabelValue>{bomItem.description}</LabelValue>
        <LabelKey>{t('Cutting length')}:</LabelKey>
        <LabelValue>{bomItem.length}</LabelValue>
        <LabelKey>{t('Quantity')}:</LabelKey>
        <LabelValue>{bomItem.quantity_per}</LabelValue>
        <LabelKey>{t('Unit')}:</LabelKey>
        <LabelValue>{bomItem.unit}</LabelValue>
        <LabelKey>{t('Not sell before')}:</LabelKey>
        <LabelValue>{bomItem.not_sell_before}</LabelValue>
        <LabelKey>{t('Not sell after')}:</LabelKey>
        <LabelValue>{bomItem.not_sell_after}</LabelValue>
      </Grid>
      {isLast ? null : <HorizontalLine />}
    </>
  );
};
