import { COLORS, borderRadiusSm } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  flexValue: number;
}

export const FooterContainer = styled.tr`
  position: relative;
  height: 62rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  width: 100%;
  border-radius: ${borderRadiusSm};
  background-color: ${COLORS.GREEN_DISABLED};
  td {
    background-color: ${COLORS.GREEN_DISABLED};
  }
  td:first-child {
    border-top-left-radius: ${borderRadiusSm};
  }
  td:last-child {
    border-top-right-radius: ${borderRadiusSm};
  }
`;

export const Cell = styled.div`
  ${(props: StyledProps) =>
    props.flexValue &&
    css`
      flex: ${props.flexValue};
    `};
  text-align: center;
`;

export const AbsoluteTotalLabel = styled.label`
  position: absolute;
  color: ${COLORS.STRICT_BLACK};
  font-weight: 300;
  top: 50%;
  left: 15rem;
  transform: translateY(-50%);
`;

export const TotalQuantityLabel = styled.label`
  color: ${COLORS.STRICT_BLACK};
  font-weight: 300;
`;
