import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonsWrapper,
  Checkbox,
  CheckboxLabel,
  CheckboxWrapper,
  Container,
  List,
  Option,
  OptionHeader,
  OptionHeaderWrapper,
  OptionValue,
  OptionValueWrapper,
  OptionWrapper,
} from './OptionsList.styled';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import {
  getButtonLabel,
  handleCheckboxClick,
  includesByValue,
} from 'components/OptionsTable/helpers';
import Button from 'components/Button/Button';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface IOptionsListProps {
  isMultiple: boolean;
  initialSelectedOptions: any[];
  tableRows: any[];
  headerData: string[];
  onCancel: () => void;
  onSubmit: (selectedOptions: any[]) => void;
}

const OptionsList = ({
  isMultiple,
  initialSelectedOptions = [],
  headerData,
  tableRows,
  onSubmit,
  onCancel,
}: IOptionsListProps) => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<any[]>([
    ...initialSelectedOptions,
  ]);
  const { isSmallPhone } = useBreakpointFlag();

  return (
    <Container>
      <List>
        <OptionWrapper>
          {tableRows.map((row, index) => {
            const isSelected = includesByValue(selectedOptions, row);

            return (
              <Option
                isSelected={isSelected}
                key={index}
                onClick={() =>
                  handleCheckboxClick(
                    isMultiple,
                    row,
                    selectedOptions,
                    setSelectedOptions
                  )
                }
              >
                <OptionHeaderWrapper>
                  {headerData.map((thString, index) => (
                    <OptionHeader key={index}>
                      {t(capitalizeFirstLetter(thString).replace('_', ' '))}
                    </OptionHeader>
                  ))}
                </OptionHeaderWrapper>

                <OptionValueWrapper>
                  {row.map((cell: string, cellIndex: number) => (
                    <OptionValue key={cellIndex}>{cell.toString()}</OptionValue>
                  ))}
                </OptionValueWrapper>
                <CheckboxWrapper>
                  <Checkbox
                    checked={isSelected}
                    onClick={(e: any) => e.stopPropagation()}
                    onChange={() =>
                      handleCheckboxClick(
                        isMultiple,
                        row,
                        selectedOptions,
                        setSelectedOptions
                      )
                    }
                  />
                  <CheckboxLabel>
                    {isSelected ? t('Unselect') : t('Select')}
                  </CheckboxLabel>
                </CheckboxWrapper>
              </Option>
            );
          })}
        </OptionWrapper>
      </List>
      <ButtonsWrapper>
        <Button
          label={t('Cancel')}
          width={isSmallPhone ? '100rem' : '150rem'}
          secondary
          onClick={() => onCancel()}
        />
        <Button
          disabled={!initialSelectedOptions.length && !selectedOptions.length}
          label={getButtonLabel(selectedOptions, initialSelectedOptions, t)}
          width="150rem"
          primary
          onClick={() => onSubmit(selectedOptions)}
        />
      </ButtonsWrapper>
    </Container>
  );
};

export default OptionsList;
