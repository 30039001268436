import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { Field } from 'formik';
import { COLORS } from 'assets/styled/colors';
import { borderRadiusSm, gapSm, paddingXs } from 'assets/styled';

interface StyledProps {
  search: boolean;
  height: string;
  wrapperStyles?: CSSProperties;
  styles?: CSSProperties;
}

interface IInputLabel {
  type: string;
  errormessage: string;
}

export const Container = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  position: relative;
  ${({ wrapperStyles }: StyledProps) => wrapperStyles}
`;

export const InputLabel = styled.label`
  margin-bottom: 10rem;
  align-self: flex-start;
  font-weight: 700;
  font-size: 16rem;
  line-height: 16rem;
  color: ${COLORS.BLACK};

  ${({ type }: IInputLabel) =>
    type === 'file' &&
    css`
      margin-bottom: 0;
      display: flex;
      align-items: center;
      border: 1rem solid ${COLORS.BORDER};
      background-color: ${COLORS.GRAY_100};
      height: 100%;
      padding: 10rem;
      cursor: pointer;
    `}
`;

export const StyledInput = styled(Field)`
  height: ${(props: StyledProps) => props.height};
  border: none;
  border: 1rem solid ${COLORS.BORDER};
  background-color: ${COLORS.WHITE};
  border-radius: 20rem;
  flex-grow: 0;
  padding-left: 15rem;
  outline: none !important;
  transition: all 0.3s;
  color: ${COLORS.BLACK};

  ::placeholder {
    font-size: 14rem;
  }

  ${({ type }: IInputLabel) => {
    if (type !== 'file') return;
    return css`
      display: none;
    `;
  }}
  :hover {
    border: 1rem solid ${COLORS.GREEN};
    background-color: ${COLORS.FORM_ELEMENT_HOVER};
  }

  ${(props: StyledProps) =>
    props.search &&
    css`
      background-color: ${COLORS.WHITE};
      color: ${COLORS.BLACK};
      :focus {
        border: 1rem solid ${COLORS.BLACK} !important;
      }
    `}
  ${({ errormessage }: IInputLabel) =>
    errormessage && `border: 1rem solid ${COLORS.RED_100};`}

  &:disabled {
    background-color: ${COLORS.GRAY_100};
    cursor: not-allowed;
  }

  ${({ styles }: StyledProps) => styles}
`;

export const StyledTextArea = styled(Field)`
  ${(props: StyledProps) =>
    props.height &&
    css`
      height: ${(props: StyledProps) => props.height};
    `}
  font-family: inherit;
  border: 1rem solid ${COLORS.BORDER};
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  flex-grow: 0;
  padding: ${paddingXs};
  gap: ${gapSm};
  transition: all 0.3s;
  resize: none;
  color: ${COLORS.BLACK};

  ${(props: StyledProps) =>
    props.search &&
    css`
      background-color: ${COLORS.WHITE};
      color: ${COLORS.BLACK};
      :focus {
        border: 1rem solid ${COLORS.BLACK} !important;
      }
    `}
  ${({ errormessage }: IInputLabel) =>
    errormessage && `border: 1rem solid ${COLORS.RED_100};`}
    
  &:disabled {
    background-color: ${COLORS.GRAY_100};
    cursor: not-allowed;
  }
`;

export const ErrorContainer = styled.div`
  min-height: 16rem;
  position: absolute;
  bottom: -15rem;
`;

export const ErrorMessage = styled.div`
  font-weight: 400;
  font-size: 12rem;
  line-height: 10rem;
  color: ${COLORS.RED_100};
  padding-top: 10rem;
`;
