import { COLORS, paddingSm, paddingXs } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';
import { ADDITIONAL_WRAPPER_MIN_HEIGHT } from '../FormItemWrapper/FormItemWrapper.styled';

interface StyledProps {
  hasValue: boolean;
  showBorder: boolean;
  hasPrice: boolean;
  opacityIndex: number;
  opacityCoeficient: number;
  hasRedStar?: boolean;
  isSkeleton?: boolean;
  allowClearField: boolean;
}

export const QuestionName = styled.label`
  /* width: max-content; */
  max-width: 100%;
  width: 100%;
  font-size: 17rem;
  font-weight: 400;
  color: ${COLORS.BLACK};
  transition: 0.3s ease;

  ${respondTo.bigPhone`
    font-size: 16rem;
  `};
`;

export const FormItemContainer = styled.div`
  position: relative;
  display: flex;
  padding: ${paddingSm} ${paddingXs} 5rem;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  max-width: 100%;
  width: 100%;

  ${(props: StyledProps) =>
    props.hasValue &&
    css`
      color: ${COLORS.GREEN};
      background: ${COLORS.BACKGROUND};
    `};

  ${respondTo.smallTablet`
      flex-wrap: wrap;
      gap: 5rem;
      padding: 15rem ${paddingXs} 5rem;
    `};
`;

export const RedAsteriskWrapper = styled.label`
  position: relative;
  padding-left: 5rem;
  pointer-events: none;
  background-color: red;
`;

export const RedAsterisk = styled.label`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  font-weight: 400;
  font-size: 30rem;
  color: ${COLORS.RED_100};
  pointer-events: none;
`;

export const QuestionNameAsteriskInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10rem;
`;

export const TooltipWrapper = styled.div`
  margin-left: 5rem;
`;

export const ContainerGrayBottomBorder = styled.div`
  display: flex;
  align-items: center;

  :hover {
    ${FormItemContainer} {
      color: ${COLORS.GREEN};
      background: ${COLORS.BACKGROUND};
    }

    ${QuestionName} {
      color: ${COLORS.GREEN};
    }

    ${(props: StyledProps) =>
      props.allowClearField &&
      css`
        ${FormItemContainer} {
          max-width: calc(100% - 40rem);
        }

        ${ClearButton} {
          transform: scale(1);
          width: 40rem;
        }
      `};
  }

  ${(props: StyledProps) =>
    props.showBorder &&
    css`
      border-bottom: 1px solid ${COLORS.BORDER};
      border-width: thin;
    `};
  ${(props: StyledProps) =>
    props.opacityIndex !== undefined &&
    css`
      opacity: ${1 - (props.opacityIndex * props.opacityCoeficient + 0.2)};
    `};
  ${(props: StyledProps) =>
    props.isSkeleton &&
    css`
      padding-bottom: 24rem;
    `}
`;

export const AdditionalMargin = styled.div`
  // FormItemWrapper's AdditionalWrapper causes the question name to be off center,
  // this margin moves the question name towards the center of the FormItemContainer
  min-height: ${ADDITIONAL_WRAPPER_MIN_HEIGHT}rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${respondTo.smallTablet`
      display: none;
  `};
`;

export const Column = styled.div`
  // Used for positioning AdditionalMargin below the question name
  display: flex;
  flex-direction: column;
`;

export const ClearButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  transform: scale(0);
  width: 0;
  transition: 0.3s ease-in-out;
`;
