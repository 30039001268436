import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import { hoverLineOut } from 'assets/styled/commonStyles';
import styled from 'styled-components';

interface StyledProps {
  isActive: boolean;
}

export const ExtraPriceQuestionLabel = styled.label`
  text-align: center;
  font-size: 16rem;
  font-weight: 300;
  cursor: pointer;
  color: ${(props: StyledProps) => {
    if (props.isActive) {
      return COLORS.GREEN;
    }
    return COLORS.BLACK;
  }};
  ${hoverLineOut};
  ${respondTo.smallTablet`
    text-align: center;
  `};
  margin-left: 20rem;
  margin-right: 20rem;
`;

export const ExtraPriceQuestionRelativeWrapper = styled.div`
  position: relative;
  width: fit-content;
`;

export const IconTooltipAbsoluteWrapper = styled.div`
  position: absolute;
  right: -10rem;
  top: 0;
`;

export const CheckmarkIconAbsoluteWrapper = styled.div`
  position: absolute;
  left: -10rem;
  top: 0;
`;
