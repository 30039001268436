import {
  SubHeaderContainer,
  PricingContainer,
  TotalPrice,
  SubHeaderContentWrapper,
  ModelNameLabel,
  AbsolutePricingWrapper,
  LeftSide,
  InfoIconWrapper,
  StyledIcon,
  EditTestConfigLabel,
  RightSide,
  QuantityWrapper,
  QuantityLabel,
} from './SubHeader.styled';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { AddedToCartModal } from 'components/Modals/AddedToCartModal/AddedToCartModal';
import { useEffect, useRef, useState } from 'react';
import {
  setCurrentlyEditingShoppingCartLineData,
  triggerAddToShoppingCart,
  triggerEditCartItem,
} from 'store/ShoppingCart/actions/shoppingCart';
import {
  setIsTestConfigurationModalOpen,
  triggerSubmitConfiguration,
} from 'store/SubmitConfiguration/actions/submitConfiguration';
import { RoutesConfig } from 'navigation/routes';
import { CartItemEditFinishedModal } from 'components/Modals/CartItemEditFinishedModal/CartItemEditFinishedModal';
import {
  resetManuallyChangedQuestionNames,
  resetTouchedQuestionNames,
  setAreAllFieldsTouched,
} from 'store/Form/actions/form';
import {
  SET_TRIGGER_SHOW_ADDED_TO_CART_MODAL,
  SET_TRIGGER_SHOW_CART_ITEM_EDITED_MODAL,
} from 'store/constants';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';
import {
  useCalculateAndFormatPrices,
  useIsEditOrAddToShoppingCartRequestInProgress,
  useShouldRoundNumbers,
} from './hooks';
import { useTranslation } from 'react-i18next';
import PricingSection from './PricingSection/PricingSection';
import { useShouldShowSpecificPrices } from './PricingSection/hooks';
import Tooltip from 'components/Tooltip/Tooltip';
import { getTooltipText } from 'pages/ConfigurePage/ConfigureForm/FormItem/helpers';
import { resetConfigureForm } from 'store/Model/actions/model';
import { useNavigateWithQuery } from 'utils/hooks/useNavigateWithQuery';
import { COLORS } from 'assets/styled';
import { Skeleton } from '@mui/material';
import Icon from 'components/Icon/Icon';
import { AppMode } from 'store/Common/reducers/common';
import Button from 'components/Button/Button';
import { shouldShowRedBorder } from './helpers';
import { CaretDown, Info } from '@phosphor-icons/react';
import { useGetBuilderEditVersionId } from 'providers/QueryProvider/hooks';
import Input from 'components/Input/Input';
import { useLocation } from 'react-router-dom';

interface ISubHeaderProps {
  isExpanded: boolean;
}

const SubHeader = ({ isExpanded }: ISubHeaderProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    triggerShowAddedToCartModal,
    triggerShowCartItemEditedModal,
    currentlyEditingShoppingCartLineId,
    currentlyEditingShoppingCartLineQuantity,
  } = useSelector((state: IRootReducerState) => state.shoppingCartInfo);

  const [quantity, setQuantity] = useState<string>(
    (currentlyEditingShoppingCartLineQuantity || 1).toString()
  );

  useEffect(() => {
    if (
      currentlyEditingShoppingCartLineQuantity !== null &&
      currentlyEditingShoppingCartLineQuantity !== undefined
    ) {
      setQuantity(currentlyEditingShoppingCartLineQuantity.toString());
    } else {
      setQuantity('1');
    }
  }, [currentlyEditingShoppingCartLineQuantity]);

  const [isPricingSectionVisible, setIsPricingSectionVisible] =
    useState<boolean>(false);
  const modelInfo = useSelector((state: IRootReducerState) => state.modelInfo);
  const { isFormValid, areAllFieldsTouched } = useSelector(
    (state: IRootReducerState) => state.formInfo
  );
  const appMode: AppMode = useSelector(
    (state: IRootReducerState) => state.commonInfo.appMode
  );

  const [isAddedToCartModalOpen, setIsAddedToCartModalOpen] = useState(false);
  const [isCartItemEditFinishedModalOpen, setIsCartItemEditFinishedModalOpen] =
    useState(false);

  const navigateWithQuery = useNavigateWithQuery();

  useEffect(() => {
    if (triggerShowAddedToCartModal) {
      setIsAddedToCartModalOpen(true);
      dispatch(resetConfigureForm());
      dispatch(resetTouchedQuestionNames());
      dispatch(resetManuallyChangedQuestionNames());
      dispatch(setAreAllFieldsTouched(false));
      dispatch({ type: SET_TRIGGER_SHOW_ADDED_TO_CART_MODAL, payload: false });
    }
  }, [triggerShowAddedToCartModal]);
  useEffect(() => {
    if (triggerShowCartItemEditedModal) {
      setIsCartItemEditFinishedModalOpen(true);
      dispatch(resetConfigureForm());
      dispatch(resetTouchedQuestionNames());
      dispatch(resetManuallyChangedQuestionNames());
      dispatch(setAreAllFieldsTouched(false));
      dispatch(setCurrentlyEditingShoppingCartLineData(null, null));
      dispatch({
        type: SET_TRIGGER_SHOW_CART_ITEM_EDITED_MODAL,
        payload: false,
      });
    }
  }, [triggerShowCartItemEditedModal]);

  const handleAddToCart = () => {
    dispatch(triggerAddToShoppingCart(true)); // Handled in useListenForAddToCartTrigger
  };

  const handleEditCartItem = () => {
    dispatch(triggerEditCartItem(true)); // Handled in useListenForEditCartItemTrigger
    navigateWithQuery(RoutesConfig.Configure.fullPath); // QueryProvider has useListenForAddToCartTrigger, but it triggers only on ConfigurePage
  };

  // Blueprint integration
  const handleSubmitConfiguration = () => {
    dispatch(triggerSubmitConfiguration(true)); // Handled in useListenForSubmitConfigurationTrigger
    navigateWithQuery(RoutesConfig.Configure.fullPath);
  };

  const queryRequestStatus = useSelector(
    (state: IRootReducerState) => state.modelInfo.queryStatus
  );

  const isTestMode = appMode === AppMode.DEALER_PANEL;
  const getButtonLabel = () => {
    if (appMode === AppMode.DEALER_PANEL) {
      return 'UNKNOWN';
    }
    if (appMode === AppMode.BLUEPRINT) {
      return t('Submit');
    } else {
      if (currentlyEditingShoppingCartLineId) {
        return t('Edit cart item');
      } else {
        return t('Add to cart');
      }
    }
  };

  const pricingContainerRef = useRef(null);
  useOutsideAlerter(pricingContainerRef, () =>
    setIsPricingSectionVisible(false)
  );

  const [
    totalBaseNetPrice,
    totalBaseGrossPrice,
    totalNetPrice,
    totalGrossPrice,
    formattedPrices,
    isPricingValid,
  ] = useCalculateAndFormatPrices(modelInfo?.pricing, Number(quantity));

  const isPricingEmpty = totalBaseNetPrice === 0 && totalBaseGrossPrice === 0;

  const { showNet } = useShouldShowSpecificPrices();
  const shouldRoundNumbers = useShouldRoundNumbers();

  const isPriceUnknown = showNet
    ? totalNetPrice === null
    : totalGrossPrice === null;

  const pricelist = modelInfo?.general?.pricelist;
  const pricelistVersion = modelInfo?.general?.pricelist_version;

  const isEditOrAddToShoppingCartRequestInProgress =
    useIsEditOrAddToShoppingCartRequestInProgress();

  const editVersionId: number | undefined = useGetBuilderEditVersionId();

  return (
    <SubHeaderContainer isExpanded={isExpanded}>
      <SubHeaderContentWrapper isExpanded={isExpanded}>
        <LeftSide>
          <ModelNameLabel>
            {modelInfo?.general?.name ? (
              modelInfo.general.name
            ) : !queryRequestStatus.attempt ? (
              <Skeleton width={'300rem'} height={'40rem'} animation="wave" />
            ) : (
              t('Unknown model')
            )}
          </ModelNameLabel>
          {pricelist || pricelistVersion ? (
            <Tooltip
              content={getTooltipText(
                ['Pricelist', 'Pricelist Version'],
                [pricelist, pricelistVersion],
                t
              )}
            >
              <InfoIconWrapper>
                <Icon svg={Info} size={20} color={COLORS.GREEN} />
              </InfoIconWrapper>
            </Tooltip>
          ) : null}
        </LeftSide>
        <AbsolutePricingWrapper>
          {isPriceUnknown ? (
            <TotalPrice>{t('Unknown price')}</TotalPrice>
          ) : (
            <PricingContainer ref={pricingContainerRef}>
              <TotalPrice>
                {showNet
                  ? '€' + totalNetPrice?.toFixed(shouldRoundNumbers ? 0 : 2)
                  : '€' + totalGrossPrice?.toFixed(shouldRoundNumbers ? 0 : 2)}
              </TotalPrice>
              {isPricingEmpty ? null : (
                <StyledIcon
                  svg={CaretDown}
                  size={20}
                  color={COLORS.BLACK}
                  onClick={() =>
                    setIsPricingSectionVisible(!isPricingSectionVisible)
                  }
                  isPricingSectionVisible={isPricingSectionVisible}
                />
              )}
              {isPricingSectionVisible && (
                <PricingSection
                  formattedPrices={formattedPrices}
                  quantity={Number(quantity)}
                />
              )}
              {isPricingValid ? null : t('Invalid dimensions')}
            </PricingContainer>
          )}
        </AbsolutePricingWrapper>
        <RightSide>
          {appMode !== AppMode.BLUEPRINT && appMode !== AppMode.DEALER_PANEL && (
            <QuantityWrapper>
              <QuantityLabel>{t('Quantity')}:</QuantityLabel>
              <Input
                key={currentlyEditingShoppingCartLineQuantity ?? ''}
                label=""
                placeholder=""
                value={quantity}
                width={'90rem'}
                type="number"
                onChange={(e) => {
                  const value = e.target.value;
                  if (value === '') {
                    setQuantity(value);
                    return;
                  }
                  const number = Number(value);
                  if (isNaN(number) || number < 0) {
                    return;
                  }
                  setQuantity(value);
                }}
                onBlur={() => {
                  let value = parseInt(quantity, 10);
                  if (isNaN(value) || value < 1) value = 1;
                  setQuantity(value.toString());
                }}
                onIncrease={() => {
                  let value = parseInt(quantity, 10);
                  if (isNaN(value) || value < 1) value = 1;
                  const newValue = value + 1;
                  setQuantity(newValue.toString());
                }}
                onDecrease={() => {
                  let value = parseInt(quantity, 10);
                  if (isNaN(value) || value <= 1) value = 1;
                  else value = value - 1;
                  setQuantity(value.toString());
                }}
              />
            </QuantityWrapper>
          )}
          {isExpanded && appMode !== AppMode.SUPPLIER ? (
            appMode === AppMode.DEALER_PANEL && !editVersionId ? (
              <EditTestConfigLabel
                onClick={() => dispatch(setIsTestConfigurationModalOpen(true))}
              >
                {t('Edit Test Configuration')}
              </EditTestConfigLabel>
            ) : (
              <Button
                buttonStyle={
                  shouldShowRedBorder(
                    isTestMode,
                    areAllFieldsTouched,
                    queryRequestStatus?.attempt,
                    isFormValid
                  )
                    ? { border: `1rem solid ${COLORS.RED}` }
                    : {}
                }
                pwId={'submit-form-button'}
                disabled={
                  isEditOrAddToShoppingCartRequestInProgress ||
                  isTestMode ||
                  (areAllFieldsTouched &&
                    (queryRequestStatus.attempt ||
                      !isFormValid ||
                      !isPricingValid))
                }
                primary
                width="150rem"
                label={getButtonLabel()}
                onClick={() => {
                  if (isFormValid && isPricingValid) {
                    if (appMode === AppMode.BLUEPRINT) {
                      handleSubmitConfiguration();
                    } else {
                      const value = parseInt(quantity, 10);
                      const validQuantity =
                        isNaN(value) || value < 1 ? 1 : value;

                      dispatch(
                        setCurrentlyEditingShoppingCartLineData(
                          currentlyEditingShoppingCartLineId,
                          validQuantity
                        )
                      );

                      // Move user back on Config from BOM page
                      if (
                        location.pathname !== RoutesConfig.Configure.fullPath
                      ) {
                        navigateWithQuery(RoutesConfig.Configure.fullPath);
                      }

                      if (currentlyEditingShoppingCartLineId) {
                        handleEditCartItem();
                      } else {
                        handleAddToCart();
                      }
                    }
                  } else if (!areAllFieldsTouched) {
                    dispatch(setAreAllFieldsTouched(true));
                  }
                }}
              />
            )
          ) : null}

          {(!!modelInfo.connLeadMpr || !!modelInfo.connLeadTime) && (
            <>
              <Tooltip
                content={
                  `${
                    modelInfo.connLeadTime
                      ? `${t('Lead Time')}: ${modelInfo.connLeadTime} ${
                          modelInfo.connLeadTime === 1 ? t('day') : t('days')
                        }`
                      : ''
                  }` +
                  `${modelInfo.connLeadMpr ? `(${modelInfo.connLeadMpr})` : ''}`
                }
              >
                <Icon svg={Info} color={COLORS.GREEN} />
              </Tooltip>
            </>
          )}
        </RightSide>
      </SubHeaderContentWrapper>
      <AddedToCartModal
        isOpen={isAddedToCartModalOpen}
        setIsOpen={setIsAddedToCartModalOpen}
      />
      <CartItemEditFinishedModal
        isOpen={isCartItemEditFinishedModalOpen}
        setIsOpen={setIsCartItemEditFinishedModalOpen}
      />
    </SubHeaderContainer>
  );
};

export default SubHeader;
