import {
  PriceLabel,
  PriceLabelValue,
  ContainerAbsolute,
  TooltipPriceLabelWrapper,
  GridContainer,
  Margin34,
  QuantityLabel,
  DiscountLabel,
  DiscountValue,
  DiscountWrapper,
} from './PricingSection.styled';
import { IFormattedPricingItem } from '../hooks';
import { useShouldShowSpecificPrices } from './hooks';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/Tooltip/Tooltip';
import Icon from 'components/Icon/Icon';
import { Info, PencilSimple } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import EditDiscountModal, {
  DiscountsType,
} from 'components/Modals/EditDiscountModal/EditDiscountModal';
import useHasRole from 'utils/hooks/useHasRole';
import { PermissionGroupSupplierId } from 'types/Permissions.types';
import { useDispatch, useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { updatePricingWithDiscount } from './helpers';
import { setPricing } from 'store/Model/actions/model';
import { RoutesConfig } from 'navigation/routes';
import { useLocation } from 'react-router-dom';
import { useNavigateWithQuery } from 'utils/hooks/useNavigateWithQuery';

interface IPricingSectionProps {
  formattedPrices: IFormattedPricingItem[];
  quantity: number;
}

interface IExistingDiscount {
  type: DiscountsType | null;
  value: string | null;
}

const PricingSection = ({
  formattedPrices,
  quantity,
}: IPricingSectionProps) => {
  const [isEditDiscountModalOpen, setIsEditDiscountModalOpen] = useState(false);
  const [existingDiscount, setExistingDiscount] = useState<IExistingDiscount>({
    type: null,
    value: null,
  });
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const { showNet, showGross, isUsingSupplierSystemDiscounts } =
    useShouldShowSpecificPrices();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const navigateWithQuery = useNavigateWithQuery();

  const pricing = useSelector(
    (state: IRootReducerState) => state.modelInfo.pricing
  );

  const userSupplier = useSelector(
    (state: IRootReducerState) => state.userInfo.supplier
  );

  const modelInfo = useSelector((state: IRootReducerState) => state.modelInfo);

  const canEditDiscount = userSupplier.id === modelInfo.supplierId;

  const handleConfirmDiscount = (
    discountValue: string,
    discountType: DiscountsType
  ) => {
    if (!editingKey) return;

    // Move user back on Config from BOM page
    if (location.pathname !== RoutesConfig.Configure.fullPath) {
      navigateWithQuery(RoutesConfig.Configure.fullPath);
    }

    const updatedPricing = updatePricingWithDiscount(
      pricing,
      editingKey,
      discountValue,
      discountType
    );
    dispatch(setPricing(updatedPricing));
    setIsEditDiscountModalOpen(false);
  };

  const canSetDiscount = useHasRole(
    undefined,
    PermissionGroupSupplierId.WRITE_CUSTOM_DISCOUNT_AND_AMOUNT
  );

  return (
    <ContainerAbsolute>
      <GridContainer areBothPriceTypesShown={showNet && showGross}>
        <QuantityLabel>{quantity}x</QuantityLabel>
        {showGross && <PriceLabel>{t('Gross')}</PriceLabel>}
        {showNet && (
          <PriceLabel>
            {isUsingSupplierSystemDiscounts ? t('Gross') : t('Net')}
          </PriceLabel>
        )}
        <DiscountLabel>{t('Discount')}</DiscountLabel>
        {formattedPrices.map(
          (formattedPrice: IFormattedPricingItem, index: number) => {
            const shouldShowPricingItem =
              (showGross && formattedPrice.grossItem?.value) ||
              (showNet && formattedPrice.netItem?.value);

            return shouldShowPricingItem ? (
              <Fragment key={index}>
                <TooltipPriceLabelWrapper>
                  {formattedPrice.netItem.description ? (
                    <>
                      <Tooltip
                        content={
                          formattedPrice.netItem.description
                            ? formattedPrice.netItem.description
                            : t('No additional info')
                        }
                      >
                        <Icon svg={Info} size={20} color={COLORS.GREEN} />
                      </Tooltip>
                    </>
                  ) : (
                    <Margin34 />
                  )}
                  <PriceLabel>
                    {`${
                      formattedPrice.name
                        ? formattedPrice.name
                        : formattedPrice.key.replace('P_', '')
                    }:`}
                  </PriceLabel>
                </TooltipPriceLabelWrapper>
                {showGross && (
                  <PriceLabelValue>
                    {formattedPrice.grossItem?.value
                      ? '€' + formattedPrice.grossItem?.value?.toFixed(2)
                      : '/'}
                  </PriceLabelValue>
                )}
                {showNet && (
                  <PriceLabelValue>
                    {formattedPrice.netItem?.value
                      ? '€' + formattedPrice.netItem?.value?.toFixed(2)
                      : '/'}
                  </PriceLabelValue>
                )}
                <DiscountWrapper>
                  <DiscountValue>
                    {formattedPrice.netItem.discount_percentage != null &&
                    formattedPrice.netItem.discount_percentage > 0
                      ? `${formattedPrice.netItem.discount_percentage}%`
                      : null}
                  </DiscountValue>
                  {canSetDiscount && (
                    <Icon
                      svg={PencilSimple}
                      size={20}
                      weight="regular"
                      color={COLORS.GREEN}
                      disabled={!canEditDiscount}
                      onClick={() => {
                        setEditingKey(formattedPrice.key);
                        setExistingDiscount({
                          type: formattedPrice.netItem.custom_amount
                            ? DiscountsType.AMOUNT
                            : DiscountsType.PERCENTAGE,
                          value:
                            formattedPrice.netItem.custom_amount?.toString() ||
                            (formattedPrice.netItem.custom_discount != null
                              ? (
                                  formattedPrice.netItem.custom_discount * 100
                                ).toString()
                              : null),
                        });
                        setIsEditDiscountModalOpen(true);
                      }}
                    />
                  )}
                </DiscountWrapper>
              </Fragment>
            ) : null;
          }
        )}
      </GridContainer>

      {isEditDiscountModalOpen && (
        <EditDiscountModal
          setIsOpen={setIsEditDiscountModalOpen}
          isOpen={isEditDiscountModalOpen}
          onConfirm={handleConfirmDiscount}
          defaultValue={existingDiscount.value}
          defaultType={existingDiscount.type}
        />
      )}
    </ContainerAbsolute>
  );
};

export default PricingSection;
